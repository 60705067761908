import { useEffect, useState, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
import moment from 'moment';


// material
import {
    Box,
    Table,
    Switch,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
    Button,
    Avatar,
    TableContainer,
    TablePagination,
    FormControlLabel,
    Stack,
    Typography,
    CircularProgress,
    Divider,
    OutlinedInput, InputAdornment,

} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';

// components../
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
// import SearchNotFound from '../../../../../../components/SearchNotFound';
import { delGroup, getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
import { getScoreList, getScoreSearch } from '../../../../../redux/action/scoreAction';
import { PATH_DASHBOARD, PATH_EMP } from "../../../../../routes/paths";
import { scoreDraftListApi, deleteDraftApi, grpDraftListApi, AllSearchDraftListApi, scoreSearchDraftListApi } from '../../../../../_apis_/authApi.js/empapi';
//
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';



// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Draft Date'
    },
    {
        id: 'scrd_name',
        numeric: false,
        disablePadding: false,
        label: 'Scorecard Name'
    },
    {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: 'Group Name'
    },
    {
        id: 'agent name',
        numeric: false,
        disablePadding: false,
        label: 'Agent Name'
    },
    {
        id: 'Draft Type',
        numeric: false,
        disablePadding: false,
        label: 'Draft Type'
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action'
    },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}



function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // console.log("query", array);

        // return filter(array, (data) =>
        //     data.name.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.description.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.id.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.location.toLowerCase().includes(query.toLowerCase()) !== false


        // )
    }
    return stabilizedThis?.map((el) => el[0]);
}

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 45,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function ScoreDraftTable() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const group = useSelector(state => state.scoreList);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    useEffect(() => {
        // dispatch(getScoreList(searchParams.get("empId"), 1))
        (async function () {
            setData([]);
            try {
                setIsLoading(true);
                if (searchParams.get("empId")) {
                    const response = await scoreDraftListApi(searchParams.get("empId"), (page + 1), searchParams.get("draftType") || 10);
                    setData(response?.data?.results);
                    setCount(response?.data?.count)
                    return setIsLoading(false);
                }

                const response = await grpDraftListApi(1, searchParams.get("draftType") || 10);
                setData(response?.data?.results);
                setCount(response?.data?.count)
                return setIsLoading(false);



            } catch (err) {
                if (err) {
                    setIsLoading(false);
                }
            }

        })();
    }, [searchParams.get("draftType")])

    const getDraftData = async (pg) => {
        setData([]);
        setIsLoading(true);
        if (searchParams.get("empId")) {
            const response = await scoreDraftListApi(searchParams.get("empId"), pg, searchParams.get("draftType") || 10);
            setData(response?.data?.results);

            setCount(response?.data?.count)
            return setIsLoading(false);
        }

        const response = await grpDraftListApi(pg, searchParams.get("draftType") || 10);
        setData(response?.data?.results);
        setCount(response?.data?.count)
        return setIsLoading(false);

    }


    const searchDraftData = async (value, pg) => {
        setData([]);
        setIsLoading(true);
        if (searchParams.get("empId")) {
            const response = await scoreSearchDraftListApi(searchParams.get("empId"), value, pg);
            setIsLoading(false);
            setCount(response?.data?.count)
            return setData(response?.data?.results)
        }
        setData([]);
        setIsLoading(true);
        const response = await AllSearchDraftListApi(value, pg, searchParams.get("draftType") || 10);
        setIsLoading(false);
        setCount(response?.data?.count)
        return setData(response?.data?.results)
    }

    // useEffect(() => {
    //     setData(group?.results);
    // }, [group])
    console.log(group)
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mbLink = [];
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {

        if (filterName && filterName !== "") {
            searchDraftData(newPage + 1);
            return setPage(newPage);
        }

        getDraftData(newPage + 1);
        return setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return getDraftData(1)
        }
        if (queryText?.length > 0) {
            return searchDraftData(queryText, 1);
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setIsLoading(true);
        setData([]);
        setPage(0);
        setFilterName(event.target.value);
        delayedQuery(event.target.value);
    };

    const mbLink2 = [];



    switch (loggedUserData?.staff?.staffType) {
        case 10:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&grpId=${searchParams.get("gpId")}&sup_dash=False` }, { name: 'Select Drafts' })
            mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            break;
        case 20:

            // if (searchParams.get("sup_dash") === "True") {
            //     mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Scorecards' })
            // }

            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Drafts' })
            if (!searchParams?.get("draftType")?.includes("10")) {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Employee", href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Drafts' })
            } else {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            }
            // if (searchParams.get("sup_dash") === "False") {
            //     mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

            // }
            break;
        case 30:

            // if (searchParams.get("sup_dash") === "True") {
            //     mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Scorecards' })
            // }

            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Drafts' })
            mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })

            // if (searchParams.get("sup_dash") === "False") {
            //     mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

            // }
            break;

        default:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/selectgroup` }, { name: 'Select Drafts' })
            if (!searchParams?.get("draftType")?.includes("10")) {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/selectgroup` }, { name: 'Select Drafts' })
            } else {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            }
            break;
    }

    async function handleDelDraft(id) {

        await confirm({ description: 'This action is permanent! It will permanently delete the draft. ' })
        const res = await deleteDraftApi(id);
        getDraftData(page + 1)

        enqueueSnackbar('Deleted Draft Successfully', {
            variant: 'success',
            action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });

    }



    const isSelected = (name) => selected.indexOf(name) !== -1;


    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    return (
        <>

            <ScoreWrapper title="Select Drafts" link={searchParams.get("empId") ? mbLink : mbLink2} >
                <Stack sx={{ width: "100%", pl: 3, borderBottom: theme => { return { lg: `1px solid ${theme.palette.text.primaryBolderBorder}`, xl: `2px solid ${theme.palette.text.primaryBolderBorder}` } } }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                    <SearchStyle
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search Draft..."
                        startAdornment={
                            <>
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                    />
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={100}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </Stack>
                <Divider />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                rowCount={data?.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredUsers?.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Box sx={{ width: "100%" }}>
                                                    <Button onClick={() => { navigate(`/score/reviewPerformance?gpId=${row?.groups?.id}&empId=${row?.staff?.id || searchParams?.get('empId')}&staff_type=${row.staff?.staff_type}&score_card_Id=${row.score_card?.id}&draftId=${row?.id}`) }} disableRipple variant='text' sx={{ justifyContent: "flex-start", color: theme => theme.palette.action.selected, p: 0, m: 0, "&:hover": { bgcolor: "transparent" }, fontSize: "14px" }}>{moment(row?.updated_at).format("LL")}</Button>
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' id={labelId} scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <h4>{row.score_card?.name}</h4>
                                                </Box>

                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>{row?.groups?.name}</Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>{row?.staff?.name}</Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>{row?.draft_type === "10" ? "Calibrate" : "Score"}</Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    {row?.draft_type !== "10" && <Link to={`/score/reviewPerformance?gpId=${row?.groups?.id}&staff_type=${row?.staff?.staff_type}&empId=${searchParams.get("empId") || row?.staff?.id}&score_card_Id=${row?.score_card?.id}&draftId=${row?.id}`}><Button sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', mx: 1, fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', border: '1px solid #dddde7' }} variant="contained">Resume</Button></Link>}
                                                    {row?.draft_type === "10" && <Link to={`${PATH_DASHBOARD.general.calibration.calibratePerformance}?score_Id=${row?.id}&score_card_Id=${row?.score_card?.id}&listName=${row?.calibration?.name}&calibrate_Id=${row?.calibration?.id}&toScore=True`}><Button variant="contained" sx={{ backgroundColor: (theme) => theme.palette.background.paper, mx: 1, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', border: '1px solid #dddde7' }}>Resume</Button></Link>}
                                                    <Button variant="contained" onClick={() => { handleDelDraft(row?.id) }} sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, mx: 1, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', border: '1px solid #dddde7' }}>Delete</Button>
                                                </Box>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {data?.length === 0 && (
                                    <TableRow>

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Draft...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Draft Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Scrollbar>

                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                    }}
                >
                    <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
                </Box>
            </ScoreWrapper>
        </>
    );
}