import React, { createContext, useReducer, useEffect, useState } from 'react';
import { API } from '../backend';
// -----------------------------------------------------------

const EventsContext = createContext();
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
const grpArr = loggedUserData?.groups?.map((obj) => obj.id);

const handlers = {
  USERS_LEVEL: (state, action) => {
    // console.log(action.payload);
    const userLevels = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userLevels
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const EventsProvider = ({ children }) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const initialState = {
    userLevels: [],
    groups: [],
    Teams: [],
    allUsers: []
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPreviewComponent, setShowPreviewComponent] = useState(false);
  const [phraseBuilderObj, setPhraseBuilderObj] = useState({
    builder: []
  });

  const [eventsPhraseBuilderListData, setEventsPhraseBuilderListData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [noOfGroupedBuilders, setNoOfGroupedBuilders] = useState(0);
  const [commonJoint, setCommonJoint] = useState();
  const [newBuilders, setNewBuilders] = useState([]);
  const [updateBuilders, setUpdateBuilders] = useState([]);
  // phrase count
  const [phraseCount, setPhraseCount] = useState();
  //
  const [builderName, setBuilderName] = useState();
  const [groupedData, setGroupedData] = useState();
  const [latestUpdatedObj, setLatestUpdatedObj] = useState();
  const [categoryId, setCategoryId] = useState('');
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventCallsFilterOpen, setEventCallsFilterOpen] = useState(false);
  const [multipleEventCallsFilterOpen, setMultipleEventCallsFilterOpen] = useState(false);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [checkEmptyGroup, setCheckEmptyGroup] = useState();
  const [eventBuilderFilterData, setEventBuilderFilterData] = useState();
  
  const [isAllPreSelected, setIsAllPreSelected] = useState(false);
  const [indBuilderObj, setIndBuilderObj] = useState();
  const [page, setPage] = useState(1);
  // ----------------------------------------------------------------------------------------------

  return (
    <EventsContext.Provider
      value={{
        showPreviewComponent,
        setShowPreviewComponent,
        phraseBuilderObj,
        setPhraseBuilderObj,
        eventsPhraseBuilderListData,
        setEventsPhraseBuilderListData,
        showAlert,
        setShowAlert,
        noOfGroupedBuilders,
        setNoOfGroupedBuilders,
        newBuilders,
        setNewBuilders,
        updateBuilders,
        setUpdateBuilders,
        phraseCount,
        setPhraseCount,
        builderName,
        setBuilderName,
        groupedData,
        setGroupedData,
        latestUpdatedObj,
        setLatestUpdatedObj,
        categoryId,
        setCategoryId,
        isAddMode,
        setIsAddMode,
        eventDialogOpen,
        setEventDialogOpen,
        unsavedChanges,
        setUnsavedChanges,
        commonJoint,
        setCommonJoint,
        checkEmptyGroup,
        setCheckEmptyGroup,
        eventBuilderFilterData,
        setEventBuilderFilterData,
        eventCallsFilterOpen,
        setEventCallsFilterOpen,
        isAllPreSelected, setIsAllPreSelected,
        indBuilderObj, setIndBuilderObj,
        page, setPage,
        multipleEventCallsFilterOpen,
        setMultipleEventCallsFilterOpen,
        selectedEvents, setSelectedEvents
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export default EventsContext;
