import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import useAuth from '../hooks/useAuth'; // assuming you have a custom hook to get the authenticated user

const RoleBasedGuard = ({ children, allowedRoles, isAiAccess, fromAiRoute }) => {

  const { data, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // const MustChangePassword = data.MustChangePassword;
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  console.log(loggedUserData?.userLevel?.name);
  console.log(allowedRoles);
  console.log('children', children);
  const isAiAccessCheck = loggedUserData?.isAiAccess
  console.log(isAiAccessCheck);

  // console.log("loggedin user data", isAuthenticated);
  // console.log("loggedin user data", loggedUserData);
  // accessibleRoles = "admin";
  // const currentRole = useCurrentRole();

  if (loggedUserData?.mustChangePassword === true && isAuthenticated) {
    navigate(`${PATH_AUTH.mustChangePassword}`)
  }
  // if (loggedUserData?.mustChangePassword === false && isAuthenticated && !allowedRoles?.includes(loggedUserData?.userLevel?.name)) {
  //   // If user doesn't have the required role, redirect to unauthorized page or some other page
  //   console.log('inside not allowed...')
  //   return <Navigate to="/login" />;
  // }
  if (loggedUserData?.mustChangePassword === false && isAuthenticated && !loggedUserData?.isAiAccess && allowedRoles?.includes(loggedUserData?.userLevel?.name) && fromAiRoute) {
    // navigate(`${PATH_AUTH.mustChangePassword}`)
    // console.log('inside allowed non ai routes...')
    return <Navigate to="/login" />;
  }
  if (loggedUserData?.mustChangePassword === false && isAuthenticated && !loggedUserData?.isAiAccess && allowedRoles?.includes(loggedUserData?.userLevel?.name) && !fromAiRoute) {
    // navigate(`${PATH_AUTH.mustChangePassword}`)
    // console.log('inside allowed non ai routes...')
    return <>{children}</>;
  }
  if (loggedUserData?.mustChangePassword === false && isAuthenticated && loggedUserData?.isAiAccess && allowedRoles?.includes(loggedUserData?.userLevel?.name)) {
    // console.log('inside ai access...')
    // navigate(`${PATH_AUTH.mustChangePassword}`)
    return <>{children}</>;
  }
  if (loggedUserData?.mustChangePassword === false && isAuthenticated && !loggedUserData?.isAiAccess && !allowedRoles?.includes(loggedUserData?.userLevel?.name)) {
    // console.log('inside failed ai condition...')
    // If user doesn't have the required role, redirect to unauthorized page or some other page
    return <Navigate to="/login" />;
  }


  // If user has the required role, render the children components
  return <>{children}</>;
};

export default RoleBasedGuard;
