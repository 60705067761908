import { useState, useContext, useEffect } from 'react';
// REACT ROUTER IMPORTS
import { useSearchParams, useNavigate } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box, Button,
    Container,
    Divider,
    FormControlLabel,
    MenuItem, Menu,
    styled, Stack, Switch,
} from '@material-ui/core';

import { Download, Cached, KeyboardArrowDown } from '@material-ui/icons'
// NPM IMPORTS
import moment from 'moment'
import { BounceLetterLoader } from 'react-spinner-overlay'
// ROUTES IMPORTS
import { PATH_DASHBOARD } from '../../routes/paths';
// CONTEXT IMPORTS
import ScoreCardsContext from '../../contexts/ScoreCardsContext';
import { AnalyticContext } from '../../contexts/AnalyticContext';
import { SettingsContext } from "../../contexts/SettingsContext";
// // HOOK IMPORTS
// import useSettings from '../../hooks/useSettings';
// COMPONENT IMPORTS
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import AnalyticsFilterDialog from '../components-overview/material-ui/dialog/AnalyticsFilterDialog';
import { Block } from '../components-overview/Block';
import { BlockNewUI } from '../components-overview/Block_newUI';
import AddCustomReportDialog from "../components-overview/material-ui/dialog/custom_analytics_report_filter";
import AnalyticsScorecardFilterDialog from '../components-overview/material-ui/dialog/AnalyticsScorecardFilterDialog';
import AnalyticsTeamsFilterDialog from '../components-overview/material-ui/dialog/AnalyticsTeamsFilterDialog';
import DialogAcceptanceByGroup from '../components-overview/material-ui/dialog/analytics_group/acceptance_by_group_dialog';
import AnalyticEvaluationFilterDialog from '../components-overview/material-ui/dialog/AnalyticEvaluationFilterDialog';
import AnalyticSupervisorFilterDialog from './analytics/supervisor/AnalyticSupervisorFIlterDialog';
// ----------------------------------------------------------------------
const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': { mx: '8px !important' }
};



// --------------------------------------------------------------------------------------

export default function AnalyticsWrapper(props) {
    const { children, val, subModule, sbVal, LabelName, brdCrmbLink,
        dwnldFn, switchFn, percentageFn, isPercentage,
        numericFn, isNumeric } = props
    // LOCAL STORAGE 
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT ROUTER IMPORTS
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // REACT STATE IMPORTS
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('1');
    const [valueScrollable, setValueScrollable] = useState('1');
    const [label, setLabel] = useState("Custom Dashboard");
    const [numericValue, setNumericValue] = useState(false);
    const [tabNum, setTabNum] = useState();
    const [customOpen, setCustomOpen] = useState(false);

    // CONTEXT IMPORTS
    const { userLevels } = useContext(ScoreCardsContext);
    const { switchView, setSwitchView, numeric, setNumeric, setFailAllOnly, failAllOnly, showSpinner, selAnaScStaffId, setSubModLabel, subModLabel, selAnaFilters, selAnaStaffId, selAnaScoreCardFilters, selDuration, selScDuration, setSavedFilterListDatas, setAdvancedTableData, setDataTagByScoreCard } = useContext(AnalyticContext);
    const { setColor } = useContext(SettingsContext);
    localStorage.setItem("userLevels", JSON.stringify(userLevels))


    // METHODS / FUNCTIONS
    useEffect(() => {
        setValue(val);
        setLabel(LabelName);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setTabNum();
    };

    const handleNumericView = (event) => {
        setNumericValue(!numericValue);
        console.log(numericValue);
    };

    const handleChangeScrollable = (event, newValue) => {
        setValueScrollable(newValue);
    };

    const handleUserTypes = () => {
        window.open("/userTypes", "_blank")
    }

    const handleDownloadXls = () => {
        console.log("vale--------------------------------------", subModLabel);
        console.log("subModule--------------------------------------", subModule);
        const filterObj =
        {
            "fromDate": selAnaFilters?.fromDate || moment().startOf('month').format('YYYY-MM-DD'),
            "toDate": selAnaFilters?.toDate || moment().endOf('month').format('YYYY-MM-DD'),
            "filterBy": selAnaFilters?.filterBy || "created_at",
            "group": selAnaFilters?.group || "",
            "teams": selAnaFilters?.teams || "",
            "scoreCard": selAnaFilters?.scoreCard || "",
            "scoreCardGroups": selAnaFilters?.scoreCard || "",
            "eventType": selAnaFilters?.eventType || "",
            "eventTypeEventSubType": selAnaFilters?.eventTypeEventSubType || "",
            "supervisor": selAnaFilters?.supervisor || "",
            "staff": selAnaFilters?.staff || selAnaStaffId || "",
            "scoreCardCategory": selAnaFilters?.scoreCardCategory || "",
            "evaluator": selAnaFilters?.evaluator || "",
            "highlightValue": selAnaFilters?.highlightValue || "",
            "page": "1",
            "filterName": "",
            // "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "flag": selDuration
        }

        const scoreCardFilObj =
        {
            "fromDate": selAnaScoreCardFilters?.fromDate || "",
            "toDate": selAnaScoreCardFilters?.toDate || "",
            "filterBy": selAnaScoreCardFilters?.filterBy || "created_at",
            // "group": setSelAnaScoreCardFilters?.group || "",
            "teams": selAnaScoreCardFilters?.teams || "",
            "scoreCard": selAnaScoreCardFilters?.scoreCard || "",
            "scoreCardGroups": selAnaScoreCardFilters?.group || "",
            "eventType": selAnaScoreCardFilters?.eventType || "",
            "eventTypeEventSubType": selAnaScoreCardFilters?.eventTypeEventSubType || "",
            "supervisor": selAnaScoreCardFilters?.supervisor || "",
            "staff": selAnaScoreCardFilters?.staff || selAnaScStaffId || "",
            "scoreCardCategory": selAnaScoreCardFilters?.scoreCardCategory || "",
            "evaluator": selAnaScoreCardFilters?.evaluator || "",
            "highlightValue": selAnaScoreCardFilters?.highlightValue || "",
            "page": "1",
            "filterName": "",
            // "sup_grp": (setSelAnaScoreCardFilters?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : '',
            "flag": selScDuration,
            "section": "",
            "numericValues": selAnaScoreCardFilters?.numericValues || "",
            "formula": selAnaScoreCardFilters?.formula || "",
        }



        // if (subModLabel === 1.1 && subModule === '1.1 Overall Staff Trend') {
        //     downloadAnalyticsXls(filterObj);
        // } else if (subModLabel === 4.4 && subModule === '4.4 Question Trend') {
        //     dowAnaQuesTrendXls(scoreCardFilObj);
        // } else if (subModLabel === 1.1 && subModule === 'Result Details') {
        //     downloadTrendResDetXls(filterObj);
        // } else if (subModLabel === 4.4 && subModule === 'Question Details') {
        //     dowQueTreDetXls(scoreCardFilObj);
        // }

        // 1.1 Overall Staff Trend
        if (dwnldFn && (label === "Staff" && sbVal === "1.1")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Staff" && sbVal === "1.1 Result")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Staff" && sbVal === "1.4")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Staff" && sbVal === "1.4 Result")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Staff" && sbVal === "1.6")) {
            dwnldFn();
        }

        // 1.2 Question by staff ---START---
        else if (dwnldFn && (label === 'Staff' && sbVal === '1.2')) {
            dwnldFn();
        } else if (dwnldFn && (label === 'Staff' && sbVal === '1.2 Details')) {
            dwnldFn();
        }
        // 1.2 Question by staff ---END---

        // 1.3 Answer By Staff ---START
        // else if (dwnldFn && (label === 'Staff' && (sbVal === '1.3' && (subModule === "1.3 Distribution of Answers" || subModule === "1.3 Answers By Staff")))) {
        //     dwnldFn();
        // }
        // else if (dwnldFn && (label === 'Staff' && (sbVal === '1.3 Details' && (subModule === "1.3 AnswersByStaff Details")))) {
        //     dwnldFn();
        // }
        // 1.3 Answer By Staff ---END

        // 2.1 section download function---
        else if (dwnldFn && (label === "Supervisor" && sbVal === "1")) {
            dwnldFn()
        }

        /**
         * @description 3.5 Evaluation per scorecard evaluator
         */
        else if (dwnldFn && (label === "Evaluation" && sbVal === "3.5")) {
            dwnldFn()
        }

        // 4.1 Overall Trend
        else if (dwnldFn && (label === "Scorecards" && sbVal === "4.1")) {
            dwnldFn();
        }
        else if (dwnldFn && (label === "Scorecards" && sbVal === "4.1-detail")) {
            dwnldFn();
        }

        // 4.2 module ---
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.2")) {
            dwnldFn();
        }
        // else if(dwnldFn && (label === "Scorecards" && sbVal === "4.2 EventType")) {
        //     dwnldFn();

        // }

        // 4.4 Question Trend
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.4")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Scorecards" && sbVal === "4.4 Result")) {
            dwnldFn();
        }

        // 4.10 result --
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.10")) {
            dwnldFn();
        }

        // 4.3 
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.3")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Scorecards" && sbVal === "4.3 EventSubType")) {
            dwnldFn();
        }
        // 4.5 Section Trend
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.5")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Scorecards" && sbVal === "4.5 Result")) {
            dwnldFn();
        }

        // 4.7 Causes By Scorecard
        if (dwnldFn && (label === "Scorecards" && sbVal === "4.7")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Scorecards" && sbVal === "4.7 Result")) {
            dwnldFn();
        }


        if (dwnldFn && (label === "Group" && sbVal === "6.7")) {
            dwnldFn();
        }
        if (dwnldFn && (label === "Staff" && (sbVal === "1.11" || sbVal === "11"))) {
            dwnldFn();
        }
        if (dwnldFn && ((label === "Staff" || label === "Section_Details") && sbVal === "5")) {
            dwnldFn();
        }

        if (dwnldFn && (subModule === "Acceptance By Result" && sbVal === "8")) {

            dwnldFn();

        }

        // 5.2 Points of Failure by Team
        if (dwnldFn && (label === "Teams" && sbVal === "5.2")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Teams" && sbVal === "5.2 team by scorecard")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Teams" && sbVal === "5.2 team by results")) {
            dwnldFn();
        } else if (dwnldFn && (label === "Teams" && sbVal === "5.2 scorecard by results")) {
            dwnldFn();
        }

        // 6.5 module ---
        if (dwnldFn && (subModule === "6.5 Compliance By Teams" && sbVal === "6.5 Compliance By Teams")) {
            dwnldFn();

        }

        if (dwnldFn && (subModule === "6.5 Compliance By Staff" && sbVal === "6.5 Compliance By Staff")) {
            dwnldFn();

        }
    }

    const getMBLink = () => {
        if (!loggedUserData?.staff) {
            return brdCrmbLink
        }

        if (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) {
            return brdCrmbLink

            // return [{ name: 'Home', href: "/supervisor/dashboard" }, { name: 'Reports', href: PATH_DASHBOARD.general.reports }, { name: label }]
        }
    }
    return (
        <>
            <Page title={`${subModule} | BeyondQA`}>
                {/* {showSpinner ? <Spinner /> : */}

                {showSpinner ? <div style={{ height: 'calc(100vh - 200px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <BounceLetterLoader style={{ textAlign: 'center' }} size={40} color="#808080" /> </div> :

                    <>
                        {/* {!loggedUserData?.staff && <HeaderBreadcrumbs
                            sx={{ pl: 4, ml: 3 }}
                            links={[{ name: 'Home', href: PATH_DASHBOARD.root }, { name: 'Reports', href: PATH_DASHBOARD.general.reports }, { name: label }]}
                        />} */}
                        {/* {!loggedUserData?.staff && <HeaderBreadcrumbs
                            sx={{ pl: 4, ml: 3, my: 0 }}
                            links={brdCrmbLink}
                        />}
                        {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <HeaderBreadcrumbs
                            sx={{ pl: 4, ml: 3 }}
                            links={[{ name: 'Home', href: "/supervisor/dashboard" }, { name: 'Reports', href: PATH_DASHBOARD.general.reports }, { name: label }]}
                        />} */}
                        <Box sx={{ width: '100%' }} >
                            <BlockNewUI MbLinks={getMBLink()} title={subModule} buttonValue="Add User" buttonType={
                                <>
                                    {label === "Custom" && <AddCustomReportDialog addUserButton text="Add Report" filterValue={undefined} setAddMode disableBackdropClick />}
                                    {(label === "Staff" && (sbVal === "1.1" || sbVal === "1.2" || sbVal === "1.3" || sbVal === "1.10" || sbVal === "1.8" || sbVal === "1.7" || sbVal === "1.11" || sbVal === "5" || sbVal === "1.4" || sbVal === "1.6" || sbVal === "1.9")) && <AnalyticsFilterDialog addUserButton setAddMode disableBackdropClick />}
                                    {(label === "Scorecards" && (sbVal === "4.1" || sbVal === "4.2 EventTypeTrend" || sbVal === "4.3" || sbVal === "4.4" || sbVal === "4.5" || sbVal === "4.6" || sbVal === "4.7" || sbVal === "4.8" || sbVal === "4.9" || sbVal === "4.10" || sbVal === "4.11")) && <AnalyticsScorecardFilterDialog addUserButton setAddMode disableBackdropClick />}
                                    {/* {(label === "team" && (sbVal === "5.1" || sbVal === "5.11" || sbVal === "5.12")) && <AnalyticsFilterDialog addUserButton setAddMode disableBackdropClick />} */}
                                    {(label === "Teams" && (sbVal === "5.2" || sbVal === "5.1" || sbVal === "5.11" || sbVal === "5.12")) && <AnalyticsTeamsFilterDialog addUserButton setAddMode disableBackdropClick />}
                                    {(label === "Group" && (sbVal === "6.1" || sbVal === "6.2" || sbVal === "6.3" || sbVal === "6.4" || sbVal === "6.5" || sbVal === '6.6' || sbVal === "6.7")) && <DialogAcceptanceByGroup addUserButton />}
                                    {(label === "Evaluations" && (sbVal === "3.1" || sbVal === "3.5" || sbVal === "3.7" || sbVal === "3.6" || sbVal === "3.3" || sbVal === "3.2" || sbVal === "3.4")) && <AnalyticEvaluationFilterDialog addUserButton />}
                                    {(label === "Supervisor" && (sbVal === "2.1" || sbVal === '2.2' || sbVal === "2.3" || sbVal === '2.4')) && <AnalyticSupervisorFilterDialog addUserButton />}
                                </>
                            } >
                                <Box sx={{ minHeight: '250px', width: '100%', pb: 4, borderRadius: '5px', backgroundColor: theme => theme.palette.background.paper }}>
                                    <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ py: 2, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}`, width: "100%" }}>
                                        <Box sx={{ pl: 1 }}>
                                            <Button onClick={(e) => { setTabNum(1); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Staff" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Staff</Button>
                                            <Button onClick={(e) => { setTabNum(2); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Supervisors" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Supervisor</Button>
                                            <Button onClick={(e) => { setTabNum(3); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Evaluations" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Evaluations</Button>
                                            <Button onClick={(e) => { setTabNum(4); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Scorecards" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Scorecards</Button>
                                            <Button onClick={(e) => { setTabNum(5); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Teams" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Teams</Button>
                                            <Button onClick={(e) => { setTabNum(6); handleClick(e); }} sx={{ mr: 2, position: "relative", "&:after": { content: '""', top: "90%", position: "absolute", width: "100%", height: "2px", backgroundColor: label === "Group" ? setColor?.main : "transparent" } }} size="small" endIcon={<KeyboardArrowDown />}>Groups</Button>
                                            <Button onClick={() => { navigate('/analytics/advanced_analytics'); setSavedFilterListDatas([]); setDataTagByScoreCard(); setAdvancedTableData([]); }} sx={{ mr: 2 }} size="small" >Advance</Button>
                                        </Box>
                                        <Box flexDirection="row" justifyContent="flex-end" sx={{ ml: 1, pr: 2 }}>
                                            {(dwnldFn && (sbVal === 'analytic_results_1' || sbVal === 'analytic_results_2' || sbVal === 'analytic_results_3' || sbVal === 'analytic_results_4' || sbVal === 'analytic_results_6' || sbVal === "1.1" || sbVal === '1.1 Result' || sbVal === "1.2" || sbVal === "1.3" || sbVal === '1.3 Details' || sbVal === "1.4" || sbVal === "1.6" || sbVal === "1.6 Details" || sbVal === "1.7" || sbVal === '1.8' || sbVal === "1.9" || sbVal === "1.10" || sbVal === "1.11" || sbVal === '2.1' || sbVal === '2.2' || sbVal === "2.3" || sbVal === "2.3 Details" || sbVal === '2.4' || sbVal === "3.1" || sbVal === '3.2' || sbVal === "3.3" || sbVal === "3.4" || sbVal === "3.5" || sbVal === "3.6" || sbVal === "3.7" || sbVal === "4.1" || sbVal === '4.1-detail' || sbVal === '4.2' || sbVal === "4.2 EventTypeTrend" || sbVal === "4.2 EventSubTypeTrend" || sbVal === "4.3" || sbVal === '4.3 EventSubType' || sbVal === '4.3ResultPage' || sbVal === '4.4 Result' || sbVal === "4.4" || sbVal === "4.5" || sbVal === "4.6" || sbVal === "4.7" || sbVal === "4.8" || sbVal === "4.8 Details" || sbVal === '4.9' || sbVal === '4.10' || sbVal === '4.10Result' || sbVal === '4.11' || sbVal === '4.11_results' || sbVal === "5" || sbVal === '5.1' || sbVal === '5.1 Details' || sbVal === '5.11' || sbVal === "5.12" || sbVal === "5.2" || sbVal === "6.1" || sbVal === "6.2" || sbVal === "6.3" || sbVal === '6.4' || sbVal === '6.5' || sbVal === "6.5 Compliance By Team" || sbVal === "6.5 Compliance By Staff" || sbVal === '6.6' || sbVal === '6.7' || sbVal === "6.11" || sbVal === '6.12' || sbVal === "7.1")) && <Button sx={{ ml: 5 }} size="small" onClick={() => { dwnldFn() }} startIcon={<Download />} >Download XLS</Button>}

                                            {(label === "Custom" || (sbVal === 'analytic_results_1' || sbVal === 'analytic_results_2' || sbVal === 'analytic_results_3' || sbVal === 'analytic_results_4' || sbVal === 'analytic_results_6' || sbVal === "1.1" || sbVal === '1.1 Result' || sbVal === "1.2" || sbVal === "1.3" || sbVal === "1.3 Details" || sbVal === "1.4" || sbVal === "1.6" || sbVal === "1.6 Details" || sbVal === "1.7" || sbVal === '1.8' || sbVal === "1.9" || sbVal === "1.10" || sbVal === "1.11" || sbVal === '2.1' || sbVal === '2.2' || sbVal === "2.3" || sbVal === "2.3 Details" || sbVal === '2.4' || sbVal === '3' || sbVal === "3.1" || sbVal === '3.2' || sbVal === "3.3" || sbVal === "3.4" || sbVal === "3.5" || sbVal === "3.6" || sbVal === "3.7" || sbVal === "4.1" || sbVal === '4.2' || sbVal === '4.1-detail' || sbVal === "4.2 EventSubTypeTrend" || sbVal === "4.2 EventTypeTrend" || sbVal === "4.3" || sbVal === '4.3ResultPage' || sbVal === '4.3 EventSubType' || sbVal === "4.4" || sbVal === '4.4 Result' || sbVal === "4.5" || sbVal === "4.6" || sbVal === "4.7" || sbVal === "4.7 Sections" || sbVal === "4.7 Question" || sbVal === "4.7 Ind Question" || sbVal === "4.8" || sbVal === "4.8 Details" || sbVal === '4.9' || sbVal === '4.10' || sbVal === '4.10Result' || sbVal === '4.11_results' || sbVal === '4.11' || sbVal === "5" || sbVal === '5.1' || sbVal === '5.1 Details' || sbVal === '5.11' || sbVal === "5.12" || sbVal === "5.2" || sbVal === "6.1" || sbVal === "6.2" || sbVal === "6.3" || sbVal === '6.4' || sbVal === "6.5 Compliance By Team" || sbVal === '6.5' || sbVal === "6.5 Compliance By Staff" || sbVal === "6.5 Compliance By Staff" || sbVal === '6.6' || sbVal === '6.7' || sbVal === '6.11' || sbVal === '6.12' || sbVal === "7.1")) ? ' ' : (<Button sx={{ ml: 5, fontSize: "12px" }} size="small" startIcon={<Download />} onClick={handleDownloadXls}>Download XLS</Button>)}

                                            {(((label === "Staff" || label === "Scorecards") && (sbVal === "5" || sbVal === "4.5")) && dwnldFn) ? (<FormControlLabel control={<Switch sx={{ ml: 4, mr: 0 }} checked={numeric} onChange={() => { setNumeric(!numeric) }} size='small' />} label="Numeric" />) : ''}
                                            {((label === "Staff" && sbVal === "5") && dwnldFn) ? (<Button sx={{ ml: 2, fontSize: "12px" }} size="small" startIcon={<Cached />} onClick={() => { setSwitchView(!switchView); switchFn(!switchView) }}>Switch View</Button>) : ''}
                                            {((label === "Staff" && sbVal === "1.10") && dwnldFn) ? (<FormControlLabel control={<Switch sx={{ ml: 4, mr: 0 }} checked={isPercentage} onChange={() => { percentageFn(!isPercentage) }} size='small' />} label="Percentage" />) : ''}
                                            {((label === "Scorecards" && sbVal === "4.11") && dwnldFn) ? (<FormControlLabel control={<Switch sx={{ ml: 4, mr: 0 }} checked={isPercentage} onChange={() => { percentageFn(!isPercentage) }} size='small' />} label="Percentage" />) : ''}

                                        </Box>

                                    </Stack>
                                    <Stack alignItems="center" justifyContent="end" direction="row" sx={{ pb: 1, width: "100%" }}>
                                        <Box flexDirection="row" justifyContent="flex-end" sx={{ ml: 1, pr: 2 }}>
                                            {((label === "Scorecards" && sbVal === "4.10") && dwnldFn) ? (<FormControlLabel control={<Switch sx={{ ml: 4, mr: 0 }} checked={isPercentage} onChange={() => { percentageFn(!isPercentage) }} size='small' />} label="Percentage" />) : ''}
                                            {((label === "Supervisor" && sbVal === "2.4") && dwnldFn) ? (<FormControlLabel control={<Switch sx={{ ml: 4, mr: 0 }} checked={numeric} onChange={() => { setNumeric(!numeric) }} size='small' />} label="Numeric" />) : ''}

                                        </Box>
                                    </Stack>
                                    <Box sx={{ width: "100%", px: 1, }}>
                                        {children}
                                    </Box>
                                </Box>
                            </BlockNewUI>

                        </Box>
                    </>
                }
            </Page >

            {tabNum === 1 && <Menu anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule1) }} >
                    1.1 &nbsp; Overall Staff Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule2) }}>
                    1.2 &nbsp; Question By Staff
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.3); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule3) }}>
                    1.3 &nbsp; Answer By Staff
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.4); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule4) }}>
                    1.4 &nbsp; Overall Trend By Individual
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.5); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule5) }}>
                    1.5 &nbsp; Section Trend By Individual
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.6); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule6) }}>
                    1.6 &nbsp; Question Trend By Individual
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.7); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule7) }}>
                    1.7 &nbsp; Section By Result
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.8); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule8) }}>
                    1.8 &nbsp; Section By Event Type
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.9); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule9) }}>
                    1.9 &nbsp; Kudos By Staff
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel('1.10'); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule10); }}>
                    1.10 &nbsp; Causes By Staff
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(1.11); navigate(PATH_DASHBOARD.general.analyticsStaffs.subModule11); }}>
                    1.11 &nbsp; Result Acceptance By Agent
                </MenuItem>
            </Menu>}

            {tabNum === 2 && <Menu anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem dense divider
                    onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsSupervisors.subModule1) }}
                >
                    2.1 &nbsp; Supervisor by Section
                </MenuItem>
                <MenuItem dense divider
                    onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsSupervisors.subModule2) }}

                >
                    2.2 &nbsp; Points of Failure by Supervisor
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsSupervisors.subModule3) }}
                >
                    2.3 &nbsp; Supervisor by Question
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsSupervisors.subModule4) }}
                >
                    2.4 &nbsp; Result Acceptence By Supervisor
                </MenuItem>
            </Menu>}

            {tabNum === 3 && <Menu anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.1); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule1) }} >
                    3.1 &nbsp; Scoring Patterns
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.2); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule2) }}>
                    3.2 &nbsp; Evaluations Per Period
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.3); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule3); }}>
                    3.3 &nbsp; Evaluations Per Scorecard
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.4); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule4) }}>
                    3.4 &nbsp; Evaluations Per Scorecard By Staff
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.5); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule5) }}>
                    3.5 &nbsp; Evaluations Per Scorecard By Evaluator
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.6); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule6) }}>
                    3.6 &nbsp; Reviews By Evaluator
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(3.7); navigate(PATH_DASHBOARD.general.analyticsEvaluation.subModule7) }}>
                    3.7 &nbsp; Reviews By Supervisor
                </MenuItem>
            </Menu>}

            {tabNum === 4 && <Menu anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule1) }} >
                    4.1 &nbsp; Overall Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule2) }} >
                    4.2 &nbsp; Event Type Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule3) }}>
                    4.3 &nbsp; Performance Against Target
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule4) }}>
                    4.4 &nbsp; Question Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule5) }}>
                    4.5 &nbsp; Section Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule6) }}>
                    4.6 &nbsp; Comments
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule7) }}>
                    4.7 &nbsp; Causes By Scorecard
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule8) }}>
                    4.8 &nbsp; Causes Trend
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule9) }}>
                    4.9 &nbsp; Compliance By Question
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule10) }}>
                    4.10 &nbsp; Data Tags By Event Type
                </MenuItem>
                <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsScoreCards.subModule11) }}>
                    4.11 &nbsp; Customer Reference By Data Tags
                </MenuItem>
            </Menu>}

            {
                tabNum === 5 && <Menu anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(5.1); navigate(PATH_DASHBOARD.general.analyticsTeams.subModule1) }} >
                        5.1 &nbsp; Overall Team Trends
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); setSubModLabel(5.2); navigate(PATH_DASHBOARD.general.analyticsTeams.subModule2) }}>
                        5.2 &nbsp; Point of Failure by Team
                    </MenuItem>
                </Menu>
            }

            {
                tabNum === 6 && <Menu anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule1) }} >
                        6.1 &nbsp; Summary
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule2) }}>
                        6.2 &nbsp; Scorecard Categories
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule3) }}>
                        6.3 &nbsp; Points Of Failure
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule4) }}>
                        6.4 &nbsp; Customer Refernce
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule5) }}>
                        6.5 &nbsp; Compliance Overview
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule6) }}>
                        6.6 &nbsp; Calibration Trend
                    </MenuItem>
                    <MenuItem dense divider onClick={() => { handleClose(); navigate(PATH_DASHBOARD.general.analyticsGroups.subModule7) }}>
                        6.7 &nbsp; Result Acceptance By Group
                    </MenuItem>
                </Menu>
            }
            <AddCustomReportDialog open={customOpen} setOpen={setCustomOpen} />

        </>
    );
}
