import React from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button, Tooltip, Badge, Menu, MenuItem } from '@material-ui/core';
import { Mail, KeyboardArrowDown } from "@material-ui/icons";

// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { SettingsContext } from "../../../contexts/SettingsContext";
import { EmpContext } from "../../../contexts/employeecontext";
// components
// import { MHidden } from '../../../components/@material-extend';
// import Searchbar from './Searchbar';
import AccountPopover from '../AccountPopover';
import Logo from "../../../components/Logo";
import { PATH_EMP, PATH_DASHBOARD } from "../../../routes/paths";
import { getMessageCount } from "../../../_apis_/authApi.js/globalDashboardApi";

// import LanguagePopover from '../LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
// const COLLAPSE_WIDTH = 60;
const COLLAPSE_WIDTH = 0;

// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 34;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
    width: "100%"

}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

SupervisorDashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

const isScore = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isStaff = "Global Admin,Group Admin,Team Admin,Internal"
const isScorecard = "Global Admin,Group Admin,Internal"
const isAnalytics = "Global Admin,Group Admin,Team Admin,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics"
const isReports = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics,Reports Only"
const isCalibrate = "Global Admin,Group Admin,Team Admin,Score & Calibrate,Calibrate Only,(Score, Calibrate, Report & Analytics)"



export default function SupervisorDashboardNavbar({ onOpenSidebar }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    // const [messageCount, setMessageCount] = React.useState(0);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isCollapse } = useCollapseDrawer();
    const { setColor, themeMode,
        showHelpSupportPopUp, setShowHelpSupportPopUp,
        setNotificationMsgCount, notificationMsgCount
    } = React.useContext(SettingsContext);
    const { activeMenu, setActiveMenu } = React.useContext(EmpContext);


    const getThemeColor = () => {
        if (themeMode === 'light') {
            return setColor?.main
        }
        return setColor?.dark
    }


    React.useEffect(() => {
        async function getCount() {
            const { data } = await getMessageCount();
            setNotificationMsgCount(data?.data);
        }
        getCount();
    }, [pathname, notificationMsgCount])
    

    return (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
                })
            }}
        >

            <ToolbarStyle>
                {/* <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden> */}
                <Link to={!loggedUserData?.staff?.staffType ? "/dashboard" : "/supervisor/dashboard"}>
                    <Box sx={{ mr: 5 }}><Logo /></Box>
                </Link>
                {/* <Searchbar /> */}
                <Box sx={{ flexGrow: 1, ml: 2 }} >
                    <Tooltip title={!loggedUserData?.staff?.staffType ? "Global Dashboard" : "Supervisor Dashboard"}>
                        <Link to={PATH_DASHBOARD.general.dashboard} style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "supervisor" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Dashboard</Typography>
                            </Button>

                        </Link>
                    </Tooltip>
                    {((loggedUserData?.staff?.staffType === 30 || loggedUserData?.staff?.staffType === 20) || isScore.includes(loggedUserData?.userLevel?.name)) && <Link to={searchParams.get("stff_sup_id") ? PATH_DASHBOARD.general.score.index : `/score/select_emp?id=${grpArr.join(",")}&sup_dash=True`} style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.split("/")[1] === "score" ? getThemeColor() : "transparent" }, color: pathname.split("/")[1] === "score" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Score</Typography>
                        </Button>
                    </Link>}
                    {((loggedUserData?.staff?.staffType === 30 || loggedUserData?.staff?.staffType === 20) || isStaff.includes(loggedUserData?.userLevel?.name)) && <Tooltip title="Employee">
                        <Link to={PATH_DASHBOARD.general.Staff} style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/staff" ? getThemeColor() : "transparent" }, color: pathname === "/staff" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Staff</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}
                    {(loggedUserData?.staff?.staffType === 30 || (!loggedUserData?.staff && isScorecard.includes(loggedUserData?.userLevel?.name))) && <Tooltip title="Scorecard">
                        <Link to={PATH_DASHBOARD.general.Scorecards} style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/scorecards" ? getThemeColor() : "transparent" }, color: pathname === "/scorecards" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Scorecard</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}
                    {(searchParams.get("stff_sup_id") && isScorecard.includes(loggedUserData?.userLevel?.name)) && <Tooltip title="Admin">
                        <Link to={PATH_DASHBOARD.general.admin} style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/admin" ? getThemeColor() : "transparent" }, color: pathname === "/admin" ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Admin</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}

                    {((loggedUserData?.staff?.staffType === 30 || loggedUserData?.staff?.staffType === 20) || isReports.includes(loggedUserData?.userLevel?.name)) && <Tooltip title="Reports">
                        <Link to={PATH_DASHBOARD.general.reports} style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("reports") ? getThemeColor() : "transparent" }, color: pathname.includes("reports") ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Reports</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}
                    {((loggedUserData?.staff?.staffType === 30 || loggedUserData?.staff?.staffType === 20) || isCalibrate.includes(loggedUserData?.userLevel?.name)) && <Tooltip title="Calibration">
                        <Link to="/calibration" style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? getThemeColor() : "transparent" }, color: pathname.includes("calibration") ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} > Calibration</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}

                    {((loggedUserData?.staff?.staffType === 30 || loggedUserData?.staff?.staffType === 20) || isAnalytics.includes(loggedUserData?.userLevel?.name)) && <Tooltip title="Analytics">
                        <Link to="/analytics" style={{ textDecoration: "none" }}>
                            <Button disableRipple sx={{ mx: 1, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("analytics") ? getThemeColor() : "transparent" }, color: pathname.includes("analytics") ? getThemeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: getThemeColor() } }}>
                                <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analytics</Typography>
                            </Button>
                        </Link>
                    </Tooltip>}
                    {(loggedUserData?.isAiAccess && loggedUserData?.aiEnabled) && <Button sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", color: "gray", "&:hover": { backgroundColor: "transparent", color: "gray" } }} endIcon={<KeyboardArrowDown fontSize='small' />} onClick={handleOpenMenu} disableRipple >Speech AI Hub</Button>}

                </Box>
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5, display: "none" }}>

                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover /> */}
                    {/* <ContactsPopover /> */}
                    <Button onClick={() => setShowHelpSupportPopUp(!showHelpSupportPopUp)} variant='outlined' sx={{ borderRadius: 5, boxShadow: 'none', mr: 1 }} size='small' > Support</Button>

                    {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <IconButton sx={{ mr: 1 }} onClick={() => { navigate("/notification"); }}> <Badge badgeContent={notificationMsgCount} color="primary"><Icon icon='ion:mail-outline' style={{ fontSize: '32px', fontWeight: 'lighter', color: themeMode === 'light' ? setColor?.main : setColor?.dark }} /></Badge></IconButton>}

                    <AccountPopover />
                </Stack>

                <Box>
                    <Menu
                        open={open}
                        onClose={() => handleCloseMenu()}
                        anchorEl={anchorEl}
                        autoFocus={false}
                        sx={{ border: '1px solid transparent', boxShadow: 0, minWidth: '150px' }}
                    >
                        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/events') }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Events</Typography>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/calls') }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calls</Typography>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/analyze') }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analyze</Typography>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/auto_qa_rules') }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Auto QA</Typography>
                        </MenuItem>
                        <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/coaching/add_coaching?csId=AC') }}>

                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Coaching</Typography>
                        </MenuItem>
                    </Menu>
                </Box>

            </ToolbarStyle>
        </RootStyle>
    );
}
