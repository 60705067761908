import PropTypes from 'prop-types';
// material
import { Card, CardHeader, Box, Typography, Button, Divider } from '@material-ui/core';
//
import AddUserDialog from './material-ui/dialog/AddUserDialog';

// ----------------------------------------------------------------------

Label.propTypes = {
  title: PropTypes.string
};

export function Label({ title }) {
  return (
    <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
      {title}
    </Typography>
  );
}

Block.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object
};

export function Block({ title, sx, children, buttonType, component }) {

  return (
    <Card
      sx={{
        overflow: 'unset',
        position: 'unset',
        width: '100%',
        borderRadius: '5px',
        boxShadow: 'none'
      }}>

      <Box
      >
        {component}
      </Box>
      <CardHeader
        // sx={{ mt: 2, mb: 2 }}
        title={title}
        subheader={component && 'Use this section to build custom reports, please keep large date ranges to a minimum.'}
        titleTypographyProps={{ variant: 'h4' }}
        action={buttonType}
      />
      <Divider />
      <Box
        sx={{
          // px: 3,
          // minHeight: 180,
          ...sx
        }}
      >
        {children}
      </Box>
    </Card >
  );
}
