import React from 'react';
// MATERIAL IMPORTS
import { IconButton, Stack, Tooltip, /* Box, Typography, Button, Divider, */ } from "@material-ui/core";
import { FilterAltOutlined } from "@material-ui/icons";
// OTHER COMPONENTS IMPORTS
import Page from '../../components/Page';
import { BlockNewUI } from '../components-overview/Block_newUI';
import GlobalDatePicker from "./datepicker";
import GloabalDashboardBody from "./main";
import FilterDialog from './filter';
// CONTEXT IMPORTS
import { StaffContext } from '../../contexts/staffContext';


export default function AdminDashboard() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT STATES
    const [open, setOpen] = React.useState(false);

    // CONTEXT STATES
    const { strtDate, endDate, tester } = React.useContext(StaffContext);
    const handleGlobalDashboard = () => {
        switch (loggedUserData?.userLevel?.name) {
            case 'Global Admin':
                return 'Global Dashboard';
            case 'Employee':
                return 'Admin Employee Dashboard';
            case 'Team Admin':
                return 'Team Dashboard';
            case 'Group Admin':
                return 'Group Dashboard';
            case 'Score, Reports & Analytics':
                return 'Score, Reports & Analytics Dashboard';
            case 'Score, Calibrate, Report & Analytics':
                return 'Score, Calibrate, Report & Analytic Dashboard';
            case 'Reports & Analytics':
                return 'Reports & Analytics Dashboard';
            case 'Score & Calibrate':
                return 'Score & Calibrate';
            case 'Score Only':
                return 'Score Dashboard';
            case 'Reports Only':
                return "Reports Dashboard";
            case 'Billing Only':
                return 'Billing Dashboard';
            case 'Dashboard Only':
                return 'Dashboard';
            case 'Calibrate Only':
                return 'Calibrate Dashboard';
            case 'Score & Reports':
                return 'Score & Reports Dashboard';
            case 'Advanced Supervisor':
                return 'Advanced Supervisor Dashboard';
            case 'Supervisor':
                return 'Supervisor Dashboard';
            default:
                return 'Global Dashboard'

        }
    }
    return (
        <>
            <Page title="Dashboard | BeyondQA" sx={{ py: 1, px: 3 }}>

                {/* <HeaderBreadcrumbs
                    sx={{ pl: 2, ml: 3, pt: 1, pr: 3 }}
                    links={[{ name: 'Home/Dashboard', href: '/dashboard' }]}
                /> */}
                {/* <Box sx={{ py: 2, borderRadius: 1, width: "100%" }}>

                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 1, borderRadius: 1 }}>
                        <Box>
                            <Typography variant="h4" sx={{ display: 'inline-block', color: '#666666' }}>
                                {loggedUserData?.userLevel?.name?.replace('Admin', '')} Dashboard
                            </Typography>
                            <HeaderBreadcrumbs
                                sx={{ m: 0, color: '#666666', fontWeight: 400 }}
                                links={[{ name: 'Home/Dashboard', href: '/dashboard' }]}
                            />
                        </Box>

                        <Stack direction="row" alignItems="center" >
                            <GlobalDatePicker />

                            <IconButton size='small' variant="contained" sx={{ mx: 1, backgroundColor: 'white', borderRadius: '5px', border: '1px solid #dddde7' }} onClick={() => { setOpen(true) }} >
                                <FilterAltOutlined size='small' sx={{ color: '#636e72', stroke: "white", strokeWidth: '1px', backgroundColor: 'white' }} />
                            </IconButton>


                        </Stack>

                    </Stack>
                    < >
                        <GloabalDashboardBody />
                    </>
                </Box>

                <FilterDialog openFilter={open} setOpenFilter={setOpen} /> */}

                <BlockNewUI MbLinks={[{ name: 'Home/Dashboard', href: '/dashboard' }]} title={handleGlobalDashboard()} buttonType={<Stack direction="row" alignItems="center" >
                    <GlobalDatePicker />

                    <Tooltip title='Filter'>
                        <IconButton size='small' variant="contained" sx={{ mx: 1, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper }, backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px', border: theme => `1px solid ${theme.palette.text.primaryBorder}` }} onClick={() => { setOpen(true) }} >
                            <FilterAltOutlined size='small' sx={{ color: '#636e72', stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px', '&:hover': { backgroundColor: (theme) => theme.palette.background.paper }, backgroundColor: (theme) => theme.palette.background.paper }} />
                        </IconButton>
                    </Tooltip>


                </Stack>} >
                    <GloabalDashboardBody />
                    <FilterDialog openFilter={open} setOpenFilter={setOpen} />
                </BlockNewUI>

            </Page>
        </>
    )
}