import React from 'react';
// MATERIAL IMPORTS
import {
    Box, Button, Menu, MenuItem, Tooltip
} from '@material-ui/core';
// REACT ROUTER IMPORTS
import { useSearchParams, useLocation } from 'react-router-dom';
import {
    CalendarTodayOutlined,
} from '@material-ui/icons';
// NPM IMPORTS
import PropTypes from 'prop-types';
import DatePicker from "react-multi-date-picker";
import moment from 'moment';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import "../../assets/datepicker.css";

// API IMPORTS
import { supScorecardListApi } from "../../_apis_/authApi.js/staffApi";
// -------------------------------------------------------------------------------------

EmpDatePicker.propTypes = {
    scoreTab: PropTypes.func
}


export default function EmpDatePicker({ scoreTab }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER DOM STATES
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    // REACT STATE
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [selectedItem, setSelectedItem] = React.useState(3);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (value) => {
        setValue(value)
    }
    const { filterby, strtDate, endDate, setStrtDate, setEndDate, dateItem, setDateItem, datesData, setDatesData, grpSup,
        tmSup, selectScrd, scCrd, setScScrd, setScreList, setTabValue, supDashData, setSupDashData } = React.useContext(EmpContext);



    const [value, setValue] = React.useState([strtDate, endDate]);

    const handleUpdateScoreList = async (date1, date2) => {
        setSupDashData([]);
        const res = await supScorecardListApi(loggedUserData?.staff?.id || searchParams.get("stff_sup_id"), date1, date2, selectScrd, grpSup, tmSup, filterby);
        setScreList(res?.data?.results);
        setScScrd([res?.data?.results[0]?.id]);
        setTabValue("1");
    }

    React.useEffect(() => {
        if (datesData?.date2 !== undefined && pathname?.split("/")[2] === "dashboard" && datesData?.date1 !== searchParams.get("date1") && pathname?.split("/")[1] === "supervisor" && ((loggedUserData?.staff?.staffType !== 10 && loggedUserData?.staff?.staffType !== undefined) || searchParams.get("stff_sup_id"))) {
            handleUpdateScoreList(datesData?.date1, datesData?.date2);
        }
        if (searchParams.get('stff_sup_id') && datesData?.date1 !== undefined && datesData?.date1 !== searchParams.get("date1")) {
            const tempId = searchParams.get('stff_sup_id')
            setSearchParams({ stff_sup_id: tempId, date1: datesData?.date1, date2: datesData?.date2 })
        }
    }, [datesData])

    const datePickerRef = React.useRef();

    const handleDate = (val) => {
        if (val === "today") {
            setStrtDate(moment(0, "HH").format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();
        }
        if (val === "l7days") {
            setStrtDate(moment(0, "HH").subtract(1, 'week').format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").subtract(1, 'week').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();
        }
        if (val === "l30days") {
            setStrtDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();

        }
        if (val === "thismonth") {
            setStrtDate(moment(0, "HH").startOf('month').format('YYYY-MM-DD'));
            setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").startOf('month').format('YYYY-MM-DD'), date2: moment().endOf('month').format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();
        }
        if (val === "lastmonth") {
            setStrtDate(moment(0, "HH").startOf('month').subtract(1, 'month').format('YYYY-MM-DD'));
            setEndDate(moment(24, "HH").subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), date2: moment(24, "HH").subtract(1, 'month').endOf('month').format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();
        }
        if (val === "quarter") {
            setStrtDate(moment(0, "HH").startOf('quarter').format('YYYY-MM-DD'));
            setEndDate(moment(24, "HH").endOf('quarter').format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").startOf('quarter').format('YYYY-MM-DD'), date2: moment(24, "HH").endOf('quarter').format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();

        }
        if (val === "year") {
            setStrtDate(moment(0, "HH").startOf("year").format('YYYY-MM-DD'));
            // setEndDate(moment().format('YYYY-MM-DD'));
            setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
            setDatesData({ date1: moment(0, "HH").startOf("year").format('YYYY-MM-DD'), date2: moment().endOf('year').format('YYYY-MM-DD') })
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            return handleClose();

        }
        if (val === "custom") {
            const fromDate = moment(value[0].toString()).format("YYYY-MM-DD");
            const toDate = moment(value[1].toString()).format("YYYY-MM-DD");
            setStrtDate(fromDate); setEndDate(toDate);
            setDatesData({ date1: fromDate, date2: toDate });
            if (pathname?.includes('employee') && pathname?.includes('scores') && scoreTab) { scoreTab('1'); setSearchParams({ tab: 1 }) }

            datePickerRef.current.closeCalendar();
        }
    }

    return (
        <>
            <Box display="flex" sx={{ position: "relative" }}>

                <Box>
                    <Tooltip title="Pick a date">
                        <Button startIcon={<CalendarTodayOutlined sx={{ color: '#636e72', stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px' }} />} variant="contained" sx={{ backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }} onClick={handleClick}>
                            {datesData?.date1}&nbsp;-&nbsp;{datesData?.date2}
                        </Button>
                    </Tooltip>
                    {/* <Button variant="contained" sx={{ mx: 1 }} onClick={handleClick}>
                        Filter
                    </Button> */}
                    <Box position="absolute" sx={{ top: "0" }}>
                        <DatePicker
                            numberOfMonths={1}
                            className="green"
                            ref={datePickerRef}
                            calendarPosition="bottom-end"
                            onChange={handleChange}
                            render={(value, openCalendar) => {
                                return (
                                    <Box onClick={openCalendar} sx={{ visibility: "hidden" }}>one</Box>
                                )
                            }}
                            maxDate={new Date()}
                            range

                        >
                            <Button sx={{ my: 2 }} onClick={() => { handleDate("custom") }} >Confirm</Button>
                            <Button sx={{ my: 2 }} onClick={() => { datePickerRef.current.closeCalendar(); }} >Cancel</Button>
                        </DatePicker>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem selected={dateItem === 0} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(0); handleDate("today") }}>Today</MenuItem>
                        <MenuItem selected={dateItem === 1} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(1); handleDate("l7days") }}>Last 7 days</MenuItem>
                        <MenuItem selected={dateItem === 2} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(2); handleDate("l30days") }}>Last 30 days</MenuItem>
                        <MenuItem selected={dateItem === 3} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(3); handleDate("thismonth") }}>This Month</MenuItem>
                        <MenuItem selected={dateItem === 4} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(4); handleDate("lastmonth") }}>Last Month</MenuItem>
                        <MenuItem selected={dateItem === 5} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(5); handleDate("quarter") }}>This Quarter</MenuItem>
                        <MenuItem selected={dateItem === 6} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(6); handleDate("year") }}>This Year</MenuItem>
                        <MenuItem selected={dateItem === 7} sx={{ fontSize: "12px", color: theme => theme.palette.text.primary, "&:hover": { color: "black" } }} onClick={() => { setDateItem(7); handleClose(); datePickerRef.current.openCalendar(); }}>Custom Range</MenuItem>



                    </Menu>

                </Box>
            </Box>
        </>
    )
}