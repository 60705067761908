import PropTypes from 'prop-types';
// material
import { Card, CardHeader, Box, Typography, Button, Divider } from '@material-ui/core';
//
import AddUserDialog from './material-ui/dialog/AddUserDialog';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

Label.propTypes = {
    title: PropTypes.string
};

export function Label({ title }) {
    return (
        <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
            {title}
        </Typography>
    );
}

BlockNewUI.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sx: PropTypes.object,
    MbLinks: PropTypes.array,
};

export function BlockNewUI({ title, sx, children, buttonType, component, MbLinks, widthType }) {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    return (
        <Card
            elevation={0}
            sx={{ overflow: 'unset', boxShadow: 'none', position: 'unset', width: '100%', backgroundColor: 'transparent', borderRadius: 1, border: '1px solid transparent', widthType }}>

            {component}
            <CardHeader sx={{ mt: loggedUserData?.staff ? 3 : 0, p: 0, color: (theme) => theme.palette.mode === 'light' ? '#666666' : '#fff' }} title={<><Box>{title}</Box><HeaderBreadcrumbs
                sx={{ m: 0, color: '#666666', fontWeight: 600 }}
                links={MbLinks}
            /></>} subheader={component && 'Use this section to build custom reports, please keep large date ranges to a minimum.'} titleTypographyProps={{ sx: { fontSize: { lg: '20px', xl: '28px' } } }} action={<Box sx={{ mt: 3 }}>{buttonType}</Box>} />
            <Box
                sx={{
                    // px: 3,
                    minHeight: 180,
                    ...sx
                }}
            >
                {children}
            </Box>
        </Card>
    );
}
