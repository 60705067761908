import React, { useState, useCallback } from 'react';
// REACT ROUTER IMPORTS
import { useSearchParams, useNavigate } from 'react-router-dom';
// MATERIAL IMPORTS 
import {
    Box, Button, CircularProgress, Chip, InputAdornment, Grid, Paper, TableHead, TableRow,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, Stack, Tooltip, TablePagination,
    Typography, OutlinedInput, Tab, IconButton
} from '@material-ui/core';
// import TableHead from '@mui/material/TableHead';
// import  from '@mui/material/TableRow';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Warning, Flag, Group, Star, MilitaryTech, CheckBox, RemoveCircleOutline, ArrowForward, ArrowDownward, ArrowUpward } from '@material-ui/icons';
// NPM IMPORTS 
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import _ from "lodash";
import { styled } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';
import moment from 'moment';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import { SettingsContext } from '../../contexts/SettingsContext';
// OTHER IMPORTS
import EmpDatePicker from "./datepicker";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../routes/paths';
import { setButtonBg } from '../../utils/filterUtilities';
// API IMPORTS
import { empScoreListApi, empScoreDetailsApi, myscorelistScorePageApi } from "../../_apis_/authApi.js/empapi";


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 45,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


const myScoreHeader = [
    { name: 'Id' },
    { name: 'Group' },
    // { name: 'Created Date' },
    { name: 'Event Date' },
    { name: 'Category' },
    { name: 'Evaluator' },
    { name: 'Score' },
    { name: 'Status' },
    {
        id: 'feedaback',
        numeric: false,
        disablePadding: false,
        name: 'Feedback Date'
    },
    {
        id: 'reviewdby',
        numeric: false,
        disablePadding: false,
        name: 'Reviewed By'
    },
    {
        id: 'Action',
        numeric: false,
        disablePadding: false,
        name: 'Action'
    },
]




export default function EmpScore() {

    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // CONTEXT STATES
    const { datesData, IdEmp } = React.useContext(EmpContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);
    console.log('IdEmp', IdEmp, datesData)
    // REACT STATES
    const [value, setValue] = React.useState(searchParams.get('tab') || '1');
    const [scoreDetail, setScoreDetail] = React.useState({});
    const [scoreList, setScoreList] = React.useState([]);
    const [labelX, setLabelX] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [isGraphLoading, setIsGraphLoading] = React.useState(false);
    const [scoreCardId, setScoreCardId] = React.useState();
    const [myScoreData, setMyScoreData] = React.useState([]);
    const [mySectionData, setMySectionData] = React.useState([]);
    const [myScoreLoader, setMyScoreLoader] = React.useState(false);
    const [myScoreCount, setMyScoreCount] = React.useState(0);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);
    const [scorecardName, setScorecardName] = useState();
    const [scrDetailTableOrder, setScrDetailTableOrder] = useState(false);
    const [scrDetailShowIcon, setScrDetailShowIcon] = useState(false)
    const [secDetailTableOrder, setSecDetailTableOrder] = useState(false);
    const [secDetailShowIcon, setSecDetailShowIcon] = useState(false)



    // METHODS / FUNCTIONS

    const handleTableOrderScrDetails = () => {
        setScrDetailTableOrder(!scrDetailTableOrder);
        setMyScoreData(myScoreData.reverse())

    }
    const handleTableOrderSecDetails = () => {
        setSecDetailTableOrder(!secDetailTableOrder);
        setMySectionData(mySectionData.reverse())

    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
        const obj = { tab: newValue }

        if (searchParams.get('stff_emp_id')) {
            obj.stff_emp_id = searchParams.get('stff_emp_id');
            obj.global_dash = 'True'
        }

        setSearchParams(obj);
    };
    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    React.useEffect(() => {
        if (scoreCardId) {
            fetchMyScoreTableData(datesData?.date1, datesData?.date2, scoreCardId);
        }
    }, [scoreCardId])


    const fetchMyScoreTableData = async (date1, date2, id, query, pgNo) => {
        setMyScoreLoader(true);
        setMyScoreData([]);
        try {
            const { data } = await myscorelistScorePageApi(date1, date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, id, pgNo || 1, query || "", pgNo || 1);
            setMyScoreData(data?.results);
            setMyScoreCount(data?.count);
            setMyScoreLoader(false);
        } catch {
            setMyScoreData([]);
            setMyScoreCount(0);
            setMyScoreLoader(false);
        }
    }

    React.useEffect(() => {

        async function getList() {

            setScoreList([]);
            setIsDataLoading(true);
            loadingTimeOut();
            setValue(searchParams.get('tab') || '1');
            const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
            setScoreDetail({})
            const { data } = await empScoreListApi(searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
            setScoreList(data.data);
            if (data?.data?.length < Number(searchParams.get('tab'))) {
                setValue('1');
                setScoreCardId(data?.data[0]?.score_card);
                setScorecardName(data?.data[0]?.score_card__name);
                setIsDataLoading(false);
            }
            if (data?.data?.length >= Number(searchParams.get('tab'))) {
                setScoreCardId(data?.data[value - 1]?.score_card);
                setScorecardName(data?.data[value - 1]?.score_card__name);
                setIsDataLoading(false);
            }


            if (data?.data.length > 0 && data?.data?.length < Number(searchParams.get('tab'))) {

                const res = await empScoreDetailsApi(data?.data[0]?.score_card, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
                setScoreDetail(res.data)
                setMySectionData(res?.data?.section_details)

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
                }

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
                }
            }
            if (data?.data.length > 0 && data?.data?.length >= Number(searchParams.get('tab'))) {

                const res = await empScoreDetailsApi(data?.data[value - 1]?.score_card, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
                setScoreDetail(res.data)
                setMySectionData(res?.data?.section_details)

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
                }

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
                }
            }
        }
        if (datesData?.date1) {
            getList();
        }
    }, [datesData])


    const tabButtons = (propsBtn) => {
        // console.log(props.direction, props)
        if (propsBtn.direction === 'left' && !propsBtn.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:left-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />
            </Stack>)
        }
        if (propsBtn.direction === 'right' && !propsBtn.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:right-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />

                </Stack>
            )
        }
        return null

    }


    const option = {
        series: [{
            name: "Overall Score",
            data: scoreDetail?.score_graph_data?.length > 0 ? scoreDetail?.score_graph_data.map(obj => { if (!Number?.isNaN(((obj.total_score / (obj?.total_sum - obj?.na_sum)) * 100))) { return ((obj.total_score / (obj?.total_sum - obj?.na_sum)) * 100)?.toFixed(2) } return 0 }) : [0]
        }],
        options: {
            colors: [themeMode === 'light' ? setColor?.main : setColor?.dark],

            chart: {
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        // alert(labelX[config.dataPointIndex])
                        // alert(config.w.config.series[0].data[config.dataPointIndex]);
                        if (labelX[config.dataPointIndex].includes("Week")) {
                            navigate(`/employee/myscore?date1=${datesData?.date1}&date2=${datesData?.date2}&range=week&date_number=${labelX[config.dataPointIndex].slice(7, 9)}&staff=${searchParams.get("stff_emp_id") || loggedUserData?.staff?.id}&scorecard=${scoreCardId}`)
                        }
                        else {
                            navigate(`/employee/myscore?date1=${datesData?.date1}&date2=${datesData?.date2}&range=month&date_number=${moment().month(labelX[config.dataPointIndex]).format("M")}&staff=${searchParams.get("stff_emp_id") || loggedUserData?.staff?.id}&scorecard=${scoreCardId}`)


                        }
                        // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    }
                },
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: labelX?.length > 0 ? labelX : [0],
                labels: {
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 4,
                labels: {
                    formatter: (val) =>
                        val?.toFixed(2),
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },

                },
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '18px',
                    fontWeight: "bold",
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                }
            },
            title: {
                text: `${moment(datesData?.date1).format("LL")}-${moment(datesData?.date2).format("LL")}`,
                align: 'middle',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }, markers: {
                size: 6,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 0,
                discrete: [{
                    seriesIndex: 0,
                    dataPointIndex: 2,
                    fillColor: setColor?.main,
                    strokeColor: '#fff',
                    size: 5
                }],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0
            },


        },
    }


    async function getScoreDetails(id) {

        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
        setIsGraphLoading(true);
        const res = await empScoreDetailsApi(id, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2);
        setScoreDetail(res?.data)
        setMySectionData(res?.data?.section_details)
        setScoreCardId(id);
        setIsGraphLoading(false);
        if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
            setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
        }

        if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
            setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
        }
        // console.log(data)
    }


    // MY SCORE FUNCTIONS

    const sendQuery = (date1, date2, scorecardID, query, pgNo) => {
        // console.log(query, query?.length)
        // console.log(date1, date2)
        if (query?.length > 0) {

            fetchMyScoreTableData(date1, date2, scorecardID, query?.trim(), pgNo);
        }
        if (query?.length === 0) {

            fetchMyScoreTableData(date1, date2, scorecardID, query?.trim(), pgNo)
        }
    }

    const delayedQuery = useCallback(_.debounce((date1, date2, scorecardID, query, pgNo) => sendQuery(date1, date2, scorecardID, query, pgNo), 1000), []);

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
        delayedQuery(datesData?.date1, datesData?.date2, scoreCardId, event.target.value, 1);
        // getCalibrateData(event.target.value, 1);

    };


    const handleChangePage = (event, newPage) => {
        // getCalibrateData(filterName, newPage + 1);
        fetchMyScoreTableData(datesData?.data1, datesData?.data2, scoreCardId, filterName, newPage + 1);
        return setPage(newPage);

    };



    return (
        <Box sx={{ px: 1 }}>
            <Stack sx={{ p: 0, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0 }} direction='row' alignContent='center' alignItems='center' justifyContent='space-between'>
                <Box >

                    <Typography variant='h5' sx={{ p: 0, m: 0, fontSize: { lg: '20px', xl: '28px' }, color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }}>
                        Score
                    </Typography>
                    <HeaderBreadcrumbs
                        sx={{ pr: 3, mb: 1, color: '#666666', fontWeight: 600 }}
                        links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Scores', href: PATH_EMP.scores }]} />

                </Box>
                <EmpDatePicker />

            </Stack>
            {/* <HeaderBreadcrumbs
                sx={{ pl: 2, ml: 3, pt: 1, pr: 3 }}
                links={[{ name: 'Home', href: PATH_EMP.dashboard }, { name: 'Scores', href: PATH_EMP.scores }]}
            /> */}
            <Paper sx={{ width: "100%", pb: 2, borderRadius: '5px' }} elevation={5}>

                {scoreList?.length > 0 && <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ px: 5, pt: 1, pb: 2, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                    {/* <Typography variant="h4">Scores </Typography> */}
                    <Box>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignContent="center" alignItems="center" sx={{ mx: 1 }}>
                                {scoreDetail?.failures_count?.fail_all_count || 0} <Tooltip title="Fail-All"><Warning sx={{ mx: 1 }} /></Tooltip>
                            </Stack>
                            <Stack direction="row" alignContent="center" alignItems="center" sx={{ mx: 1 }}>
                                {scoreDetail?.failures_count?.fail_section_count || 0}  <Tooltip title="Fail"><Flag sx={{ mx: 1 }} /></Tooltip>
                            </Stack>
                            {/* <EmpDatePicker scoreTab={setValue} sx={{ mx: 1 }} /> */}
                        </Stack>

                    </Box>
                </Box>}
                {/* <Divider /> */}
                <Box sx={{ py: 1 }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                            <TabList TabIndicatorProps={{ style: { backgroundColor: themeMode === 'light' ? setColor?.main : setColor?.dark } }} ScrollButtonComponent={(props) => tabButtons(props)} variant="scrollable" sx={{ width: '100%', display: "flex", flexDirection: "row", px: 3 }} onChange={handleChange}>
                                {scoreList?.map((tab, index) => (
                                    <Tab key={index} label={tab.score_card__name} onClick={() => { setScorecardName(tab.score_card__name); getScoreDetails(tab.score_card) }} value={String(index + 1)} sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} />
                                ))}
                            </TabList>
                        </Box>
                        {/* <TabList TabIndicatorProps={{ style: { backgroundColor: themeMode === 'light' ? setColor?.main : setColor?.dark } }} variant="scrollable" sx={{ width: '100%', display: "flex", flexDirection: "row", mb: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} onChange={handleChange}>
                            {scoreList?.map((tab, index) => (
                                <Tab key={index} label={tab.score_card__name} onClick={() => { setScorecardName(tab.score_card__name); getScoreDetails(tab.score_card) }} value={String(index + 1)} />
                            ))}
                        </TabList> */}
                        <Box
                            sx={{
                                py: 1,
                                mt: 1,
                                height: 'auto',
                                width: '100%',
                                borderRadius: 1
                            }}
                        >
                            {scoreList?.length > 0 && scoreList?.map((panel, index) => (
                                <TabPanel sx={{ px: 4 }} key={index} value={String(index + 1)}>
                                    {Object?.keys(scoreDetail)?.length > 0 &&
                                        <>
                                            <Box>
                                                <Grid container sx={{ height: "450px", border: "1px solid #dcdde1", borderRadius: 1 }}>
                                                    <Grid item xs={12} sm={4} sx={{ p: 2 }}>
                                                        {!isGraphLoading && <>
                                                            <Stack sx={{ height: "40%", mb: 2 }} direction="row" justifyContent="Space-between">
                                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", border: '2px solid #E9EBF3', width: "45%", backgroundColor: "#EFF1F7", color: theme => theme.palette.action.selected, borderRadius: 1, p: 1, textAlign: "center" }}>
                                                                    <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} fontWeight="bold">Team Overall Score</Typography><br />
                                                                    <Group sx={{ fontSize: "35px" }} />
                                                                    <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                                                        {`${((scoreDetail?.team_overall_score?.total_sum !== 0) && Number(scoreDetail?.team_overall_score?.total_sum)) ? ((scoreDetail?.team_overall_score?.total_score / (scoreDetail?.team_overall_score?.total_sum - scoreDetail?.team_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                                                        {/* {`${((100 * scoreDetail?.team_overall_score?.score_sum || 0) / scoreDetail?.team_overall_score?.total_score || 0).toFixed(1)}%`} */}
                                                                    </Typography><br />
                                                                </Box>
                                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", color: (theme) => theme.palette.text.buttonText, border: '2px solid #FEEFE9', backgroundColor: "#FEF7F5", width: "45%", borderRadius: 1, p: 1, textAlign: "center" }}>
                                                                    <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} fontWeight="bold">My Overall Score</Typography><br />
                                                                    <ArrowDownward fontSize='large' sx={{ stroke: (theme) => theme.palette.text.buttonText, strokeWidth: 2 }} />
                                                                    <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                                                        {/* {`${(Math?.round((100 * scoreDetail?.staff_overall_score?.score_sum || 0) / scoreDetail?.staff_overall_score?.total_score || 0))}%`} */}
                                                                        {/* {`${((100 * scoreDetail?.staff_overall_score?.score_sum || 0) / scoreDetail?.staff_overall_score?.total_score || 0).toFixed(1)}%`} */}
                                                                        {`${(scoreDetail?.staff_overall_score?.total_sum !== 0 && Number(scoreDetail?.staff_overall_score?.total_sum)) ? ((scoreDetail?.staff_overall_score?.total_score / (scoreDetail?.staff_overall_score?.total_sum - scoreDetail?.staff_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}

                                                                    </Typography><br />
                                                                </Box>
                                                            </Stack>
                                                            <Stack sx={{ height: "40%" }} direction="row" justifyContent="Space-between">


                                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "100%", width: "45%", color: '#29abe2', border: '2px solid #E5F5FD', backgroundColor: "#ecf9ff", borderRadius: 1, p: 1, textAlign: "center" }}>
                                                                    {scoreDetail?.trend === 30 && <Tooltip title="Neutral Trend"><ArrowForward fontSize='large' sx={{ stroke: '#29abe2', strokeWidth: 3 }} /></Tooltip>}
                                                                    {scoreDetail?.trend === 10 && <Tooltip title="Positive Trend"><ArrowUpward fontSize='large' sx={{ stroke: '#29abe2', strokeWidth: 3 }} /></Tooltip>}
                                                                    {scoreDetail?.trend === 20 && <Tooltip title="Negative Trend"><ArrowDownward fontSize='large' sx={{ stroke: '#29abe2', strokeWidth: 3 }} /></Tooltip>}

                                                                </Box>

                                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: "100%", width: "45%", border: '1px solid #F2F2F2', backgroundColor: "#f8f8f8", color: '#202020', borderRadius: 1, p: 1, textAlign: "center" }}>

                                                                    <Typography sx={{ fontSize: "12px", textTransform: 'uppercase', color: '#95a5a6', fontWeight: 'light' }} variant="body2" fontWeight="bold">Kudos</Typography><br />
                                                                    <Star sx={{ fontSize: "35px", stroke: '#202020' }} />
                                                                    <Typography variant="h4" fontWeight="bold" sx={{ mt: 1 }}>
                                                                        {scoreDetail?.failures_count?.kudos_count}
                                                                    </Typography><br />
                                                                </Box>

                                                            </Stack>
                                                        </>}
                                                        {isGraphLoading && <Stack sx={{ width: '100%', height: "450px", }} direction="row" alignItems="center" justifyContent="center">
                                                            <CircularProgress /> &nbsp;
                                                            <Typography variant="body" sx={{ my: 1 }}>
                                                                Loading Data...
                                                            </Typography>
                                                        </Stack>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={8} sx={{ p: 2, height: "auto", width: "100%" }}>
                                                        <Box display="flex" justifyContent="center" className='chart-wrapper' sx={{ height: "88%", width: "100%", border: "1px solid #dcdde1", pt: 3 }}>
                                                            {!isGraphLoading && <Chart options={option.options} series={option.series} type="line" width={605} height={320} />}
                                                            {/* test */}
                                                            {isGraphLoading && <Stack sx={{ width: '100%' }} direction="row" alignItems="center" justifyContent="center">
                                                                <CircularProgress /> &nbsp;
                                                                <Typography variant="body" sx={{ my: 1 }}>
                                                                    Loading Data...
                                                                </Typography>
                                                            </Stack>}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>


                                        </>
                                    }

                                    {
                                        Object?.keys(scoreDetail)?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                            <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                                            <Typography variant="body" sx={{ my: 1 }}>
                                                No Scores Found For Given Date Range.
                                            </Typography>
                                        </Box>
                                    }
                                </TabPanel>
                            ))}
                            {scoreList?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                                {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                                    <CircularProgress /> &nbsp;
                                    <Typography variant="body" sx={{ my: 1 }}>
                                        Loading Data...
                                    </Typography>
                                </Stack> :
                                    <Typography variant="body" sx={{ my: 1 }}>
                                        No Scores Found For Given Date Range.
                                    </Typography>}
                            </Box>
                            }
                        </Box>
                    </TabContext>
                </Box>
            </Paper >
            <>
                <Typography variant='h6' sx={{ mt: 5, mb: 1, px: 2, color: theme => theme.palette.text.buttonText }}>{`Score Details (${scorecardName || ``})`}</Typography>
                <Box component={Paper} elevation={1} sx={{ border: theme => theme.palette.mode !== 'light' ? "1px solid #dcdde1" : 'transparent', mt: 1, mb: 2, borderRadius: '5px' }}>
                    <Stack sx={{ width: "100%", px: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                        <Box>
                            <SearchStyle
                                value={filterName}
                                onChange={handleFilterByName}
                                placeholder="Search..."
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </Box>


                        <Stack direction="row" alignItems="center" alignContent="center">

                            <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                            <TablePagination
                                sx={{ border: "1px solid transparent" }}
                                rowsPerPageOptions={[100]}
                                component="div"
                                count={myScoreCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            // onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Stack>
                    </Stack>
                    {/* <Divider /> */}
                    <Box>
                        <TableContainer component={Paper} elevation={0} sx={{ pt: 1, pb: 2, px: 0, borderRadius: '5px' }}>
                            <Table size='small' sx={{ width: '100%' }} aria-label='score_table'>
                                <TableHead>
                                    <TableRow sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            py: 2
                                        },
                                    }}>
                                        {myScoreHeader?.map((value, index) => <TableCell key={index} >
                                            <Box onMouseLeave={() => { if (value.name === 'Id') setScrDetailShowIcon(false) }} onMouseEnter={() => { if (value.name === 'Id') setScrDetailShowIcon(true) }} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }} >{value?.name}</Typography>&nbsp;
                                                {(scrDetailShowIcon && value.name === 'Id') && <Tooltip title='Sort'>
                                                    <IconButton onClick={() => handleTableOrderScrDetails()}>
                                                        {scrDetailTableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                                    </IconButton>
                                                </Tooltip>}
                                            </Box>
                                        </TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {myScoreData?.length > 0 && myScoreData?.map((row, index) =>
                                        <TableRow sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: (theme) => `1px solid ${(index !== myScoreData?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }} key={index}>
                                            <TableCell>{row?.id}</TableCell>
                                            <TableCell>{row?.groups}</TableCell>
                                            {/* <TableCell>{moment().format('YYYY-MM-DD')}</TableCell> */}
                                            <TableCell>{moment(row?.event_date).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell>{row?.category}</TableCell>
                                            <TableCell>{row?.evaluator}</TableCell>
                                            <TableCell>
                                                <Chip label={`${row?.percentage}%`} sx={{ backgroundColor: setButtonBg(row?.score_result), color: 'white' }} />
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <CheckBox />}
                                                    {!row?.is_score_reviewed && <RemoveCircleOutline />}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <Typography sx={{ fontSize: "14px" }}>{moment(row?.score_reviewed_on).format("LL")}</Typography>}
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    <Typography sx={{ fontSize: "14px" }}>{row?.score_reviewed_by}</Typography>
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">

                                                    <Button sx={{ mx: 1, backgroundColor: (theme) => theme.palette.background.paper, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper, color: '#fb5d2e' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }} variant="contained" onClick={() => { navigate(searchParams.get("stff_emp_id") ? `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True&sup_view=True` : `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True`) }} >View</Button>
                                                </Box>

                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {myScoreData?.length === 0 && (
                                        <TableRow

                                        >

                                            {myScoreLoader ? <TableCell colSpan={12}>
                                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ py: 2 }}>
                                                    <CircularProgress />&nbsp;Loading Data...
                                                </Box>
                                            </TableCell> : <TableCell colSpan={12}>
                                                <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2, fontSize: "14px" }}>
                                                    No Data Found
                                                </Typography>
                                            </TableCell>}

                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Box>
            </>
            <>
                <Typography variant='h6' sx={{ mt: 5, mb: 1, px: 2, color: theme => theme.palette.text.buttonText }}>{`Section Details (${scorecardName || ''})`}</Typography>
                <Box component={Paper} elevation={1} sx={{ border: theme => theme.palette.mode !== 'light' ? "1px solid #dcdde1" : 'transparent', my: 1, borderRadius: '5px' }}>
                    <TableContainer component={Paper} elevation={0} sx={{ pb: 2, pt: 1, px: 0, borderRadius: '5px' }}>
                        <Table size='small' sx={{ width: "100%" }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        py: 2
                                    },
                                }}>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setSecDetailShowIcon(true)} onMouseLeave={() => setSecDetailShowIcon(false)}>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Section
                                            </Typography>&nbsp;
                                            {secDetailShowIcon && <Tooltip title='Sort'>
                                                <IconButton onClick={() => handleTableOrderSecDetails()}>
                                                    {secDetailTableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                                </IconButton>
                                            </Tooltip>}
                                        </Box>

                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Pass
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Fail
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            N/A
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Pass Rate
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Fail Rate
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Comments
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                            Percentage
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mySectionData?.length > 0 && mySectionData?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: (theme) => `1px solid ${(index !== scoreDetail?.section_details?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }}
                                    >
                                        <TableCell>
                                            <Typography sx={{ fontWeight: "bold", fontSize: '14px' }}>
                                                {row?.section_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {row?.count}
                                        </TableCell>
                                        <TableCell>{row?.fail_count}</TableCell>
                                        <TableCell>{row?.na}</TableCell>
                                        <TableCell> - </TableCell>
                                        <TableCell> - </TableCell>
                                        <TableCell> - </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontWeight: "bold", color: "gray", fontSize: '14px' }}>
                                                {row?.max_val !== row?.na_total && <>{`${((row?.total / (row?.max_val - row?.na_total)) * 100).toFixed(2)}%`}</>}
                                                {row?.max_val === row?.na_total && <>N/A</>}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                        {scoreDetail?.section_details?.length === 0 && <Box sx={{ width: "90%", minHeight: "80px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                            {/* <List sx={{ fontSize: "60px" }} /><br /> */}
                            <Typography variant="body" sx={{ my: 1, fontWeight: "bold" }}>
                                No section details found for the data range selected.
                            </Typography>
                        </Box>}
                    </TableContainer>
                </Box>
            </>
        </Box>
    )
}
