import { useEffect, useState, useCallback, useContext } from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, tableCellClasses,
    Typography,
    Stack, Switch,
} from '@material-ui/core';
// import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
// COMPONENT IMPORTS
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import Scrollbar from '../../../../../components/Scrollbar';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
// REDUX ACTION IMPORTS
import { getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
// API IMPORTS
import { grpDraftListApi } from "../../../../../_apis_/authApi.js/empapi";
import { SettingsContext } from "../../../../../contexts/SettingsContext";




// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Id'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description'
    },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location'
    },

];

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function ScoreGroupTable() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // CONTEXT STATES
    const { setColor, themeMode } = useContext(SettingsContext);
    // REDUX STATES
    const dispatch = useDispatch();
    const group = useSelector(state => state.groups);
    // REACT STATES
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [draftInfo, setDraftInfo] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [tableOrder, setTableOrder] = useState(false);

    // get Groups
    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllGroups(1, true))
        if (!loggedUserData?.staff) {
            getDraftData();
        }
    }, [])


    // chck error connection

    const getDraftData = async () => {
        const { data } = await grpDraftListApi(1, 20);
        setDraftInfo(data?.results);
    }

    const handleTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        handleTimeOut();
    }, 60000);




    // updating DATA STATE

    useEffect(() => {

        if (group?.results !== undefined) {
            setIsLoading(false);
            setData(group?.results);
            setCount(group?.count)
        }
    }, [group])


    const handleChangePage = (event, newPage) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        if (filterName && filterName !== "") {
            dispatch(searchAllGroups(event.target.value, newPage + 1))
            return setPage(newPage);
        }
        dispatch(getAllGroups(newPage + 1, true));
        return setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const sendQuery = (queryText) => {
        dispatch(getAllGroups(1, true, queryText || ""))
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        setPage(0);
        setFilterName(event.target.value)
        delayedQuery(event.target.value)

    };

    const handleTableOrder = () => {
        setData(data.reverse())
        setTableOrder(!tableOrder)
    }


    return (
        <>

            <ScoreWrapper title="Select Groups" fnDraft={getDraftData} draftData={draftInfo} link={[{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: PATH_DASHBOARD.general.scoreGrp }]}>

                <Stack sx={{ width: "100%", pl: 3, borderBottom: theme => { return { lg: `1px solid ${theme.palette.text.primaryBolderBorder}`, xl: `2px solid ${theme.palette.text.primaryBolderBorder}` } } }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                    <SearchStyle
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search Groups..."
                        startAdornment={
                            <>
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                    />
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </Stack>
                <Divider />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                headLabel={TABLE_HEAD}
                                rowCount={data?.length}
                                tableOrder={tableOrder}
                                handleTableOrder={handleTableOrder}
                            />
                            <TableBody>
                                {data?.map((row, index) => {
                                    return (
                                        <TableRow

                                            key={row?.id}

                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography style={{ align: "left", fontSize: '14px', marginRight: '5px' }}>{row.id}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Link to={`${PATH_DASHBOARD.general.score.selectEmployee}?id=${row.id}`} style={{ textDecoration: "none" }}>
                                                        <Button disableRipple variant='text' sx={{ justifyContent: "flex-start", color: themeMode === 'light' ? setColor?.main : setColor?.dark, p: 0, m: 0, "&:hover": { bgcolor: "transparent" }, fontSize: "14px" }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }} noWrap>
                                                                {row.name}
                                                            </Typography>
                                                        </Button>
                                                    </Link>
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>{row.description}</Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>{row.location}</Box>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {data?.length === 0 && (
                                    <TableRow>

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Groups...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Groups Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                    }}
                >
                    <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
                    <Typography variant="body2" sx={{ fontWeight: "bold", color: "#576574" }}>* Only groups with scorecard is listed.</Typography>

                </Box>
            </ScoreWrapper>
        </>
    );
}
