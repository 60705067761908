// REACT ROUTER STATES
import { Link as RouterLink, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
// NPM IMPORTS
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useRef, useState, useContext, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import questionMarkCircleOutline from '@iconify/icons-eva/question-mark-circle-outline';
// MATERIAL IMPORTS
import { Avatar, Badge, Box, Button, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
// COMPONENT IMPORTS
import { PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
import MenuPopover from '../../components/MenuPopover';
// HOOK IMPORTS
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useLocalStorage from '../../hooks/useLocalStorage';
// CONTEXT IMPORTS
import { AuthContext } from "../../contexts/JWTContext"
import ScoreCardsContext from '../../contexts/ScoreCardsContext'
import useSettings from '../../hooks/useSettings';

// -------------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.general.dashboard
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_PAGE.userProfile
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_PAGE.companySettings
  }

];

// ----------------------------------------------------------------------

export default function AccountPopover({ messageCount }) {
  // LOCAL STORAGE
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  // REACT ROUTER STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // SNACKBAR STATES
  const { enqueueSnackbar } = useSnackbar();
  // REACT REF STATES
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  // REACT STATES
  const [open, setOpen] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  // CONTEXT STATES
  const { themeMode, onChangeMode } = useSettings();
  const { profilePicture, setProfilePicture } = useContext(AuthContext);
  const { setselectedScoreFilters } = useContext(ScoreCardsContext);
  // HOOK STATES
  const { user, logout } = useAuth();
  const [settings, setSettings] = useLocalStorage('settings')

  // METHODS / FUNCTIONS
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      onChangeMode({ target: { value: 'light' } });
      localStorage.removeItem('lastPath');
      localStorage.clear();
      sessionStorage.clear();
      setselectedScoreFilters({});
      navigate('/auth/login');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      // console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  // USE EFFECT METHODS
  useEffect(() => {
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    // console.log('error')
    setProfilePictureUrl(userData?.profilePicture);
  }, [profilePicture])

  const errorHandler = () => {
    // alert("error");
    setProfilePictureUrl(undefined);
  }

  return (
    <>
      {/* <Button ref={anchorRef}
        onClick={handleOpen} sx={{ position: 'absolute' }}>
        click
      </Button> */}
      {/* {((loggedUserData?.staff?.staffType !== 10 && loggedUserData?.staff) || searchParams.get('stff_sup_id')) && <Button
        ref={anchorRef}
        onClick={handleOpen}

      >
        {loggedUserData?.firstName}&nbsp; <KeyboardArrowDown />
      </Button>} */}
      <Box sx={{ display: 'flex', alignItems: 'center', m: 0, p: 0 }}>
        {messageCount > 0 && <Badge badgeContent=' ' overlap='circular' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot" color='success'>
          <Tooltip title={`${loggedUserData?.firstName} ${loggedUserData?.lastName}`}>
            <Avatar imgProps={{
              onError: errorHandler,
            }} sx={{ cursor: 'pointer' }} onClick={() => navigate(PATH_PAGE.userProfile)} alt='smaple-image' src={profilePictureUrl} />

          </Tooltip>
        </Badge>}
        {(messageCount === 0 || !messageCount) &&
          <Tooltip title={`${loggedUserData?.firstName} ${loggedUserData?.lastName}`}>
            <Avatar imgProps={{
              onError: errorHandler
            }} sx={{ cursor: 'pointer' }} onClick={() => navigate(PATH_PAGE.userProfile)} alt='smaple-image' src={profilePictureUrl} />
          </Tooltip>
        }

        <Box sx={{ ml: 1, cursor: 'default' }}>
          <Typography variant='body2' sx={{ color: theme => theme.palette.text.primary, fontSize: { lg: '14px', xl: '16px' } }}>{loggedUserData?.firstName?.split("")[0]?.toUpperCase()}{loggedUserData?.firstName?.slice(1)}&nbsp;{loggedUserData?.lastName?.split("")[0]?.toUpperCase()}.</Typography>
          <Typography variant='body2' sx={{ fontSize: { lg: '12px', xl: '14px' }, color: theme => theme.palette.text.primary }} >{loggedUserData?.username}</Typography>
        </Box>
        <IconButton size='small' disableRipple sx={{ backgroundColor: 'transparent', '&:hover': { backgroundColor: 'transparent' } }} ref={anchorRef} onClick={handleOpen}>
          <KeyboardArrowDown fontSize='small' />
        </IconButton>
      </Box>


      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 170 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {/* {loggedUserData.firstName} */}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {user.email} */}
          </Typography>
        </Box>
        {MENU_OPTIONS.map((option) => (<MenuItem
          key={option.label}
          to={option.label === 'Home' ? PATH_DASHBOARD.general.dashboard : option.linkTo}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5, fontSize: "14px" }}
        >
          <Box
            component={Icon}
            icon={option.icon}
            sx={{
              mr: 2,
              width: 20,
              height: 20
            }}
          />

          {option.label}
        </MenuItem>)
        )}

        {((loggedUserData?.supportAccess && (!loggedUserData?.staff || ((searchParams.get('stff_sup_id') || pathname.split('/')[1] === 'employee') && !loggedUserData?.staff))) || (loggedUserData?.staff && (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30))) && <MenuItem

          to={PATH_PAGE?.support}
          // to='/dashboard'
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5, fontSize: "14px" }}
        >
          <Box
            component={Icon}
            icon={questionMarkCircleOutline}
            sx={{
              mr: 2,
              width: 20,
              height: 20
            }}
          />

          Support
        </MenuItem>}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" sx={{ fontSize: "12px" }} onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover >
    </>
  );
}