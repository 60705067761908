import axios from "axios";
import humps from 'humps';
import { apiCallBegan } from "../api";
// Base Url
import { URL } from "../../backend";

const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        if (action.type !== apiCallBegan.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError, returnPromise } =
          action.payload;

        if (onStart) dispatch({ type: onStart });

        try {
          const accessToken = window.localStorage.getItem('accessToken');
          const config = {
            headers: {
              Authorization: `Token ${accessToken}`
            }
          };
          const response = await axios.request({
            baseURL: URL,
            url,
            method,
            data: humps.decamelizeKeys(data),
            ...config
          });

          dispatch({ type: onSuccess, payload: humps.camelizeKeys(response.data) });
          if (returnPromise) return Promise.resolve(response.data);
        } catch (error) {
          console.log(error);
          // if (onError)
          //   dispatch({ type: onError, payload: { error: error.message } });
          // dispatch({ type: "SHOW_ERROR", payload: { error: error.message } });
          dispatch({ type: onError, payload: { error } });
          if (returnPromise) return Promise.reject(error);
        }
      };

export default api;
