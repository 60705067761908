import PropTypes from 'prop-types';
import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Link, Stack, Button, Drawer, IconButton, Tooltip, Typography, CardActionArea, List, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@material-ui/core';
import { Assignment, Close, AccountTree, Visibility, DashboardOutlined, MessageOutlined, GroupsOutlined, RateReviewOutlined, EventNoteOutlined, CenterFocusStrongOutlined, AssessmentOutlined, PersonOutlineOutlined, RequestQuoteOutlined, TrendingUpOutlined, SettingsOutlined, Logout, ArrowBackIos, ArrowForwardIos, FlareOutlined, Call, AutoGraph } from '@material-ui/icons';

// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// context 
import { SettingsContext } from '../../contexts/SettingsContext'
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import SvgIconStyle from '../../components/SvgIconStyle';
//
// import sidebarConfig from './SidebarConfig';
import { DocIllustration } from '../../assets';

// ----------------------------------------------------------------------
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
const userLevel = loggedUserData?.userLevel?.name
// console.log(userLevel);

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 220;
const COLLAPSE_WIDTH = 60;

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? 'transparent' : '#636e72',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ------------for lists----------------------------------------------
const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 35,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),

  // color: theme.palette.text.secondary,
  color: theme.palette.text.sidebarPrimaryText,
  '&:hover': { color: theme.palette.text.sidebarSecondaryText, fontWeight: 'fontWeightMedium' },
  '&:before': {
    top: 0,
    left: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.text.sidebarPrimaryText
  }
}));


const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});






// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title={`${collapseClick ? 'Expand' : 'Collapse'} Menu`}>
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 16,
          height: 16,
          display: 'flex',
          cursor: 'pointer',
          // borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          // border: 'solid 1px currentColor',
          // border: 'solid 1px white',

          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        {!collapseClick && <ArrowBackIos fontSize='small' sx={{ color: 'white' }} />}
        {collapseClick && <ArrowForwardIos fontSize='small' sx={{ color: 'white' }} />}
        {/* <Box
          sx={{
            width: 8,
            height: 8,
            // borderRadius: '50%',
            // bgcolor: 'currentColor',
            bgcolor: (theme) => theme.palette.text.sidebarPrimaryText,
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        /> */}
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {

  const pathnameIndexTwo = [
    'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore'
  ]

  const activeRootStyle = {
    color: (theme) => theme.palette.text.sidebarPrimaryText,
    fontWeight: 'fontWeightMedium',
    bgColor: 'transparent',
    '&:hover': { color: (theme) => theme.palette.text.sidebarSecondaryText },
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };




  const [isShowIcon, setShowIcon] = useState(false);
  const { pathname } = useLocation();
  const { user, logout } = useAuth();
  // console.log(pathname)


  // CONTEXT STATES
  const { setColor } = useContext(SettingsContext);
  const navigate = useNavigate();

  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const userLevel = loggedUserData?.userLevel?.name
  const isAiAccess = loggedUserData?.isAiAccess


  useEffect(() => {
    setShowIcon(false);
  }, [pathname])


  // LOGOUT METHOD
  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('lastPath');
      localStorage.clear();
      navigate('/auth/login');
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(userLevel);

  const getIcon = (name) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
  );

  const getIcon1 = (name) => (
    <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
  );

  const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking')
  };

  // new icon 

  const ICONS1 = {
    shield: getIcon1('shield')
  }


  const sidebarConfig = [];
  if (userLevel) {

    switch (userLevel) {
      case "Global Admin":
        // console.log("Global Admin");

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              // { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: <DashboardOutlined size='small' />,
                items: [
                  {
                    title: 'Global',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },
              { title: 'Billing', path: PATH_DASHBOARD.general.billing, icon: <RequestQuoteOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  // { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              // { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: <DashboardOutlined size='small' />,
                items: [
                  {
                    title: 'Global',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },
              { title: 'Billing', path: PATH_DASHBOARD.general.billing, icon: <RequestQuoteOutlined /> },

            ]
          })
        }


        break;

      case "Calibrate Only":
        // console.log("Global Admin");
        if (isAiAccess) {
          sidebarConfig.push({
            items: [

              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [

              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
            ]
          })
        }

        // ];

        break;
      case "Group Admin":

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: ICONS.dashboard,
                items: [
                  {
                    title: 'Dashboard',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },


                ]
              },

            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: ICONS.dashboard,
                items: [
                  {
                    title: 'Dashboard',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },

            ]
          })
        }

        break;
      case "Supervisor":

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
            ]
          })
        }

        break;
      case "Employee":

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
            ]
          })
        }

        break;
      case 'Advanced Supervisor':
        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
            ]
          })
        }

        break;
      case "Team Admin":
        // console.log("Team admin");

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: ICONS.dashboard,
                items: [
                  {
                    title: 'Dashboard',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
              // { title: 'Scorecards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              // { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon:<PersonOutlineOutlined/> }
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: ICONS.dashboard,
                items: [
                  {
                    title: 'Dashboard',
                    path: PATH_DASHBOARD.general.dashboard,
                    icon: ICONS.dashboard,
                  },
                  {
                    title: 'Evaluator',
                    path: PATH_DASHBOARD.general.evaluatorDashboard,
                    icon: ICONS.dashboard,
                  },
                ]
              },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> }
              // { title: 'Scorecards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              // { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon:<PersonOutlineOutlined/> }
            ]
          })
        }

        break;

      case "Dashboard Only":
        // console.log("Team admin");

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })
        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
            ]
          })
        }

        break;
      case "Internal":
        // console.log("Internal");

        if (isAiAccess) {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]


          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small' /> },
              { title: 'Score Cards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon: <PersonOutlineOutlined /> },
            ]


          })
        }

        break;
      case "Score, Reports & Analytics":
        // console.log("Score, Reports & Analytics");


        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.evaluatorDashboard, icon: ICONS.dashboard },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Dashboard', path: PATH_DASHBOARD.general.evaluatorDashboard, icon: ICONS.dashboard },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> }
            ]
          })
        }

        break;
      case "Score, Calibrate, Report & Analytics":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              // { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              // { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> }
              // { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small'/> },
              // { title: 'Scorecards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
              // { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon:<PersonOutlineOutlined/> }
            ]
          })
        }

        break;
      case "Reports & Analytics":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: <TrendingUpOutlined /> }
            ]
          })
        }

        break;
      case "Reports Only":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> }
            ]
          })
        }

        break;
      case "Score Only":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> }
            ]
          })
        }

        break;
      case "Score & Calibrate":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Calibration', path: PATH_DASHBOARD.general.calibration.index, icon: <CenterFocusStrongOutlined /> },

            ]
          })
        }

        break;
      case "Score & Reports":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon: <RateReviewOutlined size='small' /> },
              { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon: <AssessmentOutlined /> }

            ]
          })
        }

        break;
      case "Billing Only":

        if (isAiAccess) {

          sidebarConfig.push({
            items: [
              { title: 'Billing', path: PATH_DASHBOARD.general.billing, icon: <RequestQuoteOutlined /> },
              {
                title: 'Speech AI Hub',
                path: PATH_DASHBOARD.beyondqaAi.events,
                icon: <MessageOutlined size='small' />,
                items: [
                  { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
                  { title: 'Calls', path: '/calls', icon: <Call /> },
                  { title: 'Analyze', path: '/analyze?tab=one', icon: <Visibility /> },
                  { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
                  { title: 'Coaching', path: '/coaching?csId=CD', icon: <AutoGraph /> },
                  { title: 'Assignment', path: '/assignment', icon: <Assignment /> },

                ]
              },
            ]
          })

        } else {
          sidebarConfig.push({
            items: [
              { title: 'Billing', path: PATH_DASHBOARD.general.billing, icon: <RequestQuoteOutlined /> },
            ]
          })
        }

        break;
      default:

        sidebarConfig.push({
          items: [
            // { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
            // { title: 'Score', path: PATH_DASHBOARD.general.score.index, icon:<RateReviewOutlined size='small'/> },
            // { title: 'Staff', path: PATH_DASHBOARD.general.Staff, icon: <GroupsOutlined size='small'/> },
            // { title: 'Scorecards', path: PATH_DASHBOARD.general.Scorecards, icon: <EventNoteOutlined /> },
            // { title: 'Admin', path: PATH_DASHBOARD.general.admin, icon:<PersonOutlineOutlined/> },
            // { title: 'Reports', path: PATH_DASHBOARD.general.reports, icon:<AssessmentOutlined/> }
          ]
        });

        break;
    }
  }


  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
    // sx={{
    //   height: 1,
    //   '& .simplebar-content': {
    //     height: 1,
    //     display: 'flex',
    //     flexDirection: 'column'
    //   }
    // }}
    >
      <Stack
        spacing={2}
        sx={{
          px: 2.5,
          pt: { xs: 1, lg: 3 },
          pb: { xs: 1, lg: 2 },

          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/dashboard" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>
          <MHidden width="lgUp">
            <IconButton onClick={onCloseSidebar}  >
              <Close sx={{ color: 'white' }} />
            </IconButton>
          </MHidden>


          {isShowIcon && <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>}
        </Stack>

      </Stack>

      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  const getLgWidth = (wdth) => {
    if (!pathnameIndexTwo?.includes(pathname?.split('/')[2])) {
      return isCollapse ? COLLAPSE_WIDTH : wdth
    }
    return 0
  }

  return (
    <RootStyle
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      sx={{
        width: {
          xl: getLgWidth(DRAWER_WIDTH_XL),
          // lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH_LG,
          lg: getLgWidth(DRAWER_WIDTH_LG),
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          sx={
            {
              flexShrink: 0, width: '25%', '& .MuiDrawer-paper': {
                width: '25%', backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
              }, backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
            }

          }
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {!pathnameIndexTwo?.includes(pathname?.split('/')[2]) && <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: { xl: DRAWER_WIDTH_XL, lg: DRAWER_WIDTH_LG },
              borderTopRightRadius: { lg: '28px', xl: '50px' },
              borderBottomRightRadius: { lg: '25px', xl: '40px' },
              // bgcolor: 'background.default',
              backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                // bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,


              })
            }
          }}
        >
          {renderContent}
          {/* <Box sx={{ position: 'relative', bottom: '10%' }}>
            <List disablePadding>
              <ListItemStyle onClick={() => navigate('/companySettings')} sx={{
                ...(pathname === "/companySettings" && activeRootStyle)
              }}>
                <ListItemIconStyle><SettingsOutlined /></ListItemIconStyle>
                {!isCollapse && <>
                  <ListItemText sx={{ color: 'inherit', '&:hover': { color: 'inherit', fontWeight: 'fontWeightMedium' } }} disableTypography primary={`Settings ${pathname.split("/")[0]}`} />
                </>}
              </ListItemStyle>
              <ListItemStyle onClick={() => handleLogout()}>
                <ListItemIconStyle><Logout /></ListItemIconStyle>
                {!isCollapse && <>
                  <ListItemText sx={{ color: 'inherit', '&:hover': { color: 'inherit', fontWeight: 'fontWeightMedium' } }} disableTypography primary='Logout' />
                </>}
              </ListItemStyle>
            </List>

          </Box> */}
        </Drawer>
      </MHidden>}
    </RootStyle>
  );
}
