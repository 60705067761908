import { useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import MainFooter from '../../main/MainFooter';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
//
import SupervisorDashboardNavbar from './sup_navbar';
import HelpSupportPopUpForm from "../../../pages/dashboard/help_support/help_support_pop_up_form";



// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP - 12,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function SupervisorDashboardLayout() {
    const ref = useRef(null);
    const theme = useTheme();
    const { collapseClick } = useCollapseDrawer();
    const [open, setOpen] = useState(false);

    return (
        <>
            <RootStyle>
                <SupervisorDashboardNavbar onOpenSidebar={() => setOpen(true)} />

                <MainStyle
                    sx={{
                        transition: theme.transitions.create('margin', {
                            duration: theme.transitions.duration.complex
                        }),
                        // ...(collapseClick && {
                        //     ml: '102px'
                        // })
                    }}
                >
                    <Outlet />
                </MainStyle>
                <HelpSupportPopUpForm reference={ref} />
            </RootStyle>
            <MainFooter />
        </>
    );

}
