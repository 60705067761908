// DisableWrapper.js
import React from 'react';

const DisableWrapper = ({ children, exclude = [] }) => {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

  const recursivelyDisable = (element) => {
    if (React.isValidElement(element)) {
      const isExcluded = exclude.some(
        (exclusion) => element.props.id === exclusion || element.props.className?.split(' ').includes(exclusion)
      );

      return React.cloneElement(element, {
        ...element.props,
        disabled: isExcluded ? element.props.disabled : loggedUserData?.readOnly,
        children: React.Children.map(element.props.children, recursivelyDisable),
      });
    }
    return element;
  };

  return <>{React.Children.map(children, recursivelyDisable)}</>;
};

export default DisableWrapper;
