import { useEffect, useState, useCallback, useContext } from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTE IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Stack, Switch,
    Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, tableCellClasses, Typography, Toolbar,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// COMPONENT IMPORTS
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
import Scrollbar from '../../../../../components/Scrollbar';
// API IMPORTS
import { getGdashboardGroupApi } from "../../../../../_apis_/authApi.js/globalDashboardApi";
import { grpDraftListApi } from "../../../../../_apis_/authApi.js/empapi";
// CONTEXT IMPORTS
import { StaffContext } from "../../../../../contexts/staffContext";
import { SettingsContext } from "../../../../../contexts/SettingsContext";
// REDUX ACTION IMPORTS
import { getAllStaff } from '../../../../../redux/action/staffAction';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Id'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Employee'
    },
    {
        id: 'external_id',
        numeric: false,
        disablePadding: false,
        label: 'Ext-ID'
    },
    {
        id: 'groups',
        numeric: false,
        disablePadding: false,
        label: 'Groups'
    },
    {
        id: 'teams',
        numeric: false,
        disablePadding: false,
        label: 'Teams'
    },
    {
        id: 'supervisor',
        numeric: false,
        disablePadding: false,
        label: 'Supervisor'
    },
];


const RootStyle = styled(Toolbar)(({ theme }) => ({
    // height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

// ----------------------------------------------------------------------


export default function SelectEmployeeTable() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    // REDUX STATES
    const dispatch = useDispatch();
    const employee = useSelector(state => state.staff);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // CONTEXT STATES
    const { dateObj, setGroupList } = useContext(StaffContext);
    const { setColor, themeMode } = useContext(SettingsContext);
    // REACT STATE
    const [draftInfo, setDraftInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [tableOrder, setTableOrder] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllStaff(searchParams.get("id"), 1))
        refreshDashboard();
        if (loggedUserData?.staff?.staffType) {
            getDraftData();
        }
    }, [])

    const handleTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    const getDraftData = async () => {
        const { data } = await grpDraftListApi(1, 20);
        setDraftInfo(data?.results);
    }

    setTimeout(() => {
        handleTimeOut();
    }, 60000);

    const refreshDashboard = () => {
        async function getList() {
            if (dateObj) {
                const { data } = await getGdashboardGroupApi(dateObj?.date1, dateObj?.date2)
                // console.log(data?.data)
                setGroupList(data?.data);
            }

        }
        getList()
    }

    useEffect(() => {
        if (employee?.results !== undefined) {
            setIsLoading(false);
            setData(employee?.results?.map(obj => ({ id: obj.id, access: obj.is_agent_access, username: obj.user.email, name: `${obj?.user?.first_name} ${obj?.user?.last_name}`, groups: obj.user.groups, external_id: obj.external_id, teams: obj.user.teams, user: { staff_type: obj?.staff_type, teams: obj?.user?.teams?.map(item => ({ id: item?.id, name: item?.name })), name: `${obj?.user?.first_name} ${obj?.user?.last_name}`, supervisor_id: obj?.supervisor?.id }, supervisor: (obj?.supervisor ? `${obj?.supervisor?.first_name} ${obj?.supervisor?.last_name}` : null) })));
            setCount(employee?.count)
        }
    }, [employee])



    const handleChangePage = (event, newPage) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        if (filterName && filterName !== "") {
            dispatch(getAllStaff(searchParams.get("id"), newPage + 1, filterName));
            return setPage(newPage);
        }
        dispatch(getAllStaff(searchParams.get("id"), newPage + 1));
        return setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return dispatch(getAllStaff(searchParams.get("id"), 1))
        }
        if (queryText?.length > 0) {
            return dispatch(getAllStaff(searchParams.get("id"), 1, queryText));
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setPage(0);
        setData([]);
        setCount(0)
        setIsLoading(true);
        setFilterName(event.target.value);
        delayedQuery(event.target.value);
    };


    const handleTableOrder = () => {
        setData(data.reverse())
        setTableOrder(!tableOrder)
    }


    return (
        <>
            <ScoreWrapper title="Select Employees" fnDraft={getDraftData} draftData={draftInfo} link={[{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? `${PATH_DASHBOARD.general.score.selectEmployee}?id=${grpArr?.join(",")}&sup_dash=${searchParams.get("sup_dash")}` : PATH_DASHBOARD.general.scoreGrp }, { name: 'Select Employees' }]}>
                <Stack sx={{ width: "100%", borderBottom: theme => { return { lg: `1px solid ${theme.palette.text.primaryBolderBorder}`, xl: `2px solid ${theme.palette.text.primaryBolderBorder}` } } }} direction="row" alignItems="center" justifyContent="space-between">
                    {/* <UserListToolbar numSelected={selected.length} empFilterName={filterName} onFilterName={handleFilterByName} placeholderName={filterName ? undefined : "Search Employee..."} /> */}
                    <RootStyle>
                        <SearchStyle
                            value={filterName}
                            onChange={handleFilterByName}
                            placeholder="search employee..."
                            startAdornment={
                                <>
                                    <InputAdornment position="start">
                                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                </>
                            }


                        />
                    </RootStyle>

                    <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                    </Stack>
                </Stack>

                <Divider />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                rowCount={data?.length}
                                tableOrder={tableOrder}
                                handleTableOrder={handleTableOrder}
                            />
                            <TableBody>
                                {data?.map((row, index) =>

                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }}
                                    >

                                        <TableCell component="th" scope="row" padding="normal">

                                            <Box sx={{ ml: 2 }}>
                                                {/* {allUsers?.results?.length} */}
                                                <Typography sx={{ fontSize: '14px' }}>{row.id}</Typography>

                                            </Box >

                                        </TableCell >


                                        <TableCell component="th" scope="row" padding="normal" align="center">

                                            <Box sx={{ ml: 2 }}>
                                                {/* {allUsers?.results?.length} */}
                                                <Link style={{ textDecoration: "none" }} to={(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? `${PATH_DASHBOARD.general.score.selectScorecard}?grpId=${searchParams.get("id")}&empId=${row.id}&sup_dash=True&empTable=${JSON.stringify(row?.user)}` : `${PATH_DASHBOARD.general.score.selectScorecard}?grpId=${searchParams.get("id")}&empId=${row.id}&empTable=${JSON.stringify(row?.user)}`}>
                                                    <Button disableRipple variant='text' sx={{ justifyContent: "flex-start", color: themeMode === 'light' ? setColor?.main : setColor?.dark, p: 0, m: 0, "&:hover": { bgcolor: "transparent" }, fontSize: "14px" }}>
                                                        <Typography noWrap sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {row.name}
                                                        </Typography>
                                                    </Button>

                                                </Link>

                                            </Box >

                                        </TableCell >
                                        <TableCell align='center' scope="row" padding="none">
                                            <div style={{ width: "90px" }}> <h5>{row.external_id || ""}</h5></div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ minWidth: "150px" }}>
                                                {row.groups === null ? '' : row.groups.map((data, index) => <Button key={index} sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400', marginRight: '5px', marginBottom: '2px' }} variant="outlined" color="inherit"> {data.name} </Button>)}
                                            </Box></TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ minWidth: "150px" }}>
                                                {row.teams === null ? '' : row.teams.map((data, index) => <Button key={index} sx={{ padding: '0px 8px', mx: 1, fontSize: "12px", fontWeight: '400' }} variant="outlined" color="inherit"><span style={{ whiteSpace: 'nowrap' }}> {data.name} </span> </Button>)}
                                            </Box>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography noWrap sx={{ fontSize: '14px' }}>
                                                {row?.supervisor ? row.supervisor : " "}
                                            </Typography>
                                            {/* <div style={{ minWidth: "80px" }}></div> */}
                                        </TableCell>
                                    </TableRow>

                                )}

                                {data?.length === 0 && (
                                    <TableRow>

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Employees...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Employee Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                    }}
                >
                    <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
                </Box>
            </ScoreWrapper >
        </>
    );
}
