import React, { useEffect, useCallback, useState } from 'react';
// REACT ROUTER IMPORTS
import { useNavigate, useSearchParams } from "react-router-dom";
// MATERIAL UI IMPORT
import {
    Table, TableHead, TableContainer, TableRow, TableCell, TableBody, TablePagination, tableCellClasses,
    Box, Stack, Button, Typography, OutlinedInput, Divider, InputAdornment, IconButton, CircularProgress
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { debounce } from "lodash";
// MATERIAL STYLED
import { styled } from '@material-ui/core/styles';
// NPM IMPORTS
import moment from "moment";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import searchFill from '@iconify/icons-eva/search-fill';
// COMPONENT IMPORTS
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
import AnalyticsWrapper from "../../../analyticswrapper";
import { PATH_DASHBOARD } from "../../../../../routes/paths";
// Context IMPORTS
import { AnalyticContext } from "../../../../../contexts/AnalyticContext";
// UTILS IMPORTS
import { setButtonBg, setFilterByHeader } from "../../../../../utils/filterUtilities";
// API IMPORTS
import { getAcceptanceByTeamApi, getAcceptanceByResultApi, getAcceptanceByTeamResultApi, getAcceptanceByResultDownloadXLS } from "../../../../../_apis_/authApi.js/analytic_api";


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    margin: "12px 0",
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));



export default function AcceptanceResultTable() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // CONTEXT STATES
    const { selAnaSupervisorFilters, acceptanceByResultApiRes,
        downloadAcceptanceByResult, getAcceptanceByResult, loading } = React.useContext(AnalyticContext);
    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // STATES
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [filterName, setFilterName] = React.useState("");
    const [count, setCount] = React.useState(0);
    const [supGrp, setSupGrp] = React.useState(loggedUserData?.groups?.map(obj => obj.id));
    const [filterObj, setFilterObj] = useState('')
    const [tableOrder, setTableOrder] = useState(false);
    const [showIcon, setShowIcon] = useState(false);

    // USE-EFFECT METHODS
    useEffect(() => {

        if (searchParams.get("link") !== "Supervisor") {
            setIsLoading(true);
            setData([]);
            fetchAcceptanceByResultData(1);
        }
    }, []);

    // 2.4
    useEffect(() => {
        if (searchParams.get("link") === 'Supervisor') {
            const filterObj = {
                'fromDate': searchParams.get("date1") || '',
                'toDate': searchParams.get("date2") || '',
                'filterBy': searchParams.get("filter") || 'created_at',
                'teams': searchParams.get('teams') || selAnaSupervisorFilters?.teams || '',
                'scoreCard': searchParams.get("scorcard") || '',
                "scoreCardGroups": searchParams.get("group") || selAnaSupervisorFilters?.group || '',
                'eventType': selAnaSupervisorFilters?.eventType || '',
                "eventTypeEventSubType": selAnaSupervisorFilters?.eventTypeEventSubType || '',
                'supervisor': searchParams.get("supervisor") || '',
                "staff": selAnaSupervisorFilters?.staff || '',
                "scoreCardCategory": selAnaSupervisorFilters?.scoreCardCategory || '',
                'evaluator': selAnaSupervisorFilters?.evaluator || '',
                "highlightValue": selAnaSupervisorFilters?.highlightValue || '',
                'page': page + 1,
                'filterName': '',
                'flag': '',
                'section': selAnaSupervisorFilters?.section || '',
                "numericValues": selAnaSupervisorFilters?.numericValues || undefined,
                'formula': selAnaSupervisorFilters?.formula || '',
                'isScoreViewed': searchParams.get("viewed") || '',
                'isScoreReviewed': searchParams.get("reviewed") || '',
                'noAction': searchParams.get("noaction") || '',
                'isScoreAccepted': searchParams.get("accepted") || '',
                'isOneToOneReq': searchParams.get("one2one") || '',
                'dataTag': searchParams?.get('dataTag') || selAnaSupervisorFilters?.dataTag || '',
                'dataTagsOperator': searchParams?.get('dataTagsOperator') || selAnaSupervisorFilters?.anyOfTag || selAnaSupervisorFilters?.allOfTag || selAnaSupervisorFilters?.dataTagsOperator || '10',
                'search': filterName || ''
            };
            setFilterObj(filterObj)
            getAcceptanceByResult(filterObj);
        }

    }, [page]);

    useEffect(() => {
        if (searchParams.get("link") === 'Supervisor') {
            setData(acceptanceByResultApiRes?.results)
            setCount(acceptanceByResultApiRes?.count)
        }

    }, [acceptanceByResultApiRes])

    const fetchAcceptanceByResultData = async (pgNo, query) => {
        console.log(searchParams.get("isTeamResult"));

        setIsLoading(true);
        setData([]);
        try {
            if (searchParams.get("isTeamResult") === "False") {
                const { data } = await getAcceptanceByResultApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("filter"), searchParams.get("agent") || "", searchParams.get("scorcard") === 'all' ? '' : searchParams.get("scorcard"), searchParams.get("envnt") === 'all' ? '' : searchParams.get("envnt"), searchParams.get("subEvnt") === 'all' ? '' : searchParams.get("subEvnt"), searchParams.get("eval") === 'all' ? '' : searchParams.get("eval"), searchParams.get("scorecard_group") || "", (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), searchParams.get("viewed") || "", searchParams.get("accepted") || "", searchParams.get("one2one") || "", searchParams.get("reviewed") || "", searchParams.get("noaction") || "", pgNo, query || "", searchParams.get('dataTag'), searchParams.get('dataTagsOperator'));
                setData(data?.results);
                setCount(data?.count);
            } else {
                const { data } = await getAcceptanceByTeamResultApi(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("filter"), searchParams.get("agent") || "", searchParams.get("scorcard") === 'all' ? '' : searchParams.get("scorcard"), searchParams.get("envnt") === 'all' ? '' : searchParams.get("envnt"), searchParams.get("subEvnt") === 'all' ? '' : searchParams.get("subEvnt"), searchParams.get("eval") === 'all' ? '' : searchParams.get("eval"), searchParams.get("scorecard_group") || "", (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), searchParams.get("team") || "", searchParams.get("viewed") || "", searchParams.get("accepted") || "", searchParams.get("one2one") || "", searchParams.get("reviewed") || "", searchParams.get("noaction") || "", pgNo, query || "", searchParams.get('dataTag') || '', searchParams.get('dataTagsOperator') || '');
                setData(data?.results);
                setCount(data?.count);
            }
            setIsLoading(false);
        } catch (err) {
            setData([]);
            setCount([]);
            setIsLoading(false);
        }
    }

    const handleTableOrder = () => {
        setData(data.reverse());
        setTableOrder(!tableOrder)
    }

    // METHODS
    const handleChangePage = (event, newPage) => {
        if (searchParams.get("link") !== 'Supervisor') {
            fetchAcceptanceByResultData(newPage + 1, filterName);
        }
        setPage(newPage);
    }

    const handleSearchFun = (filterObject, searchVal) => {
        setFilterName(searchVal);
        const filterObj = {
            'fromDate': searchParams.get("date1") || '',
            'toDate': searchParams.get("date2") || '',
            'filterBy': searchParams.get("filter") || 'created_at',
            'teams': searchParams.get('teams') || selAnaSupervisorFilters?.teams || '',
            'scoreCard': searchParams.get("scorcard") || '',
            "scoreCardGroups": searchParams.get("group") || selAnaSupervisorFilters?.group || '',
            'eventType': selAnaSupervisorFilters?.eventType || '',
            "eventTypeEventSubType": selAnaSupervisorFilters?.eventTypeEventSubType || '',
            'supervisor': searchParams.get("supervisor") || '',
            "staff": selAnaSupervisorFilters?.staff || '',
            "scoreCardCategory": selAnaSupervisorFilters?.scoreCardCategory || '',
            'evaluator': selAnaSupervisorFilters?.evaluator || '',
            "highlightValue": selAnaSupervisorFilters?.highlightValue || '',
            'page': '1',
            'filterName': '',
            'flag': '',
            'section': selAnaSupervisorFilters?.section || '',
            "numericValues": selAnaSupervisorFilters?.numericValues || undefined,
            'formula': selAnaSupervisorFilters?.formula || '',
            'isScoreViewed': searchParams.get("viewed") || '',
            'isScoreReviewed': searchParams.get("reviewed") || '',
            'noAction': searchParams.get("noaction") || '',
            'isScoreAccepted': searchParams.get("accepted") || '',
            'isOneToOneReq': searchParams.get("one2one") || '',
            'dataTag': searchParams?.get('dataTag') || selAnaSupervisorFilters?.dataTag || '',
            'dataTagsOperator': searchParams?.get('dataTagsOperator') || selAnaSupervisorFilters?.anyOfTag || selAnaSupervisorFilters?.allOfTag || selAnaSupervisorFilters?.dataTagsOperator || '10',
            'search': searchVal?.trim() || ''
        };
        getAcceptanceByResult(filterObj);
        if (searchParams.get("link") !== 'Supervisor') {
            setPage(0);
            setIsLoading(true);
            setData([]);
            return fetchAcceptanceByResultData(1, searchVal?.trim());
        }
    }
    // Delay search by 600ms
    const delayedSearch = useCallback(
        debounce((filterObj, val) => handleSearchFun(filterObj, val), 600), []);

    const handleFilterByName = (e) => {
        delayedSearch(filterObj, e.target.value);
    }

    const handleDownloadAcceptanceByResult = async () => {
        if (searchParams.get("link") !== 'Supervisor') {
            try {
                const res = await getAcceptanceByResultDownloadXLS(searchParams.get("date1"), searchParams.get("date2"), searchParams.get("filter"), searchParams.get("agent") || "", searchParams.get("scorcard") === 'all' ? '' : searchParams.get("scorcard"), searchParams.get("envnt") === 'all' ? '' : searchParams.get("envnt"), searchParams.get("subEvnt") === 'all' ? '' : searchParams.get("subEvnt"), searchParams.get("eval") === 'all' ? '' : searchParams.get("eval"), searchParams.get("scorecard_group") || "", (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), searchParams.get("team") || "", searchParams.get("viewed") || "", searchParams.get("accepted") || "", searchParams.get("one2one") || "", searchParams.get("reviewed") || "", searchParams.get("noaction") || "", searchParams.get('dataTag') || '', searchParams.get('dataTagsOperator') || '10');
                const outputFilename = `Acceptance_by_result_.xlsx`;
                enqueueSnackbar('Downloading File...', {
                    variant: 'info',
                    autoHideDuration: 1500,
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                // If you want to download file automatically using link attributes.
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();

            } catch (err) {
                enqueueSnackbar(err?.message, {
                    variant: 'error',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                console.log(err.message);
            }
        }

        // 2.4
        if (searchParams.get("link") === 'Supervisor') {
            const filterObj = {
                'fromDate': searchParams.get("date1") || '',
                'toDate': searchParams.get("date2") || '',
                'filterBy': searchParams.get("filter") || 'created_at',
                'teams': selAnaSupervisorFilters?.teams || '',
                'scoreCard': searchParams.get("scorcard") || '',
                "scoreCardGroups": selAnaSupervisorFilters?.group || '',
                'eventType': selAnaSupervisorFilters?.eventType || '',
                "eventTypeEventSubType": selAnaSupervisorFilters?.eventTypeEventSubType || '',
                'supervisor': searchParams.get("supervisor") || '',
                "staff": selAnaSupervisorFilters?.staff || '',
                "scoreCardCategory": selAnaSupervisorFilters?.scoreCardCategory || '',
                'evaluator': selAnaSupervisorFilters?.evaluator || '',
                "highlightValue": selAnaSupervisorFilters?.highlightValue || '',
                'page': '1',
                'filterName': '',
                // "// "sup_grp"": (setSelAnaScoreCardFilters?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj" => obj.id) ": '',
                'flag': '',
                'section': selAnaSupervisorFilters?.section || '',
                "numericValues": selAnaSupervisorFilters?.numericValues || undefined,
                'formula': selAnaSupervisorFilters?.formula || '',
                'isScoreViewed': searchParams.get("viewed") || '',
                'isScoreReviewed': searchParams.get("reviewed") || '',
                'noAction': searchParams.get("noaction") || '',
                'isScoreAccepted': searchParams.get("accepted") || '',
                'isOneToOneReq': searchParams.get("one2one") || '',
                'dataTag': searchParams?.get('dataTag') || selAnaSupervisorFilters?.dataTag || '',
                'dataTagsOperator': searchParams?.get('dataTagsOperator') || selAnaSupervisorFilters?.anyOfTag || selAnaSupervisorFilters?.allOfTag || selAnaSupervisorFilters?.dataTagsOperator || '10'
            };
            downloadAcceptanceByResult(filterObj);
        }
    }


    const mbLink = [{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }]

    switch (searchParams.get("link")) {
        // case 'Groups':
        //     mbLink.push({ name: 'Analytics', href: "/analytics" },
        //         { name: "Group", href: "" },
        //         { name: "6.7 Result Acceptance By Group", href: "/analytics/group/acceptance_by_group" },
        //         { name: "Acceptance By Result" });
        //     break;

        case 'Teams':
            mbLink.push({ name: 'Analytics', href: "/analytics" },
                { name: "Result Acceptance By Team", href: `/analytics/acceptance_by_team?date1=${searchParams.get("date1")}&date2=${searchParams.get("date2")}&filter=${searchParams.get("filter")}&scorcard=${searchParams.get("scorcard")}&envnt=${searchParams.get("envnt")}&subEvnt=${searchParams.get("subEvnt")}&eval=${searchParams.get("eval")}&scorecard_group=${searchParams.get("scorecard_group")}&link=Groups` },
                { name: "Acceptance By Result" });
            break;

        case 'Agents':
            mbLink.push({ name: 'Analytics', href: "/analytics" },
                { name: "Staff", href: "" },
                { name: "1.11 Result Acceptance By Staff", href: `/analytics/staff/acceptance_by_agent?fromDate=${searchParams.get("date1")}&toDate=${searchParams.get("date2")}&filterBy=${searchParams.get("filter")}&scoreCard=${searchParams.get("scorcard")}&eventType=${searchParams.get("envnt")}&eventTypeEventSubType=${searchParams.get("subEvnt")}&teams=${searchParams.get("teams") || ''}&group=${searchParams.get("scorecard_group")}&scoreCardGroups=${searchParams.get("scorecard_group")}&staff=${searchParams.get('staff') || ''}&evaluator=${searchParams.get('eval') || ''}&section=${searchParams.get('section') || ''}&scoreCardSingle=${searchParams.get('scoreCardSingle') || ''}&dataTag=${searchParams.get('dataTag')}&dataTagsOperator=${searchParams.get('dataTagsOperator')}&search=${searchParams.get('search')}` },
                { name: "Acceptance By Result" });
            break;
        case 'Supervisor':
            mbLink.push({ name: 'Analytics', href: "/analytics" },
                { name: "Supervisors", href: `/analytics/supervisor/result_acceptence_by_supervisor?fromDate=${searchParams.get('date1')}&toDate=${searchParams.get('date2')}&dateRange=${searchParams.get('dateRange') || ''}&filterBy=${searchParams.get('filter')}&group=${searchParams.get("group")}&scoreCard=${searchParams.get('scorcard')}&eventType=${searchParams.get("envnt") || ""}&eventTypeEventSubType=${searchParams.get('subEvnt') || ""}&evaluator=${searchParams.get('eval')}&supervisor=${searchParams.get("supervisor")}` },
                { name: "2.4 Result Acceptance By Superviosr", href: `/analytics/supervisor/result_acceptence_by_supervisor?fromDate=${searchParams.get('date1')}&toDate=${searchParams.get('date2')}&filterBy=${searchParams.get('filter')}&group=${searchParams.get("group")}&scoreCard=${searchParams.get('scorcard')}&eventType=${searchParams.get("envnt") || ""}&eventTypeEventSubType=${searchParams.get('subEvnt') || ""}&evaluator=${searchParams.get('eval')}&supervisor=${searchParams.get("supervisor")}&teams=${searchParams.get('teams')}&dateRange=${searchParams.get('dateRange') || ''}&dataTag=${selAnaSupervisorFilters?.dataTags || searchParams.get('dataTag') || ''}&dataTagsOperator=${searchParams.get('dataTagsOperator') || '10'}` },
                { name: "Acceptance By Result" });
            break;
        default:
            mbLink.push({ name: 'Analytics', href: "/analytics" }, { name: "Acceptance By Result" });
            break;
    }

    const handleReviewPerformancePage = (row) => {
        navigate(`/reports/reviewPerformance?score_card_Id=${row?.scoreCardId}&score_Id=${row?.id}&review=True&stff_sup_id=${row?.staff_id}&sup_view=True&from_staff_review=True`)
        localStorage.setItem("tabLabel", JSON.stringify("Staff Review"))
        localStorage.setItem("tabState", JSON.stringify('6'))

        sessionStorage.setItem('fromDate', searchParams.get("date1") || '',);
        sessionStorage.setItem('toDate', searchParams.get("date2") || '');
        sessionStorage.setItem('filterBy', searchParams.get("filter") || 'created_at');
        sessionStorage.setItem('teams', searchParams.get('teams') || selAnaSupervisorFilters?.teams || '');
        sessionStorage.setItem('scoreCard', searchParams.get("scorcard") || '');
        sessionStorage.setItem("scoreCardGroups", selAnaSupervisorFilters?.group || '');
        sessionStorage.setItem('eventType', selAnaSupervisorFilters?.eventType || '');
        sessionStorage.setItem("eventTypeEventSubType", selAnaSupervisorFilters?.eventTypeEventSubType || '');
        sessionStorage.setItem('supervisor', searchParams.get('supervisor') === 'null' ? '' : searchParams.get('supervisor'));
        sessionStorage.setItem("staff", selAnaSupervisorFilters?.staff || '');
        sessionStorage.setItem("scoreCardCategory", selAnaSupervisorFilters?.scoreCardCategory || '');
        sessionStorage.setItem('evaluator', selAnaSupervisorFilters?.evaluator || '');
        sessionStorage.setItem('section', selAnaSupervisorFilters?.section || '');
        sessionStorage.setItem("numericValues", selAnaSupervisorFilters?.numericValues || undefined);
        sessionStorage.setItem('formula', selAnaSupervisorFilters?.formula || '');
        // sessionStorage.setItem('supervisor', (searchParams.get('supervisor') === 'null' ? '' : searchParams.get('supervisor')))
    }

    const handleDate = (row) => {
        let date = '';
        if (searchParams.get('filter') === 'created_at') {
            date = row?.createdAtDate
        }
        if (searchParams.get('filter') === 'event_date') {
            date = row?.eventDateAt
        }
        if (searchParams.get('filter') === 'updated_at') {
            date = row?.updatedAtDate
        }
        return date;

    }

    console.log('222', loading)
    console.log('data', data)

    return (
        <>
            <AnalyticsWrapper val="6" sbVal="6.12" subModule="Acceptance By Result" LabelName="" brdCrmbLink={mbLink} dwnldFn={handleDownloadAcceptanceByResult}>
                <Box >
                    <Stack sx={{ width: "100%", px: 2 }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                        <SearchStyle
                            // value={filterName}
                            onChange={handleFilterByName}
                            placeholder="Search..."
                            startAdornment={
                                <>
                                    <InputAdornment position="start">
                                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                </>
                            }
                        />

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                            <TablePagination
                                sx={{ border: "1px solid transparent" }}
                                rowsPerPageOptions={100}
                                component="div"
                                count={count || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Box>


                    </Stack>
                    <Divider />
                    <Box>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                                <Table size='medium' sx={{

                                    "& .MuiTableCell-root": {
                                        border: (theme) => `1px solid ${theme.palette.text.primaryBorder}`,

                                    },

                                    "& .MuiTableCell-root:first-of-type": {
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        boxShadow: 'none',
                                    },
                                    "& .MuiTableCell-root:last-of-type": {
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        boxShadow: 'none',
                                    },
                                }} >

                                    <TableHead >
                                        <TableRow sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                py: 1,
                                            },
                                        }}>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                <Stack sx={{ width: '100%' }} onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} flexDirection='row' justifyContent='center' alignItems='center'>
                                                    <>Id</>
                                                    {showIcon && <IconButton onClick={handleTableOrder}>
                                                        {!tableOrder ? <ArrowUpward fontSize='small' /> : <ArrowDownward fontSize='small' />}
                                                    </IconButton>}
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                {setFilterByHeader(searchParams.get('filter'))}
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Status Date
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Staff Name
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Supervisor
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Team
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Group
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Evaluator
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "90px" }} align="center">
                                                Score
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                Scorecard Name
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                <Typography noWrap sx={{ fontWeight: 'inherit', fontSize: 'inherit' }}>Last Edit Date</Typography>
                                            </TableCell>
                                        </TableRow>


                                    </TableHead>
                                    <TableBody>
                                        {/* 1.11 module */}
                                        {data?.length > 0 && searchParams.get("link") !== "Supervisor" && data?.map((row, index) => (<TableRow key={index} >
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                <Button onClick={() => { navigate(`/reports/reviewPerformance?score_card_Id=${row?.score_card?.id}&score_Id=${row?.id}&review=True&stff_sup_id=${row?.staff_id}&sup_view=True&from_staff_review=True`) }}>{row?.id}</Button>
                                            </TableCell>
                                            {searchParams.get('filter') === 'event_date' && <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.event_date !== null ? moment(row?.event_date).format('YYYY-MM-DD') : null}
                                                </Typography>
                                            </TableCell>}
                                            {searchParams.get('filter') === 'created_at' && <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.created_at !== null ? moment(row?.created_at).format('YYYY-MM-DD') : null}
                                                </Typography>
                                            </TableCell>}
                                            {searchParams.get('filter') === 'updated_at' && <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.updated_at !== null ? moment(row?.updated_at).format('YYYY-MM-DD') : null}
                                                </Typography>
                                            </TableCell>}
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {!row?.score_reviewed_on ? "" : moment(row?.score_reviewed_on).format('YYYY-MM-DD')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.staff}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.supervisor}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.teams?.name}

                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.teams?.groups?.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.evaluator}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "90px" }} align="center">
                                                <Button onClick={() => { navigate(`/reports/reviewPerformance?score_card_Id=${row?.score_card?.id}&score_Id=${row?.id}&review=True&stff_sup_id=${row?.staff_id}&sup_view=True&from_staff_review=True`) }} size="small" variant="contained" sx={{ backgroundColor: setButtonBg(row?.score_result), "&:hover": { backgroundColor: setButtonBg(row?.score_result) } }}>{row?.percentage}%</Button>

                                            </TableCell>
                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.score_card?.name}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                <Typography noWrap sx={{ fontSize: { xs: '14px', xl: '15px' } }}>
                                                    {row?.updated_at !== null ? moment(row?.updated_at).format('YYYY-MM-DD') : null}
                                                </Typography>
                                            </TableCell>

                                        </TableRow>))}

                                        {/* 2.4 section table result--- */}

                                        {(data?.length > 0 && searchParams.get("link") === "Supervisor") &&
                                            (data?.map((row, index) => (<TableRow key={index} >
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                    <Button sx={{ color: "#005fe3" }} onClick={() => handleReviewPerformancePage(row)}>{row?.id}</Button>
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center" padding='normal'>
                                                    {handleDate(row)}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.statusDate}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.staffName}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.supervisorName}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.teamsName}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.groupName}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.evaluatorName}
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "90px" }} align="center">
                                                    <Button onClick={() => handleReviewPerformancePage(row)} size="small" variant="contained" sx={{ backgroundColor: setButtonBg(row?.scoreResult), "&:hover": { backgroundColor: setButtonBg(row?.scoreResult) } }}>{row?.score}%</Button>
                                                </TableCell>
                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.scorecardName}
                                                </TableCell>

                                                <TableCell sx={{ px: 1, margin: "0 auto", minWidth: "100px" }} align="center">
                                                    {row?.lastEditDate}
                                                </TableCell>

                                            </TableRow>)))

                                        }
                                        {
                                            data?.length === 0 && searchParams.get("link") === "Supervisor" && (<TableRow>

                                                {loading === true ? <TableCell colSpan={12}>
                                                    <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                        <CircularProgress /> Loading Data...
                                                    </Box>
                                                </TableCell> : <TableCell colSpan={12}>
                                                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                                        No Data Found
                                                    </Typography>
                                                </TableCell>}

                                            </TableRow>)
                                        }
                                        {data?.length === 0 && searchParams.get("link") !== "Supervisor" && (
                                            <TableRow>

                                                {isLoading ? <TableCell colSpan={12}>
                                                    <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                        <CircularProgress /> Loading Data...
                                                    </Box>
                                                </TableCell> : <TableCell colSpan={12}>
                                                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                                        No Data Found
                                                    </Typography>
                                                </TableCell>}

                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Box>
                </Box>
            </AnalyticsWrapper>

        </>
    )
}