import {
    ScoreList
} from '../types/typeScore';

const initalData = {};


export const scoreReduceR = (state = initalData, action) => {
    switch (action.type) {
        case ScoreList: {
            const { results, count } = action.payload;
            state = { results, count };
            return state;
        }
        default: return state
    }

}