import React, { useEffect } from 'react';
// REACT ROUTER IMPORTS
import { useNavigate } from "react-router-dom";
// MATERIAL IMPORTS
import { Avatar, Box, Button, CircularProgress, Collapse, IconButton, Link, List, Paper, Stack, Tab, Table, tableCellClasses, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Warning, Flag, StarRounded, MilitaryTech, KeyboardArrowUp, KeyboardArrowDown, Groups, AnnouncementRounded, Edit } from '@material-ui/icons';
// OTHER NPM IMPORTS
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// OTHER COMPONENT IMPORTS
import EmpDialog from "../components-overview/material-ui/dialog/admin/staff/EmpDialog";
import { MIconButton } from '../../components/@material-extend';
// API IMPORTS
import { /* getGdashboardGroupApi, */ getGdashboardDetailApi, getEmployeeDetails, downloadGdashboardDetailApi } from '../../_apis_/authApi.js/globalDashboardApi';
// CONTEXT IMPORTS
import { StaffContext } from '../../contexts/staffContext';
import { SettingsContext } from '../../contexts/SettingsContext';



export default function GloabalDashboardBody() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    /* console.log(loggedUserData); */

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // REACT ROUTER STATES
    const navigate = useNavigate();

    // CONTEXT STATES
    const { strtDate, endDate, gId, setGpId, groupList, globalTeamId, filterby, dateObj, teamListsContext, setEmpForm, setEmployeeID } = React.useContext(StaffContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);

    // REACT STATES
    const [grpIds, setGrpIds] = React.useState([]);
    const [summary, setSummary] = React.useState();
    const [supervisorDetail, setSupervisorDetail] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(true);
    const [teamTab, setTeamTab] = React.useState();
    const [groupTab, setGroupTab] = React.useState();
    const [supervisorListLoader, setSupervisorListLoader] = React.useState(false);
    const [tabLoader, setTabLoader] = React.useState(false);
    const [empList, setEmpList] = React.useState([]);
    const [empListLoader, setEmpListLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [collapseId, setCollapseId] = React.useState();
    const [value, setValue] = React.useState('1');



    // METHODS/FUNCTIONS

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchEmpDetails = async (id) => {
        setEmpListLoader(true);
        const { data } = await getEmployeeDetails(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby, id);
        // console.log(data?.data, "tester")
        setEmpListLoader(false);
        setEmpList(data?.data);

    }

    const handleTabChange = async (id) => {
        setTabLoader(true);
        if (groupList?.length > 0 && loggedUserData?.userLevel?.name !== 'Team Admin') {
            setSupervisorListLoader(true);
            setSupervisorDetail([]);
            try {
                const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, id, globalTeamId || "", filterby)
                setSummary(data?.data?.summery)
                setSupervisorListLoader(false);
                setSupervisorDetail(data?.data?.supervisor)
                setGroupTab(id);
                setTabLoader(false);
                setTeamTab(globalTeamId || "");
            } catch (err) {
                setSummary()
                setSupervisorListLoader(false);
                setSupervisorDetail([])
                setTabLoader(false);
            }
        }

        if (loggedUserData?.userLevel?.name === 'Team Admin') {
            setSupervisorListLoader(true);
            setSupervisorDetail([]);
            try {
                const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, grpIds, id, filterby)
                setSummary(data?.data?.summery)
                setSupervisorListLoader(false);
                setSupervisorDetail(data?.data?.supervisor)
                setGroupTab(grpIds);
                setTeamTab(id);
                setTabLoader(false);
            } catch (err) {
                setSummary()
                setSupervisorListLoader(false);
                setSupervisorDetail([])
                setTabLoader(false);
            }
        }

    }

    const handleDownloadXLS = async () => {
        try {

            if (groupList?.length > 0 && loggedUserData?.userLevel?.name !== 'Team Admin') {
                const { data } = await downloadGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby)
                enqueueSnackbar('Downloading File...', {
                    variant: 'info',
                    autoHideDuration: 1500,
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                const outputFilename = `${loggedUserData?.userLevel?.name}_Dashboard_Details.xlsx`;
                // If you wanted to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }

            if (loggedUserData?.userLevel?.name === 'Team Admin') {

                const { data } = await downloadGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby)
                enqueueSnackbar('Downloading File...', {
                    variant: 'info',
                    autoHideDuration: 1500,
                });
                const outputFilename = `${loggedUserData?.userLevel?.name}_Dashboard_Details_.xlsx`;
                // If you wanted to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            console.log(err.message);
        }
    }

    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    const tabButtons = (props) => {
        // console.log(props.direction, props)
        if (props.direction === 'left' && !props.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => props.onClick()} icon="icon-park-solid:left-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />
            </Stack>)
        }
        if (props.direction === 'right' && !props.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => props.onClick()} icon="icon-park-solid:right-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />

                </Stack>
            )
        }
        return null
    }




    // USEEFFECT METHODS
    React.useEffect(() => {
        async function getDetail() {
            try {
                setIsDataLoading(true);
                if (loggedUserData?.userLevel?.name !== 'Team Admin' && dateObj?.date1 !== undefined && groupList?.length > 0) {
                    // if (groupList?.length > 0 && !globalTeamId) {
                    loadingTimeOut();
                    setSupervisorListLoader(true);
                    setSupervisorDetail([]);
                    const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupList[0]?.score_card__groups || groupList[0], globalTeamId || "", filterby)
                    setIsDataLoading(false);
                    setSummary(data?.data?.summery);
                    setValue('1');
                    setGroupTab(groupList[0]?.score_card__groups || groupList[0]);
                    if (globalTeamId !== null && globalTeamId !== undefined && globalTeamId !== "") {
                        setTeamTab(globalTeamId);
                    }
                    setSupervisorListLoader(false);
                    return setSupervisorDetail(data?.data?.supervisor)
                }
                if (groupList?.length === 0) {
                    setIsDataLoading(false);
                }
                // loadingTimeOut();
                // const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupList[0]?.score_card__groups, globalTeamId, filterby)
                // setSummary(data?.data?.summery)
                // setSupervisorDetail(data?.data?.supervisor);
                // setIsDataLoading(false);
                // setValue('1');
                // setGroupTab(groupList[0]?.score_card__groups);
                // setTeamTab(globalTeamId);
                // }
                // if (loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.length > 0 && dateObj?.date1 !== undefined) {
                //     const groupIds = []
                //     loggedUserData?.groups?.map(row => groupIds.push(row?.id));
                //     setGrpIds(groupIds);
                //     loadingTimeOut();
                //     const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupIds, teamListsContext[0]?.teams, filterby)
                //     setSummary(data?.data?.summery)
                //     setSupervisorDetail(data?.data?.supervisor);
                //     setIsDataLoading(false);
                //     setValue('1')
                //     setGroupTab(groupIds);
                //     setTeamTab(teamListsContext[0]?.teams);
                // }
            } catch (err) {
                setIsDataLoading(false);
            }
        }
        if (groupList?.length > 0) { getDetail() } else { setIsDataLoading(false) }
    }, [groupList])

    React.useEffect(() => {
        async function getDetail() {
            try {
                if (loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.length > 0 && dateObj?.date1 !== undefined) {
                    setIsDataLoading(true);
                    setSupervisorListLoader(true);
                    setSupervisorDetail([]);
                    const groupIds = []
                    loggedUserData?.groups?.map(row => groupIds.push(row?.id));
                    setGrpIds(groupIds);
                    loadingTimeOut();
                    const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupIds, teamListsContext[0]?.teams, filterby)
                    setSummary(data?.data?.summery)
                    setSupervisorListLoader(false);
                    setSupervisorDetail(data?.data?.supervisor);
                    setIsDataLoading(false);
                    setValue('1')
                    setGroupTab(groupIds);
                    setTeamTab(teamListsContext[0]?.teams);
                }
            } catch (err) {
                setIsDataLoading(false);
            }
        }
        getDetail()
    }, [teamListsContext])


    return (
        <>
            <Box sx={{ px: 0, py: 1, m: 0 }}>
                <TabContext value={value}>
                    <Box component={Paper} elevation={5} sx={{ display: groupList?.length !== 0 ? 'block' : 'none' }}>
                        <TabList TabIndicatorProps={{ style: { backgroundColor: themeMode === 'light' ? setColor?.main : setColor?.dark } }} ScrollButtonComponent={(props) => tabButtons(props)} variant='scrollable' sx={{ width: '100%', display: "flex", flexDirection: "row", px: 3, borderTopRightRadius: '5px', borderTopLeftRadius: '5px', borderBottom: (theme) => { return { lg: `2px solid ${theme.palette.text.primaryBolderBorder}`, xl: `3px solid ${theme.palette.text.PrimaryBolderBorder}` } }, backgroundColor: (theme) => theme.palette.background.paper }} onChange={handleChange}>
                            {loggedUserData?.userLevel?.name !== 'Team Admin' && groupList?.map((tab, index) => (
                                <Tab sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} key={index} label={tab?.score_card__groups__name} onClick={() => { handleTabChange(tab?.score_card__groups, index) }} value={String(index + 1)} />
                            ))}
                            {loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.map((tab, index) => (
                                <Tab sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} key={index} label={tab?.teams__name} onClick={() => { handleTabChange(tab?.teams, index) }} value={String(index + 1)} />
                            ))}


                        </TabList>

                        {!tabLoader && <Box
                            sx={{
                                height: 'auto',
                                width: '100%',
                                borderRadius: '5px',
                                backgroundColor: (theme) => theme.palette.background.paper
                            }}
                        >
                            {groupList?.length > 0 && groupList?.map((panel, index) => (
                                <TabPanel key={index} value={String(index + 1)}>
                                    <Box sx={{ py: 1, px: { lg: 1, xl: 2 }, height: { lg: '190px', xl: '250px' } }} display="flex" alignItems="center" justifyContent="space-around">
                                        {loggedUserData?.userLevel?.name !== 'Team Admin' && <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, width: '99%', mx: { lg: 2, xl: 3 }, px: 2, border: '2px solid #E9EBF3', height: { lg: '65%', xl: '65%' }, borderRadius: 2, backgroundColor: "#EFF1F7" }}>
                                            <Box>
                                                <Typography sx={{ mt: 1, fontWeight: "normal", fontSize: { lg: '13px', xl: '14px' }, color: '#95a5a6' }} >GROUP SCORE</Typography>
                                                <Typography sx={{ fontSize: { lg: '20px', xl: '35px' }, color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600 }}>{!Number.isNaN(summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) ? ((summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) * 100).toFixed(2) : 0}%</Typography>
                                            </Box>
                                            <Box>
                                                <Groups sx={{ my: 1, fontSize: { lg: "40px", xl: '50px' }, color: (theme) => theme.palette.text.sidebarSecondaryText }} />
                                            </Box>
                                        </Box>}
                                        {loggedUserData?.userLevel?.name === 'Team Admin' && <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, width: '99%', mx: { lg: 2, xl: 3 }, px: 2, border: '2px solid #E9EBF3', height: { lg: '65%', xl: '65%' }, borderRadius: 2, backgroundColor: "#EFF1F7" }}>
                                            <Box>
                                                <Typography sx={{ mt: 1, fontWeight: "normal", fontSize: { lg: '13px', xl: '14px' }, color: '#95a5a6' }} >TEAM SCORE</Typography>
                                                <Typography sx={{ fontSize: { lg: '20px', xl: '35px' }, color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600 }}>{!Number.isNaN(summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) ? ((summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) * 100).toFixed(2) : 0}%</Typography>
                                            </Box>
                                            <Box>
                                                <Groups sx={{ my: 1, fontSize: { lg: "40px", xl: '50px' }, color: (theme) => theme.palette.text.sidebarSecondaryText }} />
                                            </Box>
                                        </Box>}

                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, width: '99%', mx: { lg: 2, xl: 3 }, px: 2, height: { lg: '65%', xl: '65%' }, border: '2px solid #FEEFE9', borderRadius: 2, backgroundColor: "#FEF7F5" }}>

                                            <Box>
                                                <Typography sx={{ mt: 1, fontWeight: "normal", fontSize: { lg: '13px', xl: '14px' }, color: '#95a5a6' }} >FAIL SECTION</Typography>
                                                <Typography sx={{ fontSize: { lg: '20px', xl: '35px' }, color: (theme) => theme.palette.text.buttonText, fontWeight: 600 }}>{summary?.flag || 0}</Typography>
                                            </Box>
                                            <Box>
                                                <Flag sx={{ my: 1, fontSize: { lg: "40px", xl: '50px' }, color: (theme) => theme.palette.text.buttonText }} />
                                            </Box>

                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, width: '99%', px: 2, mx: { lg: 2, xl: 3 }, height: { lg: '65%', xl: '65%' }, border: '2px solid #E5F5FD', borderRadius: 2, backgroundColor: "#ecf9ff", color: "white" }}>

                                            <Box>
                                                <Typography sx={{ mt: 1, fontWeight: "normal", fontSize: { lg: '13px', xl: '14px' }, color: '#95a5a6' }} >FAIL ALL</Typography>
                                                <Typography sx={{ fontSize: { lg: '20px', xl: '35px' }, color: '#29abe2', fontWeight: 600 }}>{summary?.fail_count || 0}</Typography>
                                            </Box>
                                            <Box>
                                                <AnnouncementRounded sx={{ my: 1, fontSize: { lg: "50px", xl: '50px' }, color: '#29abe2' }} />
                                            </Box>

                                        </Box>

                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, width: '99%', mx: { lg: 2, xl: 3 }, px: 2, height: { lg: '65%', xl: '65%' }, border: '1px solid #F2F2F2', borderRadius: 2, backgroundColor: "#f8f8f8", color: "white" }}>
                                            <Box>
                                                <Typography sx={{ mt: 1, fontWeight: "normal", fontSize: { lg: '13px', xl: '14px' }, color: '#95a5a6' }} >KUDOS</Typography>
                                                <Typography sx={{ fontSize: { lg: '20px', xl: '35px' }, color: '#202020', fontWeight: 600 }}>{summary?.Kudos || 0}</Typography>
                                            </Box>
                                            <Box>
                                                <StarRounded sx={{ my: 1, fontSize: { lg: "40px", xl: '50px' }, color: '#202020' }} />
                                            </Box>


                                        </Box>
                                    </Box>


                                </TabPanel>

                            ))}

                        </Box>}
                    </Box>
                    {tabLoader && <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "150px", backgroundColor: (theme) => theme.palette.background.paper, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                        <CircularProgress sx={{ mr: 2 }} /> <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
                            Loading Dashboard Data...
                        </Typography>
                    </Stack>}
                    {(groupList?.length !== 0 && !tabLoader) && <Box sx={{ width: "100%", py: 2, px: 0 }}>
                        <Box>
                            <Stack sx={{ backgroundColor: 'transparent', px: 1, py: 1 }} alignItems='center' justifyContent='space-between' direction='row'>
                                <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.buttonText }}>Supervisor's List</Typography>
                                <Button sx={{ backgroundColor: (theme) => theme.palette.background.paper, color: (theme) => theme.palette.text.buttonText, stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px', fontWeight: 'lighter', fontSize: '12px', borderRadius: '5px', border: (theme) => `1px solid ${(theme) => theme.palette.text.PrimaryBorder}`, '&:hover': { backgroundColor: (theme) => theme.palette.background.paper } }} disabled={supervisorDetail?.length === 0} onClick={handleDownloadXLS} startIcon={<Icon icon="tabler:file-download" fontWeight={200} style={{ fontSize: '28px', color: '#636e72', stroke: (theme) => theme.palette.text.sidebarPrimaryText, backgroundColor: (theme) => theme.palette.text.sidebarPrimaryText, strokeWidth: '1px', '&:hover': { backgroundColor: (theme) => theme.palette.background.paper } }} />}>Download Excel</Button>
                            </Stack>
                            <TableContainer component={Paper} elevation={5} sx={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', height: { xs: '250px', xl: '400px' } }}>
                                <Table sx={{ width: "100%" }} aria-label="simple table">
                                    <TableHead sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            backgroundColor: (theme) => theme.palette.background.paper,
                                        }
                                    }} >
                                        <TableRow sx={{ borderBottom: (theme) => { return { lg: `2px solid ${theme.palette.text.primaryBolderBorder}`, xl: `3px solid ${theme.palette.text.PrimaryBolderBorder}` } } }}>

                                            <TableCell>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Supervisor
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center' >
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Employees
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Results
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Viewed
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Accepted
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    1:1
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Reviewed
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='center'>
                                                <Flag />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Warning />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <StarRounded />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography sx={{ fontSize: "13px", color: (theme) => theme.palette.text?.primary }}>
                                                    Score
                                                </Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody  >

                                        {supervisorDetail?.map((row, index) => <React.Fragment key={index}><TableRow sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: (theme) => `1px solid ${(index !== supervisorDetail?.length - 1 && collapseId !== row?.supervisor) ? `${theme.palette.text.tableprimaryBorder}` : 'transparent'}`,
                                            },
                                        }} >

                                            <TableCell component="th" scope="row">
                                                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "100px" }}>
                                                    <>
                                                        <IconButton onClick={() => { setOpen(!open); if (collapseId === row?.supervisor) { setEmpList([]); setCollapseId(undefined) } else { fetchEmpDetails(row?.supervisor); setCollapseId(row?.supervisor); } }}>
                                                            {(collapseId === row?.supervisor) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                        </IconButton>
                                                        <Avatar sx={{ backgroundColor: (index % 2) === 0 ? (theme) => theme.palette.text.buttonText : (theme) => theme.palette.action.selected, color: (theme) => theme.palette.text.sidebarPrimaryText, fontSize: '14px' }}>
                                                            {row?.first_name?.split("")[0]?.toUpperCase()}{row?.last_name?.split("")[0]?.toUpperCase()}
                                                        </Avatar>
                                                    </>

                                                    <Box sx={{ ml: 1 }}>
                                                        {/* {allUsers?.results?.length} */}

                                                        <Typography sx={{ fontSize: '12px' }}>{row?.supervisor}</Typography>

                                                        {/* <Button onClick={() => { alert("test") }} disableRipple variant='text' sx={{ justifyContent: "flex-start", p: 0, m: 0, "&:hover": { bgcolor: "transparent" }, fontSize: "13px" }}>{row?.supervisor__user__email}</Button> */}
                                                        <Link href={`/supervisor/dashboard?stff_sup_id=${row?.supervisor}&date1=${dateObj?.date1}&date2=${dateObj?.date2}&supType=${row?.supervisor_type}`} onClick={(e) => { e.preventDefault(); navigate(`/supervisor/dashboard?stff_sup_id=${row?.supervisor}&date1=${dateObj?.date1}&date2=${dateObj?.date2}&supType=${row?.supervisor_type}`, { state: { stffName: row?.staff?.user?.first_name } }) }} variant='text' sx={{ justifyContent: "flex-start", p: 0, m: 0, color: theme => theme.palette.action.selected, "&:hover": { bgcolor: "transparent" }, fontSize: { lg: '13px', xl: '15px' }, fontWeight: '600' }} underline="none">{row?.supervisor__user__email}</Link>
                                                        <Typography sx={{ fontWeight: "normal", marginRight: '5px', fontSize: { lg: '12px', xl: '14px' } }}>{row?.first_name}&nbsp;{row?.last_name}</Typography>



                                                    </Box >
                                                </Box >
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.total_employe || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.total_result || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.score_viewed || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.accepted || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.onetoone_req || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.reviewed || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.fail_section_count || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.fail_all_count || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.kudos || 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {Number?.isNaN(((row?.total_score / (row?.max_score - row?.na_sum)) * 100)) ? `0%` : `${((row?.total_score / (row?.max_score - row?.na_sum)) * 100)?.toFixed(2)}%`}
                                            </TableCell>

                                        </TableRow>
                                            <TableRow
                                                sx={{
                                                    [`& .${tableCellClasses.root}`]: {
                                                        borderBottom: (theme) => `1px solid ${(collapseId === row?.supervisor) ? theme.palette.text.tableprimaryBorder : 'transparent'}`,
                                                    },

                                                }}
                                            >
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0, m: 0 }} colSpan={12}>
                                                    {!empListLoader > 0 && <Collapse in={(collapseId === row?.supervisor && !empListLoader)} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 1 }}>
                                                            <Typography sx={{ fontWeight: 600, fontSize: { lg: '14px', xl: '16px' } }} gutterBottom component="div">
                                                                Employee Details
                                                            </Typography>
                                                            <Table size="small" aria-label="emp_details" sx={{ m: 0 }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Email</TableCell>
                                                                        <TableCell >Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {empList?.length > 0 && empList?.map((rows, index) => <>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "100px" }}>
                                                                                    <>
                                                                                        <Avatar sx={{ backgroundColor: (index % 2) === 0 ? (theme) => theme.palette.text.buttonText : (theme) => theme.palette.action.selected, color: (theme) => theme.palette.text.sidebarPrimaryText, fontSize: '14px' }}>
                                                                                            {rows?.first_name?.split("")[0]?.toUpperCase()}{rows?.last_name?.split("")[0]?.toUpperCase()}
                                                                                        </Avatar>
                                                                                    </>
                                                                                    <Box sx={{ ml: 1 }}>
                                                                                        <Typography sx={{ fontSize: '12px' }}>{rows?.id}</Typography>
                                                                                        <Typography sx={{ fontWeight: "normal", marginRight: '5px', fontSize: { lg: '12px', xl: '14px' } }}>{rows?.first_name}&nbsp;{rows?.last_name}</Typography>
                                                                                    </Box >
                                                                                </Box >
                                                                            </TableCell>
                                                                            <TableCell>{rows?.email}</TableCell>
                                                                            <TableCell>
                                                                                <Button size='small' sx={{ color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper, fontWeight: 'normal', border: '1px solid #dddde7', borderRadius: '5px', fontSize: { lg: '12px', xl: '13px' }, boxShadow: 'none', '&:hover': { color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper } }} startIcon={<Edit size='small' />} onClick={() => { setEmployeeID(rows?.id); setEmpForm(true) }} variant='contained' >
                                                                                    Edit
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>)}
                                                                </TableBody>
                                                            </Table>
                                                            {empList?.length === 0 && <Box sx={{ fontWeight: 'bold', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                No Data Found
                                                            </Box>}
                                                        </Box>
                                                    </Collapse>}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        )}
                                    </TableBody>

                                </Table>
                                {supervisorDetail?.length === 0 && <Box sx={{ width: "100%", minHeight: "250px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                    <List sx={{ fontSize: "50px" }} /><br />
                                    {!supervisorListLoader && <Typography variant="body" sx={{ my: 1, fontWeight: "bold" }}>
                                        No Score found for the data range selected.
                                    </Typography>}

                                </Box>}
                            </TableContainer>
                        </Box>
                    </Box>}
                    {groupList?.length === 0 && <Box sx={{ width: "100%", minHeight: { lg: "350px", xl: '650px' }, backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px' }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                        <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                        {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                            <CircularProgress />&nbsp;
                            <Typography variant="body" sx={{ my: 1 }}>

                                Loading Data...
                            </Typography>
                        </Stack> : <Typography variant="body" sx={{ my: 1 }}>
                            No Scores Found For Given Date Range.
                        </Typography>}
                    </Box>
                    }

                </TabContext>

            </Box>
            <EmpDialog apiSupId={groupList?.[Number(value) - 1]?.score_card__groups} handleTabChange={handleTabChange} setOpen={setOpen} setCollapseId={setCollapseId} showButton setEmpList={setEmpList} />
            {/* <Box>ok</Box> */}
        </>
    )
}
