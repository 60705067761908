import React, { createContext, useReducer, useEffect, useState } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
//
import moment from 'moment'

import axios from 'axios';
import * as fs from 'fs';
//
import humps from 'humps';
import { isValidToken, setSession } from '../utils/jwt';
// URLisValidToken

import { API } from "../backend";
import { MIconButton } from '../components/@material-extend';
// -----------------------------------------------------------

const ScoreCardsContext = createContext();
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
const grpArr = loggedUserData?.groups?.map(obj => obj.id);


const handlers = {
    USERS_LEVEL: (state, action) => {
        // console.log(action.payload);
        const userLevels = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            userLevels
        };
    },
    GROUPS: (state, action) => {
        // console.log(action.payload);
        const groups = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            groups
        };
    },
    GROUPS_List: (state, action) => {
        // console.log(action.payload);
        const groupsList = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            groupsList
        };
    },
    CATEGORY: (state, action) => {
        // console.log(action.payload);
        const category = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            category
        };
    },
    ARCHIVE: (state, action) => {
        // console.log(action.payload);
        const scorecardArchive = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardArchive
        };
    },
    SECTION: (state, action) => {
        // console.log(action.payload);
        const scorecardSection = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardSection
        };
    },
    FORMULA_ANS_BY_STAFF: (state, action) => {
        // console.log(action.payload);
        const formulaAnsByStaff = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            formulaAnsByStaff
        };
    },
    FORMULA_QUEST_BY_STAFF: (state, action) => {
        // console.log(action.payload);
        const formulaQuestByStaff = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            formulaQuestByStaff
        };
    },
    SECTION_ANSWER_BY_STAFF: (state, action) => {
        const sectionAnswerByStaff = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            sectionAnswerByStaff
        };
    },
    QUESTION_ANSWER_BY_STAFF: (state, action) => {
        const questionsAnswerByStaff = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            questionsAnswerByStaff
        };
    },
    COMMENTS: (state, action) => {
        // console.log(action.payload);
        const scorecardComments = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardComments
        };
    },
    QUESTIONS: (state, action) => {
        // console.log(action.payload);
        const scorecardQuestions = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardQuestions
        };
    },
    ALL_CAUSES: (state, action) => {
        // console.log(action.payload);
        const allScorecardCauses = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            allScorecardCauses
        };
    },
    ALL_CAUSES_FOR_SCORE: (state, action) => {
        // console.log(action.payload);
        const allScorecardCausesScore = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            allScorecardCausesScore
        };
    },
    ALL_CUSTOM_FIELDS: (state, action) => {
        // console.log(action.payload);
        const allCustomFields = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            allCustomFields
        };
    },
    ALL_CUSTOM_FIELDS_TAGS: (state, action) => {
        // console.log(action.payload);
        const allCustomFieldsTags = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            allCustomFieldsTags
        };
    },
    EVENT_TYPE: (state, action) => {
        // console.log(action.payload);
        const scorecardEventType = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardEventType
        };
    },
    EVENT_TYPE_LIST: (state, action) => {
        // console.log(action.payload);
        const scorecardEventTypeList = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scorecardEventTypeList
        };
    },
    TEAMS: (state, action) => {
        const teams = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            teams
        };
    },
    ADD_USER: (state, action) => {
        const user = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    GET_SINGLE_SCORECARD: (state, action) => {

        // console.log("singleScorecards payload", action.payload);
        const singleScorecards = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            singleScorecards
        };
    },
    GET_ALL_SCORECARDS: (state, action) => {

        const allScorecards = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            allScorecards
        };
    },
    GET_USERS_ACTIVITY_LOG: (state, action) => {

        const usersActivityLog = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            usersActivityLog
        };
    },
    GET_SCORECARD_PREVIEW: (state, action) => {

        const scoreCardPreview = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreCardPreview
        };
    },
    GET_TIP_SHEET: (state, action) => {

        const scoreCardTipSheet = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreCardTipSheet
        };
    },
    GET_TIP_QUESTION: (state, action) => {

        const scoreCardTip = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreCardTip
        };
    },
    GET_ALL_ANSWERS: (state, action) => {

        const scoreCardAnswers = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreCardAnswers
        };
    },
    GET_SCORECARD_SETTINGS: (state, action) => {

        const scoreCardSettings = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreCardSettings
        };
    },
    GET_SCORE_LOG: (state, action) => {

        const reportsScoreLog = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLog
        };
    },
    GET_SNAPSHOT: (state, action) => {

        const reportsSnapshot = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsSnapshot
        };
    },
    VIEW_SNAPSHOT: (state, action) => {

        const viewSnapshotDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            viewSnapshotDetails
        };
    },
    GET_SCORE_LOG_GROUPS: (state, action) => {

        const reportsScoreLogGroups = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogGroups
        };
    },
    GET_SCORE_LOG_TEAMS: (state, action) => {

        const reportsScoreLogTeams = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogTeams
        };
    },
    GET_SCORE_LOG_SCORECARDS: (state, action) => {

        const reportsScoreLogScorecards = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogScorecards
        };
    },
    GET_SCORE_LOG_EVENT_TYPE: (state, action) => {

        const reportsScoreLogEventType = action?.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogEventType
        };
    },
    GET_SCORE_LOG_EVENT_SUB_TYPE: (state, action) => {

        const reportsScoreLogEventSubType = action?.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogEventSubType
        };
    },
    GET_SCORE_LOG_SUPERVISOR: (state, action) => {

        const reportsScoreLogSupervisor = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogSupervisor
        };
    },
    GET_SCORE_LOG_EMPLOYEE: (state, action) => {

        const reportsScoreLogEmployee = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogEmployee
        };
    },
    GET_SCORE_LOG_CATEGORY: (state, action) => {

        const reportsScoreLogCategory = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogCategory
        };
    },
    GET_SCORE_LOG_EVALUATOR: (state, action) => {

        const reportsScoreLogEvaluator = action?.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsScoreLogEvaluator
        };
    },
    GET_AGENT_HISTORY: (state, action) => {

        const agentHistoryData = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            agentHistoryData
        };
    },
    GET_GENERAL_REPORT: (state, action) => {

        const generalReportDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            generalReportDetails
        };
    },
    GET_GENERAL_REPORT_COMMENTS: (state, action) => {

        const generalReportCommentsDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            generalReportCommentsDetails
        };
    },
    GET_GENERAL_REPORT_CAUSES: (state, action) => {

        const generalReportCausesDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            generalReportCausesDetails
        };
    },
    GET_STAFF_REVIEW: (state, action) => {

        const reportsStaffreview = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            reportsStaffreview
        };
    },
    GET_STA_REVIEW_COMMENTS: (state, action) => {

        const staffReviewComments = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffReviewComments
        };
    },
    GET_STAFF_TREND_REPORT: (state, action) => {

        const staffTrendReportDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffTrendReportDetails
        };
    },
    GET_STAFF_TREND_DIS: (state, action) => {

        const staffTrendDisDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffTrendDisDetails
        };
    },
    GET_STAFF_TREND_ABOBELOW: (state, action) => {

        const staffTrendAboBelow = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffTrendAboBelow
        };
    },
    GET_STAFF_TREND_RESULTS: (state, action) => {

        const staffTrendResDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffTrendResDetails
        };
    },
    GET_QUESTION_TREND_REPORT: (state, action) => {

        const questionTrendReportDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            questionTrendReportDetails
        };
    },
    GET_QUES_TREND_RESULTS: (state, action) => {

        const quesTrendResDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            quesTrendResDetails
        };
    },
    GET_STAFF_TREND_IND: (state, action) => {

        const staffTrendByInd = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            staffTrendByInd
        };
    },
    GET_SCORECARD_OVERALL_TREND_REPORT: (state, action) => {

        const scoreOverallResponse = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            scoreOverallResponse
        };
    },
    GET_COMPLIANCE_REPORT: (state, action) => {

        const complianceReports = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            complianceReports
        };
    },
    GET_COMPLIANCE_REPORT_SECTION: (state, action) => {

        const complianceSectionReports = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            complianceSectionReports
        };
    },
    GET_COMPLIANCE_RESULT: (state, action) => {

        const complianceResults = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            complianceResults
        };
    },
    GET_COMPLIANCE_FAIL_COMMENTS: (state, action) => {

        const complianceFailCommentsData = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            complianceFailCommentsData
        };
    },
    GET_COMPLIANCE_REPORT_RESULTS: (state, action) => {

        const complianceReportResultsData = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            complianceReportResultsData
        };
    },
    GET_NON_NUMERIC_DATA: (state, action) => {

        const nonNumericDetails = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            nonNumericDetails
        };
    },
    GET_SUCCESS_REPORT: (state, action) => {

        const successReportsData = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            successReportsData
        };
    },
    GET_SUCCESS_REPORT_DRILL: (state, action) => {

        const successReportsDrilledData = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            successReportsDrilledData
        };
    },
    GET_SUCCESS_REPORT_COMMENTS: (state, action) => {

        const successReportsComments = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            successReportsComments
        };
    },
    GET_SUCCESS_REPORT_CAUSES: (state, action) => {

        const successReportsCauses = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            successReportsCauses
        };
    },
    GET_NON_NUMERIC_STATUS: (state, action) => {

        const nonNumericStatus = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            nonNumericStatus
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const ScoreCardsProvider = ({ children }) => {

    const accessToken = window.localStorage.getItem('accessToken');
    const initialState = {
        userLevels: [],
        groups: [],
        Teams: [],
        allUsers: []
    }

    const [blockTitle, setBlockTitle] = useState();
    const [state, dispatch] = useReducer(reducer, initialState);

    const [showSpinner, setShowSpinner] = useState();
    const [showSpinnerComments, setShowSpinnerComments] = useState();
    const [showSpinnerCauses, setShowSpinnerCauses] = useState();

    const [selectedScorecard, setSelectedScorecard] = useState();
    const [selectedScoreFilters, setselectedScoreFilters] = useState(JSON.parse(window.sessionStorage.getItem('selectedScoreFilters')));
    const [selectedSnapshotFilters, setSelectedSnapshotFilters] = useState();
    const [selAnaFilters, setSelAnaFilters] = useState();
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedFilterBy, setSelectedFilterBy] = useState();
    const [selectedScFilter, setselectedScFilter] = useState();
    const [selectedGroupFilter, setSelectedGroupFilter] = useState();
    const [selectedTeamFilter, setSelectedTeamFilter] = useState();
    const [selectedSupervisorFilter, setSelectedSupervisorFilter] = useState();
    const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState();
    const [selectedEventFilter, setSelectedEventFilter] = useState();
    const [selectedEventSubTypeFilter, setSelectedEventSubTypeFilter] = useState();
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState();
    const [selectedEvaluatorFilter, setSelectedEvaluatorFilter] = useState();
    const [scoreFiltersEnabled, setScoreFiltersEnabled] = useState();
    const [selectedScore, setSelectedScore] = useState();
    const [selected, setSelected] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState();
    const [selectedToDate, setSelectedToDate] = useState();
    const [showNumericDownload, setShowNumericDownload] = useState(false);
    const [selDuration, setSelDuration] = useState();
    const [openComments, setOpenComments] = useState(false);
    const [isAddMode, setIsAddMode] = useState(false);

    const [subModLabel, setSubModLabel] = useState();
    // change card header in edit score log
    const [isPrintScoreClicked, setIsPrintScoreClicked] = useState(false);
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // selected event types in table
    const [selectedET, setSelectedET] = useState([]);


    // Get groups data
    const getGroups = () => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/groups`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = response.data;
                dispatch({
                    type: 'GROUPS',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get groups data
    const getGroupsList = () => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/groups/item_list`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response)
                const { data } = response.data;
                dispatch({
                    type: 'GROUPS_List',
                    payload: data
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // ----------------------------------------------------------------------------------------------

    // Add Category
    const addScorecardCategory = async (name) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_category`, JSON.stringify({ name }), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit Category
    const editScorecardCategory = async (id, name) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_category/${id}`, JSON.stringify({ name }), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };


    // Get category data
    const getCategory = () => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_category`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = response.data;
                dispatch({
                    type: 'CATEGORY',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Delete Category
    const deleteCategory = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const categoryId = row;
        const response = await axios.delete(`${API}/score_cards_category/${categoryId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // ----------------------------------------------------------------------------------------------

    // Get scorecard archive data
    const makeScorecardArchive = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        const response = await axios.get(`${API}/score_cards/is_archive?is_archive=True&score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
        // .then((response) => {
        //     const { results } = response.data;
        //     dispatch({
        //         type: 'ARCHIVE',
        //         payload: results
        //     });
        // })
        // .catch((error) => {
        //     console.error(error)
        // })
    }

    // Get scorecard archive data
    const getScorecardArchive = (value, page) => {
        setShowSpinner(true);
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_list/search?value=${value}&page=${page}&is_archive=True`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results, count } = humps.camelizeKeys(response?.data);
                dispatch({
                    type: 'ARCHIVE',
                    payload: { results, count }
                });
                setShowSpinner(false);
            })
            .catch((error) => {
                setShowSpinner(false);
                console.error(error)
            })
    }

    // Make scorecard unarchive
    const unArchiveScorecard = async (scoreCardId) => {

        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.get(`${API}/score_cards/is_archive?is_archive=False&score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })

        // const isArchive = false;
        // const response = await axios.put(`${API}/score_cards/${id}`, JSON.stringify({ is_archive: "False" }), {
        //     headers: {
        //         'Authorization': `Token ${accessToken}`,
        //         'Content-Type': 'application/json'
        //     }
        // });

    };

    // Delete scorecard
    const deleteScorecard = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const scorecardId = row;
        const response = await axios.delete(`${API}/score_cards/${scorecardId}?is_archive=True`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Lock and Unlock scorecard
    // const lockUnlockScorecard = async (row, isLocked) => {
    //     const accessToken = window.localStorage.getItem('accessToken');

    //     const scorecardId = row;
    //     const response = await axios.patch(`${API}/score_cards/${scorecardId}`, JSON.stringify(humps.decamelizeKeys({
    //         isLocked,
    //     })), {
    //         headers: {
    //             'Authorization': `Token ${accessToken}`,
    //             'Content-Type': 'application/json'
    //         }
    //     });
    // };

    // Lock and Unlock scorecard
    const lockUnlockScorecard = async (scorecard) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards/lock_scorecard`, JSON.stringify({ scorecard }), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    };

    // ----------------------------------------------------------------------------------------------

    // Add scorecard section
    const addScorecardSection = async (scoreCard, name, type) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_section`, JSON.stringify(humps.decamelizeKeys({
            name,
            type,
            scoreCard
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit scorecard section
    const editScorecardSection = async (sectionId, scorecardId, name, type) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_section/${sectionId}`, JSON.stringify(humps.decamelizeKeys({
            name,
            type,
            scoreCard: scorecardId
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard section
    const getScorecardSection = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        await axios.get(`${API}/score_cards_section?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'SECTION',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get section for dropdown list
    const getSectionDropdown = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        await axios.get(`${API}/score_cards_section/dropdown?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'SECTION',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get Formula Answer By Staff
    const getAnswerByStaffFormula = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/analytic_staff/answers_by_staff?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj.toDate).format('YYYY-MM-DD')}&filter_by=${obj.filterBy}&score_card=${obj.scoreCardSingle}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'FORMULA_ANS_BY_STAFF',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get Formula Question By Staff
    const getQuestionByStaffFormula = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/analytic_staff/question_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'FORMULA_QUEST_BY_STAFF',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }



    // Get Sections for 1.3 Module ---START---

    const getSectionAnswerByStaff = (scoreCard) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/reports/score_log_sections?score_card=${scoreCard}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const results = humps.camelizeKeys(response.data.data);
                dispatch({
                    type: 'SECTION_ANSWER_BY_STAFF',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }
    // Get Sections for 1.3 Module ---END---

    // Get Questions for 1.3 Module ---START---

    const getQuestionsAnswerByStaff = (scoreCard, section) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/reports/score_log_questions?score_card=${scoreCard}&section=${section}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const results = humps.camelizeKeys(response.data.data);
                dispatch({
                    type: 'QUESTION_ANSWER_BY_STAFF',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get Questions for 1.3 Module ---START---

    // Delete scorecard section
    const deleteScorecardSection = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const sectionId = row;
        const response = await axios.delete(`${API}/score_cards_section/${sectionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // ----------------------------------------------------------------------------------------------

    // Add scorecard comments
    const addScorecardComments = async (scoreCard, section, comments) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_comments`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            section,
            comments
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit scorecard comments
    const editScorecardComments = async (commentsId, scoreCard, section, comments) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_comments/${commentsId}`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            section,
            comments
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard comments
    const getScorecardComments = (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_comments?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'COMMENTS',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Delete comments 
    const deleteScorecardcomments = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const commentId = row;
        const response = await axios.delete(`${API}/score_cards_comments/${commentId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // ----------------------------------------------------------------------------------------------

    // Add scorecard event type 
    const addScorecardEventtype = async (scoreCardId, eventType, existingEventType, eventSubType) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_event_type`, JSON.stringify(humps.decamelizeKeys({
            scoreCardId,
            eventType,
            existingEventType,
            eventSubType
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit scorecard event type
    const editScorecardEventtype = async (scoreCardId, eventType, eventSubType, eventTypeId) => {
        console.log(scoreCardId, eventType, eventSubType, eventTypeId);
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_event_type/${eventTypeId}`, JSON.stringify(humps.decamelizeKeys({
            scoreCardId,
            eventType,
            eventSubType
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard event type
    const getScorecardEventtype = (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_event_type?score_card_id=${scoreCardId}&is_existing='true'`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'EVENT_TYPE',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get scorecard event type list
    const getScorecardEventtypeList = (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_event_type?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'EVENT_TYPE_LIST',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Upload event type csv
    const uploadEventTypeCsv = async (data) => {
        setShowSpinner(true)
        const accessToken = window.localStorage.getItem('accessToken');

        await axios.post(`${API}/score_cards_event_type/upload_csv`, data, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setShowSpinner(false);
        }).catch((error) => {
            setShowSpinner(false);
        })
    };

    // Delete scorecard event type 
    const deleteScorecardEventtype = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const EventTypeId = row;
        const response = await axios.delete(`${API}/score_cards_event_type/${EventTypeId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Delete multiple scorecard event type 
    const deleteMultipleScorecardEventtype = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const EventTypeId = row;
        const response = await axios.get(`${API}/score_cards_event_type/multi_event_delete?events_ids=${EventTypeId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // ----------------------------------------------------------------------------------------------

    // Add scorecard causes
    const addScorecardCauses = async (scoreCard, scoreCardQuestions, section, causes) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_causes`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            scoreCardQuestions,
            section,
            causes
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    };

    // Edit scorecard causes
    const editScorecardCauses = async (causesId, scoreCard, scoreCardQuestions, section, sectionName, causes) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_causes/${causesId}`, JSON.stringify(humps.decamelizeKeys({
            section,
            causes,
            scoreCardQuestions,
            scoreCard,
            sectionName
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard causes
    const getScorecardCauses = (page, scoreCardId, value) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_causes/search?score_card_id=${scoreCardId}&page=${page}&value=${value || ''}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results, count } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'ALL_CAUSES',
                    payload: { results, count }
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Get scorecard for score API
    const getScorecardCausesScore = (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_causes/list_all?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { data } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'ALL_CAUSES_FOR_SCORE',
                    payload: data
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Delete scorecard causes 
    const deleteScorecardCauses = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const causesId = row;
        const response = await axios.delete(`${API}/score_cards_causes/${causesId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // ----------------------------------------------------------------------------------------------

    // Add scorecard custom fields
    const addCustomFields = async (scoreCard, name, type, tags, isRequired) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_custom_fields`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            name,
            type,
            tags,
            isRequired
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add scorecard custom fields
    const addCustomFieldsTags = async (scoreCard, customFields, name) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_custom_tags/add_tags`, JSON.stringify(humps.decamelizeKeys({
            name,
            scoreCard,
            customFields
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit scorecard custom fields
    const editCustomFields = async (customFieldId, scoreCard, name, type, tags, isRequired) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_custom_fields/${customFieldId}`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            name,
            type,
            tags,
            isRequired
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit scorecard custom fields tags
    const editCustomFieldsTags = async (tagsId, name) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.patch(`${API}/score_cards_custom_tags/${tagsId}`, JSON.stringify(humps.decamelizeKeys({
            name
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard custom fields
    const getCustomFields = (page, scoreCardId, value) => {
        const accessToken = window.localStorage.getItem('accessToken');

        axios.get(`${API}/score_cards_custom_fields?score_card_id=${scoreCardId}&page=${page}&value=${value || ''}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results, count } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'ALL_CUSTOM_FIELDS',
                    payload: { results, count }
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }
    // Get scorecard custom fields
    const getCustomFieldsTags = (scoreCardId, customFieldId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        axios.get(`${API}/score_cards_custom_tags?score_card_id=${scoreCardId}&custom_field_id=${customFieldId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'ALL_CUSTOM_FIELDS_TAGS',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Delete scorecard custom fields 
    const deleteCustomFields = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const customFieldId = row;
        const response = await axios.delete(`${API}/score_cards_custom_fields/${customFieldId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Delete scorecard custom fields tags
    const deleteCustomFieldsTags = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const tagsId = row;
        const response = await axios.delete(`${API}/score_cards_custom_tags/${tagsId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };


    // Get questions belongs to specific section
    const getQuestionsOfSection = (scoreCardId, sectionId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        axios.get(`${API}/score_cards_questions/questions_filter?score_card_id=${scoreCardId}&section_id=${sectionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'QUESTIONS',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // ----------------------------------------------------------------------------------------------
    // Get teams data
    const getTeams = (groupId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/team/teams_filter?groups=${groupId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: 'TEAMS',
                    payload: data
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({
                    type: 'TEAMS',
                    payload: null
                });
            })
    }

    // ----------------------------------------------------------------------------------------------

    // Add Scorecard
    const addScorecard = async (name, type, groups) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards`, JSON.stringify(humps.decamelizeKeys({
            name,
            type,
            groups
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Edit Scorecard
    const editScorecard = async (id, name, description, groups, category, location, numberOfQuestions, isLocked) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards/${id}`, JSON.stringify(humps.decamelizeKeys({
            id,
            name,
            description,
            groups,
            category,
            location,
            numberOfQuestions,
            isLocked
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get single scorecards
    const getsingleScorecards = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        await axios.get(`${API}/score_cards/${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                const results = humps.camelizeKeys(res.data);

                dispatch({
                    type: 'GET_SINGLE_SCORECARD',
                    payload: results,
                })
                setShowSpinner(false);
            })
            .catch((error) => {
                setShowSpinner(false);
                console.error(error)
            })
    }
    // Get single scorecards
    const getInitialSingleScorecards = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const data = await axios.get(`${API}/score_cards/${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                setShowSpinner(false);
                const results = humps.camelizeKeys(res.data);
                return results;
                // dispatch({
                //     type: 'GET_SINGLE_SCORECARD',
                //     payload: results,
                // })
            })
            .catch((error) => {
                setShowSpinner(false);
                console.error(error)
            })

        return data;
    }

    // Get question details 
    const getQuestionDetails = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const data = await axios.get(`${API}/score_cards/${scoreCardId}?is_section_required=True`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                setShowSpinner(false);
                const results = humps.camelizeKeys(res.data);
                return results;
                // dispatch({
                //     type: 'GET_SINGLE_SCORECARD',
                //     payload: results,
                // })
            })
            .catch((error) => {
                setShowSpinner(false);
                console.error(error)
            })

        return data;
    }

    // Get edit scorecards
    const getEditScorecards = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const data = await axios.get(`${API}/score_cards/scorecard_questions_data?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                console.log(res);
                setShowSpinner(false);
                const results = humps.camelizeKeys(res?.data?.data[0]);
                return results;
            })
            .catch((error) => {
                setShowSpinner(false);
                console.error(error)
            })

        return data;
    }

    // Refresh scorecards
    const refreshScorecards = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // setShowSpinner(true);

        const data = await axios.get(`${API}/score_cards/scorecard_questions_data?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                // setShowSpinner(false);
                const results = humps.camelizeKeys(res?.data?.data[0]);
                return results;
                // dispatch({
                //     type: 'GET_SINGLE_SCORECARD',
                //     payload: results,
                // })
            })
            .catch((error) => {
                // setShowSpinner(false);
                console.error(error)
            })

        return data;
    }

    // Get All scorecards
    // const getAllScorecards = (page) => {
    //     const accessToken = window.localStorage.getItem('accessToken');
    //     setShowSpinner(true);

    //     if (accessToken) {

    //         axios.get(`${API}/score_cards?page=${page}`, {
    //             headers: {
    //                 'Authorization': `Token ${accessToken}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then((res) => {

    //                 const { results, count } = humps.camelizeKeys(res.data);
    //                 dispatch({
    //                     type: 'GET_ALL_SCORECARDS',
    //                     payload: { results, count },
    //                 })
    //                 setShowSpinner(false);
    //             })
    //             .catch((error) => {
    //                 console.error(error)
    //                 setShowSpinner(false);
    //             })
    //     }

    // }

    // Get All scorecards
    const getAllScorecards = (page, searchString) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        if (accessToken) {

            axios.get(`${API}/score_cards_list/search?is_archive=False&value=${searchString}&page=${page}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const { results, count } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_ALL_SCORECARDS',
                        payload: { results, count },
                    })
                    setShowSpinner(false);
                })
                .catch((error) => {
                    console.error(error)
                    setShowSpinner(false);
                })
        }

    }

    // refresh All scorecards
    const refreshAllScorecards = (page, searchString) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);
        if (accessToken) {

            axios.get(`${API}/score_cards_list/search?is_archive=False&value=${searchString}&page=${page}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const { results, count } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_ALL_SCORECARDS',
                        payload: { results, count },
                    })
                    setShowSpinner(false);

                })
                .catch((error) => {
                    console.error(error);
                    setShowSpinner(false);
                })
        }

    }

    // Search scorecards
    const searchScorecards = (filterList, isArchive, page) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);
        if (accessToken) {

            axios.get(`${API}/score_cards_list/search?value=${filterList}&is_archive=${isArchive}&page=${page}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const { results, count } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_ALL_SCORECARDS',
                        payload: { results, count },
                    })
                    setShowSpinner(false);
                })
                .catch((error) => {
                    console.error(error);
                    setShowSpinner(false);
                })
        }

    }

    // Add number of questions 
    const addNumberOfQuestions = async (scoreCard, numberOfQuestions) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards/add_questions`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            numberOfQuestions
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Format questions 
    const formatQuestions = async (scoreCard, numberOfQuestions, isEmptyQuestionExists) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards/add_questions`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            numberOfQuestions,
            isEmptyQuestionExists
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add single question 
    const addSingleQuestions = async (scoreCard, numberOfQuestion) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards/add_questions`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            numberOfQuestion
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add each question
    const addEachQuestion = async (questionId, scoreCard, question, section) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_questions/${questionId}`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            question,
            section
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // exclude NA in question
    const excludeNaInQuestion = async (questionId, scoreCard, excludeNa) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/score_cards_questions/${questionId}`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            excludeNa
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add compliance
    // const addCompliance = async (questionId, scoreCard, section, sectionType) => {
    //     const accessToken = window.localStorage.getItem('accessToken');

    //     const response = await axios.get(`${API}/score_cards/section_compliance`, JSON.stringify(humps.decamelizeKeys({
    //         questionId,
    //         scoreCard,
    //         section,
    //         sectionType
    //     })), {
    //         headers: {
    //             'Authorization': `Token ${accessToken}`,
    //             'Content-Type': 'application/json'
    //         }
    //     });
    // };

    // Add compliance
    const addCompliance = async (questionId, scoreCard, section, sectionType) => {

        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.get(`${API}/score_cards/section_compliance?question_id=${questionId}&section_id=${section}&section_type=${sectionType}&scorecard_id=${scoreCard}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
    };


    // Add answers
    const addAnswers = async (scoreCard, scoreCardQuestions, answer) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_answers`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            scoreCardQuestions,
            answer,
            is_updated: "False"
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Update answers
    const updateAnswers = async (scoreCard, scoreCardQuestions, answer) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_answers`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            scoreCardQuestions,
            answer,
            is_updated: "True"
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Copy answers
    const copyAnswers = async (scoreCard, scoreCardQuestions) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_answers/copy_answer`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            scoreCardQuestions
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Copy answers
    const addTipSpecificQuestion = async (scorecard, scoreCardQuestions, tip) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/scorecard-question-tip`, JSON.stringify(humps.decamelizeKeys({
            scorecard,
            scoreCardQuestions,
            tip
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // 
    const getTipSpecificQuestion = (scoreCard, questionId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/scorecard-question-tip?score_card_id=${scoreCard}&score_card_questions=${questionId}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const { results } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_TIP_QUESTION',
                        payload: results,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }

    }


    const getSpecificAnswers = async (questionId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            const data = await axios.get(`${API}/score_cards_questions/answer_based_question?question_id=${questionId}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const results = humps.camelizeKeys(res.data);
                    return results;

                })
                .catch((error) => {
                    console.error(error)
                })

            return data;
        }

    }

    // Get scorecard custom fields
    const deleteQuestionBlank = async (questionId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        await axios.get(`${API}/score_cards_questions/questions_delete_blank?question_id=${questionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }

    // Delete scorecard questions
    const deleteScorecardQuestions = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const questionId = row;
        const response = await axios.delete(`${API}/score_cards_questions/${questionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Add question details
    const addQuestionDetails = async (scoreCard, scoreCardQuestions, answer, value, weighting, maxScore, totalWeighting, totalScore) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_question_details`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            scoreCardQuestions,
            answer,
            value,
            weighting,
            maxScore,
            totalWeighting,
            totalScore
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add question details status
    const addQuestionDetailsStatus = async (scoreCard, questionId, answer, status, isChecked) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards_questions/question_details_status`, JSON.stringify(humps.decamelizeKeys({
            scoreCard,
            questionId,
            answer,
            status,
            isChecked
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // get all questions belongs to each section
    const getAllQuestionsBelongSection = async (scoreCardId, sectionId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.get(`${API}/score_cards_questions?score_card=${scoreCardId}&section_id=${sectionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };


    // Get scorecards preview API
    const getScorecardPreview = async (scoreCardId, isCall, callId, staffId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        if (!isCall) {
            const data = await axios.get(staffId ? `${API}/score_cards_preview?score_card_id=${scoreCardId}&staff=${staffId}` : `${API}/score_cards_preview?score_card_id=${scoreCardId}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    // console.log(res);

                    const results = humps.camelizeKeys(res.data);
                    setShowSpinner(false);
                    return results;
                    // dispatch({
                    //     type: 'GET_SCORECARD_PREVIEW',
                    //     payload: results,
                    // })
                })
                .catch((error) => {
                    console.error(error);
                    setShowSpinner(false);
                })

            return data;
        }


        const data = await axios.get(staffId ? `${API}/score_cards_preview?score_card_id=${scoreCardId}&staff=${staffId}&is_section_required=True&call_id=${callId}` : `${API}/score_cards_preview?score_card_id=${scoreCardId}&is_section_required=true&call_id=${callId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                const results = humps.camelizeKeys(res.data);
                setShowSpinner(false);
                return results;
                // dispatch({
                //     type: 'GET_SCORECARD_PREVIEW',
                //     payload: results,
                // })
            })
            .catch((error) => {
                console.error(error);
                setShowSpinner(false);
            })

        return data;
    }


    // Add percentage for specific question
    const addScoreCardPreviewPercentage = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const data = axios.post(`${API}/score_cards_preview/question_status`, JSON.stringify(humps.decamelizeKeys({
            "scoreCard": obj?.scoreCard,
            "answerId": obj?.answerId,
            "scoreCardQuestions": obj?.scoreCardQuestions,
            "specialAnswerId": obj?.specialAnswerId
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
        // .then((res) => {

        //     console.log(res);

        //     const results = humps.camelizeKeys(res.data);
        //     return results;
        //     // dispatch({
        //     //     type: 'GET_SCORECARD_PREVIEW',
        //     //     payload: results,
        //     // })
        // })
        // .catch((error) => {
        //     console.error(error)
        // })
        return data;
    };


    // Get Tip sheet
    const getTipSheet = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        await axios.get(`${API}/scorecard-tip?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                const results = humps.camelizeKeys(res.data.results[0]);

                dispatch({
                    type: 'GET_TIP_SHEET',
                    payload: results,
                })
            })
            .catch((error) => {
                console.error(error)
            })

    };

    // Add Tip sheet
    const addTipSheet = async (scorecard, tip) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/scorecard-tip`, JSON.stringify(humps.decamelizeKeys({
            scorecard,
            tip
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Add target score
    const addTarget = async (scorecardId, target) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.patch(`${API}/score_cards/${scorecardId}`, JSON.stringify(humps.decamelizeKeys({
            target,
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Copy scorecard
    const copyScorecard = async (scoreCard) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/score_cards/copy_scorecard`, JSON.stringify(humps.decamelizeKeys({
            scoreCard
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard settings
    // const getScorecardSettings = async (scoreCardId) => {
    //     const accessToken = window.localStorage.getItem('accessToken');
    //     setShowSpinner(true);

    //     await axios.get(`${API}/score_cards/scorecard_settings?id=${scoreCardId}`, {
    //         headers: {
    //             'Authorization': `Token ${accessToken}`,
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then((res) => {

    //             console.log(res);

    //             const results = humps.camelizeKeys(res.data.data);

    //             dispatch({
    //                 type: 'GET_SCORECARD_SETTINGS',
    //                 payload: results,
    //             })
    //             setShowSpinner(false);
    //         })
    //         .catch((error) => {
    //             setShowSpinner(false);
    //             console.error(error)
    //         })
    // }

    // Get single scorecards
    const getScorecardSettings = async (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const data = await axios.get(`${API}/score_cards/scorecard_settings?id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {

                // console.log(res);

                const results = humps.camelizeKeys(res.data.data);
                return results;
                // dispatch({
                //     type: 'GET_SINGLE_SCORECARD',
                //     payload: results,
                // })
            })
            .catch((error) => {
                console.error(error)
            })

        return data;
    }

    // Update scorecard settings
    const updateScorecardSettings = async (scoreCardId, eventReference, eventType, eventDuration, blindScoring, failAll, pointFailureEmail, complianceFailEmail, failSectionSubtract, customReportLabel, agentDashboard, nonNumeric, selfScore, populateEventDatetime, multiCommentsQuestion, multiCausesQuestion, questionNoView, successRateAnsChoices, successRateAnsPositiveLabel, successRateAnsNegativeLabel) => {
        // console.log(scoreCardId, fieldsName, fieldValue);
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.patch(`${API}/score_cards/scorecard_settings?id=${scoreCardId}`, JSON.stringify(humps.decamelizeKeys({
            eventReference,
            eventType,
            eventDuration,
            blindScoring,
            failAll,
            pointFailureEmail,
            complianceFailEmail,
            failSectionSubtract,
            customReportLabel,
            agentDashboard,
            nonNumeric,
            selfScore,
            populateEventDatetime,
            multiCommentsQuestion,
            multiCausesQuestion,
            questionNoView,
            successRateAnsChoices,
            successRateAnsPositiveLabel,
            successRateAnsNegativeLabel
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // ----------------------------------------------------------------------------------------------
    // Get all Score Log
    const getScoreLog = (page) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        if (accessToken) {

            axios.get(`${API}/reports?from_date=${moment().startOf('month').format('YYYY-MM-DD')}&to_date=${moment().endOf('month').format('YYYY-MM-DD')}&filter_by=created_at&page=${page}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);

                    const { data } = humps.camelizeKeys(res);
                    dispatch({
                        type: 'GET_SCORE_LOG',
                        payload: data,
                    })
                    setShowSpinner(false);
                })
                .catch((error) => {
                    console.error(error);
                    setShowSpinner(false);
                })
        }
    }


    // Search scorelog
    const searchScoreLog = (filterList) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports?from_date=${moment().startOf('month').format('YYYY-MM-DD')}&to_date=${moment().endOf('month').format('YYYY-MM-DD')}&filter_by=created_at&search=${filterList}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {

                    const { data } = humps.camelizeKeys(res);
                    dispatch({
                        type: 'GET_SCORE_LOG',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }

    }

    // Get all score log in xls format
    const downloadScoreLogXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/reports/xlsx_score_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&supervisor=${obj.supervisor}`, responseType: 'arraybuffer', headers };

        const response = await axios(config);
        return response;
    }

    // Get groups of score log
    const getScoreLogGroups = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/reports/score_log_groups?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&sup_groups=${obj.sup_grp}&is_calibrated_score=${obj.isCalibratedScore || 'False'}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps?.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_GROUPS',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get teams of score log
    const getScoreLogTeams = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_teams?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&sup_groups=${obj.sup_grp}&score_card__groups_id__in=${obj.group}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_TEAMS',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // for 6.6 calibration trend group & teams
    const [calibrationTrendEvaluator, setCalibrationTrendEvaluator] = useState([])
    const [calibrationTrendGroup, setCalibrationTrendGroup] = useState([])
    const [calibrationTrendTeam, setCalibrationTrendTeam] = useState([])

    const getScoreLogGroupsCalibrationTrend = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/reports/score_log_groups?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&sup_groups=${obj.sup_grp}&is_calibrated_score=${obj.isCalibratedScore}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    setCalibrationTrendGroup(data)
                    // dispatch({
                    //     type: 'GET_SCORE_LOG_GROUPS',
                    //     payload: data,
                    // })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get teams of score log
    const getScoreLogTeamsCalibrationTrend = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_teams?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&sup_groups=${obj.sup_grp}&score_card__groups=${obj.group}&is_calibrated_score=${obj.isCalibratedScore}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    setCalibrationTrendTeam(data)
                    // dispatch({
                    //     type: 'GET_SCORE_LOG_TEAMS',
                    //     payload: data,
                    // })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get evaluator of score log for 6.6 calibration trend
    const getScoreLogEvaluatorCalibrationTrend = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_evaluator?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&is_calibrated_score=${obj.isCalibratedScore}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    setCalibrationTrendEvaluator(data)
                    // dispatch({
                    //     type: 'GET_SCORE_LOG_EVALUATOR',
                    //     payload: data,
                    // })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // 6.6 end

    // Get scorecards of score log
    const getScoreLogScorecards = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_scorecards?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups_id__in=${obj.group}&teams_id__in=${obj.teams || ''}&score_card_id__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator || ""}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_SCORECARDS',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get event type of score log
    const getScoreLogEventType = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/reports/score_log_event_type?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams || ''}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps?.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_EVENT_TYPE',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get event type of score log
    const getScoreLogEventSubType = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_event_sub_type?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams || ''}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    console.log(data?.map(item => ({ ...item, eventTypeEventSubType: item?.subType })))
                    dispatch({
                        type: 'GET_SCORE_LOG_EVENT_SUB_TYPE',
                        payload: data?.length > 0 ? data?.map(item => ({ ...item, eventTypeEventSubType: item?.subType })) : data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get supervisor of score log
    const getScoreLogSupervisor = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_supervisor?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_SUPERVISOR',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get employee of score log
    const getScoreLogEmployee = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_employee?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&sup_groups=${obj.sup_grp}&supervisor=${obj.supervisor}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_EMPLOYEE',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get category of score log
    const getScoreLogCategory = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_category?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_CATEGORY',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Get evaluator of score log
    const getScoreLogEvaluator = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {

            axios.get(`${API}/reports/score_log_evaluator?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams || ''}&score_card=${obj.scoreCard || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&${subModLabel === 6.6 ? 'is_calibrated_score' : ''}=${subModLabel === 6.6 ? 'True' : ''}&sup_groups=${obj.sup_grp}&send_email=0`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res.data);
                    dispatch({
                        type: 'GET_SCORE_LOG_EVALUATOR',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    // Report Filter
    const applyReportFilter = async (obj) => {

        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/reports?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&supervisor=${obj.supervisor}&send_email=0`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_SCORE_LOG',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // List all Snapshot
    const getSnapShot = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);
        const response = axios.get(`${API}/snap_shot_report/search?value=${obj?.search}&page=${obj?.page}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_SNAPSHOT',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // View Snapshot
    const viewSnapShot = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // setShowSpinner(true);

        const response = axios.get(`${API}/snap_shot_report/snapshot_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response.data);
            console.log("data", data);
            dispatch({
                type: 'VIEW_SNAPSHOT',
                payload: data
            });
        }).catch((error) => {
            console.error(error);
        })

    }


    // Edit Score
    const editScore = async (score, scoreCard, staff, teams, supervisor, eventDate, eventTime, reference, scoreValue, selectedAns, answers, ansCauses, ansComments, comment, attachments, empEmail, supEmail, eventType, eventSubType, trackAns, totalScore, percentage, scoreResult, customField) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const payloadObj = humps.decamelizeKeys({
            score,
            scoreCard,
            staff,
            teams,
            supervisor,
            eventDate,
            eventTime,
            reference,
            selectedAns,
            answers,
            ansCauses,
            ansComments,
            comment,
            attachments,
            empEmail,
            supEmail,
            eventType,
            eventSubType,
            trackAns,
            totalScore,
            percentage,
            scoreResult,
            customField
        });

        console.log("payloadObj before", payloadObj);

        const mergedObj = { ...payloadObj, score_value: scoreValue }

        console.log("payloadObj after", mergedObj);

        const response = await axios.put(`${API}/score/update_score`, JSON.stringify(mergedObj), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    };

    // Delete Score
    const deleteScore = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const scoreId = row;
        const response = await axios.delete(`${API}/score/${scoreId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Delete Attachment
    const deleteAttachment = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const attachId = row;
        const response = await axios.delete(`${API}/score_attachments/${attachId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });

        return response;
    };

    // Delete Comment
    const deleteComment = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const commentId = row;
        const response = await axios.delete(`${API}/score/comment?id=${commentId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        })

        return response?.data;
    };

    // give kudos
    const giveKudos = async (scoreId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        await axios.get(`${API}/global_dashboard/give_kudos?score_id=${scoreId}&status=ADD&is_kudos=true`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }

    // remove kudos
    const removeKudos = async (scoreId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        await axios.get(`${API}/global_dashboard/give_kudos?score_id=${scoreId}&is_kudos=false`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }

    const hideOnDashboard = async (scoreId, hideFromDashboard) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.patch(`${API}/score/${scoreId}`, JSON.stringify(humps.decamelizeKeys({
            hideFromDashboard,
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }

    const showOnDashboard = async (scoreId, hideFromDashboard) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.patch(`${API}/score/${scoreId}`, JSON.stringify(humps.decamelizeKeys({
            hideFromDashboard,
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }

    // View Agent History
    const viewAgentHistory = async (scoreId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        axios.get(`${API}/score_cards_preview/agent_history?score_id=${scoreId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { results } = humps.camelizeKeys(response?.data);
            console.log("results", results)
            dispatch({
                type: 'GET_AGENT_HISTORY',
                payload: results
            });

        })
            .catch((error) => {
                console.error(error);
            })

    }

    // export xls
    // const exportScoreXls = async (scoreCardId) => {
    //     const accessToken = window.localStorage.getItem('accessToken');

    //     const response = await axios.get(`${API}/reports/review_score_log?score_card=${scoreCardId}`, {
    //         headers: {
    //             'Authorization': `Token ${accessToken}`,
    //             'Content-Type': 'application/json'
    //         }
    //     });
    // };

    const exportScoreXls = async (scoreId) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/reports/review_score_log?score_id=${scoreId}`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')

            const outputFilename = `Export - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }

    // Get all snapshot in xls format
    const downloadSnapshotXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/snap_shot_report/download_snapshot_report?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Export - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }

    // List all General Reports
    const getGeneralReports = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // setShowSpinner(true);

        const response = axios.get(`${API}/reports/general_report?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card__in=${obj.scoreCardMultiple}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&send_email=0`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_GENERAL_REPORT',
                payload: data
            });
            // setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                // setShowSpinner(false);
            })
    }

    // List all General Reports Comments
    const getGeneralReportsComments = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinnerComments(true);

        const response = axios.get(`${API}/reports/general_report_comments?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&send_email=0`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_GENERAL_REPORT_COMMENTS',
                payload: data
            });
            setShowSpinnerComments(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinnerComments(false);
            })
    }

    // List all General Causes Reports
    const getGeneralCausesReports = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinnerCauses(true);

        const response = axios.get(`${API}/reports/general_report_causes?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_GENERAL_REPORT_CAUSES',
                payload: data
            });
            setShowSpinnerCauses(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinnerCauses(false);
            })
    }

    // Get general report in xls format
    const downloadGeneralReportXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/reports/general_report_export?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card__in=${obj.scoreCardMultiple}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&send_email=0`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `General report - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }


    // List all Staff reviwe
    const getStaffReview = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/reports/staff_score_review?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&supervisor=${obj.supervisor}&send_email=0`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);

            dispatch({
                type: 'GET_STAFF_REVIEW',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Get staff review in xls 
    const downloadScorereviewXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/reports/export_staff_review?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&supervisor=${obj.supervisor}&send_email=0`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Staff Review Export - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }

    // List all staff reviews comments
    const getStaffReviewComments = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // setShowSpinner(true);

        const response = axios.get(`${API}/reports/score_comments?score=${obj.scoreId}&send_email=0`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_STA_REVIEW_COMMENTS',
                payload: data
            });
            // setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                // setShowSpinner(false);
            })
    }

    // Display score on dashboard
    const displayDashboard = async (score) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/reports/emp_show_dashboard?send_email=0`, JSON.stringify(humps.decamelizeKeys({
            score
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Review score
    const reviewScore = async (score, comment, notifyEmp) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/reports/multi_review_score`, JSON.stringify(humps.decamelizeKeys({
            score,
            comment,
            notifyEmp
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Delete score
    const deleteScoreView = async (score) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/reports/score_multi_delete`, JSON.stringify(humps.decamelizeKeys({
            score
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // const getSearchComplianceReport = (dataObj, filterName) => {
    //     const filtVal = dataObj?.results?.filter(data =>
    //         data?.name?.toLowerCase().includes(filterName?.trim().toLowerCase()) !== false ||
    //         data?.groupName?.toLowerCase().includes(filterName?.trim().toLowerCase()) !== false ||
    //         data?.fail?.toString()?.includes(filterName?.trim()) !== false ||
    //         data?.failRate?.toString()?.includes(filterName?.trim()) !== false ||
    //         data?.notApplicable?.toString()?.includes(filterName?.trim()) !== false ||
    //         data?.passScore?.toString()?.includes(filterName?.trim()) !== false ||
    //         data?.passRate?.toString()?.includes(filterName?.trim()) !== false ||
    //         data?.sections?.some(val => val?.name?.trim()?.toLowerCase() === filterName?.trim().toLowerCase()) !== false

    //     )
    //     console.log(filterName, { ...dataObj, results: filtVal, count: filtVal?.length })
    //     return { ...dataObj, results: filtVal, count: filtVal?.length }
    // }

    // Compliance Report
    const complianceReport = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        axios.get(`${API}/compliance_report?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&search=${obj.filterName}&supervisor=${obj.supervisor}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response, obj?.filterName)
            const { data } = humps.camelizeKeys(response);
            console.log(data);
            // console.log((obj?.filterName !== '') ? getSearchComplianceReport(data, obj?.filterName) : data);
            dispatch({
                type: 'GET_COMPLIANCE_REPORT',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Compliance Report
    const complianceReportBySection = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        axios.get(`${API}/compliance_report/compliance_by_question?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_COMPLIANCE_REPORT_SECTION',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })
    }

    // Compliance Report
    const complianceReportDetails = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/compliance_report/compliance_result_details?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&question_id=${obj.questionId}&status=${obj.status}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_COMPLIANCE_RESULT',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Compliance Report
    const complianceFailComments = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinnerComments(true);

        axios.get(`${API}/compliance_report/compliance_scorecard_fail_comments?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_COMPLIANCE_FAIL_COMMENTS',
                payload: data
            });
            setShowSpinnerComments(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinnerComments(false);
            })

    }

    // Get general report in xls format
    const downloadComplianceReportXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/compliance_report?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&export=true`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Compliance report - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }

    // Compliance Report Results
    const complianceReportResults = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        axios.get(`${API}/compliance_report/compliance_scorecard_details?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_COMPLIANCE_REPORT_RESULTS',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Add custom fields
    const addNonNumeric = async (scorecard, startScore, endScore, label, colour) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.post(`${API}/scorecard_threshold`, JSON.stringify(humps.decamelizeKeys({
            scorecard,
            startScore,
            endScore,
            label,
            colour
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Get scorecard non numeric details
    const getNonNumericData = (scoreCardId) => {
        const accessToken = window.localStorage.getItem('accessToken');

        axios.get(`${API}/scorecard_threshold?score_card_id=${scoreCardId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const { results } = humps.camelizeKeys(response.data);
                dispatch({
                    type: 'GET_NON_NUMERIC_DATA',
                    payload: results
                });
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // Delete NonNumeric Threshold 
    const deleteThreshold = async (row) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const thresholdId = row;
        const response = await axios.delete(`${API}/scorecard_threshold/${thresholdId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
    };

    // Get scorecard non numeric color
    const getNonNumericColour = (scoreCardId, percentage) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = axios.get(`${API}/scorecard_threshold/threshold_status?scorecard_id=${scoreCardId}&score=${percentage}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })

        return response;
        // .then((response) => {
        //     const  results  = humps.camelizeKeys(response.data);
        //     dispatch({
        //         type: 'GET_NON_NUMERIC_STATUS',
        //         payload: results
        //     });
        // })
        // .catch((error) => {
        //     console.error(error)
        // })
    }

    // Edit NonNumeric Threshold 
    const editThreshold = async (thresholdId, scorecard, operator, score, label, colour) => {
        const accessToken = window.localStorage.getItem('accessToken');

        const response = await axios.put(`${API}/scorecard_threshold/${thresholdId}`, JSON.stringify(humps.decamelizeKeys({
            scorecard,
            operator,
            score,
            label,
            colour
        })), {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    };

    // Success Report
    const successReport = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/success_reports/main_listing?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName || ""}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&supervisor=${obj.supervisor}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_SUCCESS_REPORT',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Get success report in xls format
    const downloadSuccessReportXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/success_reports/main_listing?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&export=true`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Success report - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }

    // Success Report drill down
    const successReportDrillDown = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/success_reports/drilldown?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_SUCCESS_REPORT_DRILL',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // Success report cause drill down
    const successDrillDownCauseReport = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        const response = axios.get(`${API}/success_reports/drilldown_causes_count?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            dispatch({
                type: 'GET_SUCCESS_DRILL_CAUSES',
                payload: data
            });
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    // List success reports question comments
    const getSuccessReportsComments = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinnerComments(true);

        const response = axios.get(`${API}/success_reports/drilldown_comments?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&question_id=${obj.questionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_SUCCESS_REPORT_COMMENTS',
                payload: data?.results
            });
            setShowSpinnerComments(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinnerComments(false);
            })
    }

    // List success reports causes
    const getSuccessReportsCauses = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinnerComments(true);

        const response = axios.get(`${API}/success_reports/drilldown_causes_count?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&question_id=${obj.questionId}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const { data } = humps.camelizeKeys(response);
            console.log("data", data);
            dispatch({
                type: 'GET_SUCCESS_REPORT_CAUSES',
                payload: data?.results
            });
            setShowSpinnerComments(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinnerComments(false);
            })
    }

    // Get success report drill down in xls format
    const successReportDrillDownXls = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/success_reports/drilldown?&sup_groups=${obj?.sup_grp}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff=${obj.staff}&score_card__category=${obj.scoreCardCategory}&evaluator=${obj.evaluator}&page=${obj.page}&search=${obj.filterName}&export=true`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Success report drill down - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }


    // report evaluation api ----
    const [reportEvaluationData, setReportEvaluationData] = useState([])
    const reportEvaluationApi = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);
        axios.get(`${API}/reports/evaluation?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor=${obj?.supervisor}&staff=${obj?.staff}&score_card__category=${obj?.scoreCardCategory}&evaluator=${obj?.evaluator}&page=${obj?.page}&search=${obj?.filterName}`, {
            headers: {
                'Authorization': `Token ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            const { data } = humps.camelizeKeys(response);
            setReportEvaluationData(data)
            setShowSpinner(false);
        })
            .catch((error) => {
                console.error(error)
                setShowSpinner(false);
            })

    }

    const downloadEvaluationReport = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setShowSpinner(true);

        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { 'Authorization': `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = { method: 'GET', url: `${API}/reports/evaluation_export?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor=${obj?.supervisor}&staff=${obj?.staff}&score_card__category=${obj?.scoreCardCategory}&evaluator=${obj?.evaluator}&page=${obj?.page}&search=${obj?.filterName}`, responseType: 'arraybuffer', headers };

        try {
            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')
            console.log(currentDateTime);

            const outputFilename = `Evaluation Report - ${currentDateTime}.xlsx`;

            setShowSpinner(false);
            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            setShowSpinner(false);
            throw Error(error);
        }
    }


    // ----------------------------------------------------------------------------------------------

    return <ScoreCardsContext.Provider value={{
        groups: state.groups,
        groupsList: state.groupsList,
        category: state.category,
        scorecardSection: state.scorecardSection,
        formulaAnsByStaff: state.formulaAnsByStaff,
        formulaQuestByStaff: state.formulaQuestByStaff,
        sectionAnswerByStaff: state.sectionAnswerByStaff,
        questionsAnswerByStaff: state.questionsAnswerByStaff,
        scorecardArchive: state.scorecardArchive,
        scorecardComments: state.scorecardComments,
        scorecardEventType: state.scorecardEventType,
        scorecardEventTypeList: state.scorecardEventTypeList,
        scoreCardPreview: state.scoreCardPreview,
        teams: state.teams,
        userLevels: state.userLevels,
        singleScorecards: state.singleScorecards,
        allScorecards: state.allScorecards,
        usersActivityLog: state.usersActivityLog,
        scoreCardTipSheet: state.scoreCardTipSheet,
        scoreCardTip: state.scoreCardTip,
        scorecardQuestions: state.scorecardQuestions,
        allScorecardCauses: state.allScorecardCauses,
        allScorecardCausesScore: state.allScorecardCausesScore,
        allCustomFields: state.allCustomFields,
        allCustomFieldsTags: state.allCustomFieldsTags,
        scoreCardAnswers: state.scoreCardAnswers,
        scoreCardSettings: state.scoreCardSettings,
        reportsScoreLog: state.reportsScoreLog,
        reportsSnapshot: state.reportsSnapshot,
        viewSnapshotDetails: state.viewSnapshotDetails,
        reportsScoreLogGroups: state.reportsScoreLogGroups,
        reportsScoreLogScorecards: state.reportsScoreLogScorecards,
        reportsScoreLogEventType: state.reportsScoreLogEventType,
        reportsScoreLogEventSubType: state.reportsScoreLogEventSubType,
        reportsScoreLogTeams: state.reportsScoreLogTeams,
        reportsScoreLogSupervisor: state.reportsScoreLogSupervisor,
        reportsScoreLogEmployee: state.reportsScoreLogEmployee,
        reportsScoreLogCategory: state.reportsScoreLogCategory,
        reportsScoreLogEvaluator: state.reportsScoreLogEvaluator,
        agentHistoryData: state.agentHistoryData,
        generalReportDetails: state.generalReportDetails,
        generalReportCommentsDetails: state.generalReportCommentsDetails,
        generalReportCausesDetails: state.generalReportCausesDetails,
        reportsStaffreview: state.reportsStaffreview,
        staffReviewComments: state.staffReviewComments,
        staffTrendReportDetails: state.staffTrendReportDetails,
        staffTrendDisDetails: state.staffTrendDisDetails,
        staffTrendAboBelow: state.staffTrendAboBelow,
        staffTrendResDetails: state.staffTrendResDetails,
        quesTrendResDetails: state.quesTrendResDetails,
        questionTrendReportDetails: state.questionTrendReportDetails,
        staffTrendByInd: state.staffTrendByInd,
        complianceReports: state.complianceReports,
        complianceSectionReports: state.complianceSectionReports,
        complianceResults: state.complianceResults,
        complianceFailCommentsData: state.complianceFailCommentsData,
        complianceReportResultsData: state.complianceReportResultsData,
        nonNumericDetails: state.nonNumericDetails,
        successReportsData: state.successReportsData,
        successReportsDrilledData: state.successReportsDrilledData,
        successReportsComments: state.successReportsComments,
        successReportsCauses: state.successReportsCauses,
        nonNumericStatus: state.nonNumericStatus,
        getGroups,
        getGroupsList,
        getCategory,
        getTeams,
        addScorecard,
        editScorecard,
        addScorecardCategory,
        editScorecardCategory,
        getScorecardArchive,
        makeScorecardArchive,
        unArchiveScorecard,
        deleteCategory,
        deleteScorecard,
        lockUnlockScorecard,
        addScorecardSection,
        editScorecardSection,
        getScorecardSection,
        getSectionDropdown,
        getAnswerByStaffFormula,
        getQuestionByStaffFormula,
        getSectionAnswerByStaff,
        getQuestionsAnswerByStaff,
        deleteScorecardSection,
        addNumberOfQuestions,
        formatQuestions,
        addSingleQuestions,
        addEachQuestion,
        excludeNaInQuestion,
        deleteQuestionBlank,
        addTipSpecificQuestion,
        getTipSpecificQuestion,
        getSpecificAnswers,
        addAnswers,
        addCompliance,
        updateAnswers,
        copyAnswers,
        addQuestionDetails,
        addQuestionDetailsStatus,
        getAllQuestionsBelongSection,
        addScorecardComments,
        editScorecardComments,
        getScorecardComments,
        deleteScorecardcomments,
        addScorecardEventtype,
        editScorecardEventtype,
        getScorecardEventtype,
        getScorecardEventtypeList,
        uploadEventTypeCsv,
        deleteScorecardEventtype,
        deleteMultipleScorecardEventtype,
        addScorecardCauses,
        editScorecardCauses,
        getScorecardCauses,
        getScorecardCausesScore,
        deleteScorecardCauses,
        addCustomFields,
        addCustomFieldsTags,
        editCustomFields,
        editCustomFieldsTags,
        getCustomFields,
        getCustomFieldsTags,
        deleteCustomFields,
        deleteCustomFieldsTags,
        deleteScorecardQuestions,
        getQuestionsOfSection,
        getAllScorecards,
        refreshAllScorecards,
        searchScorecards,
        getsingleScorecards,
        getInitialSingleScorecards,
        getQuestionDetails,
        getEditScorecards,
        refreshScorecards,
        getScorecardPreview,
        addScoreCardPreviewPercentage,
        addTipSheet,
        getTipSheet,
        blockTitle,
        setBlockTitle,
        selectedScorecard,
        setSelectedScorecard,
        addTarget,
        copyScorecard,
        getScorecardSettings,
        updateScorecardSettings,
        showSpinner,
        setShowSpinner,
        showSpinnerComments, setShowSpinnerComments,
        showSpinnerCauses, setShowSpinnerCauses,
        selectedScoreFilters,
        setselectedScoreFilters,
        selectedSnapshotFilters,
        setSelectedSnapshotFilters,
        selAnaFilters,
        setSelAnaFilters,
        scoreFiltersEnabled,
        setScoreFiltersEnabled,
        setSelectedDateRange,
        selectedDateRange,
        selectedFilterBy,
        setSelectedFilterBy,
        getScoreLog,
        searchScoreLog,
        downloadScoreLogXls,
        getScoreLogGroups,
        getScoreLogScorecards,
        getScoreLogEventType,
        getScoreLogEventSubType,
        getScoreLogTeams,
        getScoreLogSupervisor,
        getScoreLogEmployee,
        getScoreLogCategory,
        getScoreLogEvaluator,
        // 6.6 calibration trend---
        setCalibrationTrendEvaluator,
        calibrationTrendEvaluator,
        getScoreLogGroupsCalibrationTrend,
        getScoreLogTeamsCalibrationTrend,
        getScoreLogEvaluatorCalibrationTrend,
        calibrationTrendGroup, setCalibrationTrendGroup,
        calibrationTrendTeam, setCalibrationTrendTeam,
        // 6.6 end
        applyReportFilter,
        getSnapShot,
        viewSnapShot,
        downloadSnapshotXls,
        editScore,
        giveKudos,
        removeKudos,
        viewAgentHistory,
        deleteScore,
        deleteAttachment,
        deleteComment,
        exportScoreXls,
        showOnDashboard,
        hideOnDashboard,
        getGeneralReports,
        getGeneralReportsComments,
        getGeneralCausesReports,
        downloadGeneralReportXls,
        getStaffReview,
        downloadScorereviewXls,
        getStaffReviewComments,
        displayDashboard,
        selectedScore,
        setSelectedScore,
        selected, setSelected,
        selectedScFilter,
        setselectedScFilter,
        selectedGroupFilter, setSelectedGroupFilter,
        selectedTeamFilter, setSelectedTeamFilter,
        selectedSupervisorFilter, setSelectedSupervisorFilter,
        selectedEmployeeFilter, setSelectedEmployeeFilter,
        selectedEventFilter, setSelectedEventFilter,
        selectedEventSubTypeFilter, setSelectedEventSubTypeFilter,
        selectedCategoryFilter, setSelectedCategoryFilter,
        selectedEvaluatorFilter, setSelectedEvaluatorFilter,
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        showNumericDownload,
        setShowNumericDownload,
        selDuration,
        setSelDuration,
        subModLabel,
        setSubModLabel,
        reviewScore,
        deleteScoreView,
        complianceReport,
        complianceReportBySection,
        complianceReportDetails,
        complianceFailComments,
        downloadComplianceReportXls,
        complianceReportResults,
        isPrintScoreClicked, setIsPrintScoreClicked,
        addNonNumeric,
        getNonNumericData,
        deleteThreshold,
        editThreshold,
        getNonNumericColour,
        // success report drill down
        successReport,
        downloadSuccessReportXls,
        successReportDrillDown,
        getSuccessReportsComments,
        getSuccessReportsCauses,
        successReportDrillDownXls,
        // report evaluation
        reportEvaluationData, setReportEvaluationData,
        reportEvaluationApi,
        downloadEvaluationReport,
        selectedET, setSelectedET,
        // scorecard comments
        openComments, setOpenComments,
        isAddMode, setIsAddMode
    }} >
        {children}
    </ScoreCardsContext.Provider>

}

export default ScoreCardsContext