import * as Yup from 'yup';
import { useState, useContext, useEffect } from 'react';
// 
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Close } from '@material-ui/icons'
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Alert,
  Stack,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  ListSubheader,
  InputAdornment,
  FormControl,
  IconButton,
  Divider,
  Typography,
  Autocomplete,
  CircularProgress,
  Box
} from '@material-ui/core';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import useAuth from '../../../../hooks/useAuth';
//
import { MIconButton } from '../../../../components/@material-extend';
import AddUserContext from '../../../../contexts/AddUserContext'
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext';
import DisableWrapper from '../../../dashboard/DisableWrapper';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle:
  {
    padding: 5
  },
  select: {
    backgroundColor: "#FFFFFF"
  },
  selectDisabled: {
    backgroundColor: "#E9ECEF"
  }
}));

const style = {
  root: {
    // width: 600,
    color: 'red',
    // marginLeft: '25em',
  },
}
// ----------------------------------------------------------------------

export default function AddUserDialog({ addUserButton, selectedRow, setAddMode, closeAddUserDialog }) {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const isAiEnabled = loggedUserData?.aiEnabled

  const {
    userLevels,
    groups,
    teams,
    getUserLevels,
    getGroups,
    getTeams,
    addUser,
    getAllUsers,
    editUser,
    resetPassword,
    loggedUserDetails,
    open,
    setOpen,
    isAddMode,
    setIsAddMode,
    refreshAllUsers,
    getAllUsersGroupsTeams,
    editUserSpinner
  } = useContext(AddUserContext);
  const { groupsList, getGroupsList } = useContext(ScoreCardsContext);
  const { getCountryName, cityDetails, getCityName } = useAuth();

  // const [open, setOpen] = useState(false);

  const [userLevel, setUserLevel] = useState(userLevels);
  const [userLev, setUserLev] = useState([]);
  const [group, setGroup] = useState(groupsList);
  const [gro, setGro] = useState([]);
  const [teamList, setTeamList] = useState();
  const [personName, setPersonName] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [supportAccess, setSupportAccess] = useState();
  const [billingAccess, setBillingAccess] = useState();
  const [readOnly, setReadOnly] = useState();
  const [mustChangePassword, setMustChangePassword] = useState();
  const [showAddUserButton, setShowAddUserButton] = useState(addUserButton);
  const [countryDetailsData, setCountryDetailsData] = useState([]);
  const [cityDetailsData, setCityDetailsData] = useState([]);
  const [locationName, setLocationName] = useState();
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [cityInputValue, setCityInputValue] = useState('');
  const [isLoading, setIsLoading] = useState();
  // const [isAddMode, setIsAddMode] = useState(setAddMode);

  const [multiselectDisabled, setMultiselectDisabled] = useState(true);

  const classes = useStyles();

  const isMountedRef = useIsMountedRef();

  // useEffect(() => {
  //   setTeam(teams);
  //   const selectedTeams = teams?.map((item) => {
  //     return item?.id;
  //   })

  //   setFieldValue("team", selectedTeams);
  // }, [teams]);
  // useEffect(() => {
  //   setTeamList(teams);
  // }, [teams]);

  useEffect(async () => {
    if (open) {
      if (selectedRow && !isAddMode) {
        const response = await getAllUsersGroupsTeams(selectedRow?.id);

        // Set selected row on edit dialog modal
        setFieldValue('firstName', response?.firstName);
        setFieldValue('lastName', response?.lastName);
        setFieldValue('email', response?.email);
        setFieldValue('shortUsername', response?.shortUsername);
        if (response?.userLevel) {
          setFieldValue('userLev', response?.userLevel.id);
        } else {
          setFieldValue('userLev', ' ');
        }

        if (response?.groups) {
          const selectedGroups = response?.groups.map((data) => data.id);
          setFieldValue('gro', selectedGroups);
          const responseTeam = await getTeams(selectedGroups.toString());
          setTeamList(responseTeam);
        }

        if (response?.teams) {
          const groupsBelongsTo = response.teams.map((data) => data.groups);
          // getTeams(groupsBelongsTo);
          const selectedTeams = response.teams.map((data) => data.id);

          setFieldValue('team', selectedTeams);
        } else {
          setFieldValue('team', ' ');
        }

        setFieldValue('supportAccess', response?.supportAccess);
        setFieldValue('billingAccess', response?.billingAccess);
        setFieldValue('readOnly', response?.readOnly);
        setFieldValue('mustChangePassword', response?.mustChangePassword);
        setFieldValue('resetPassword', response?.resetPassword);
        setFieldValue('isAiAccess', response?.isAiAccess);
        setFieldValue('id', response?.id);
        setFieldValue('id', response?.id);

        // call country API for the set previously selected location
        if (response?.country) {
          setFieldValue('countryName', response?.country);
          const responseCountry = await getCountryName(response?.country);
          setCountryDetailsData(responseCountry);
          setValue(response?.country);
        } else {
          setValue('');
        }

        // call city API for the set previously selected location
        if (response?.city && response?.country) {
          setFieldValue('cityName', response?.city);
          const responseCity = await getCityName(response?.country, response?.city);
          setCityDetailsData(responseCity);
          setCityValue(response?.city);
        } else {
          setCityValue('');
        }
      }
    }
  }, [open]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const AddUserSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().min(8, 'Password must contain at least 8 characters').required('Password is required'),
    password:
      isAddMode &&
      Yup.string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        )
        .required('Password is required'),
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid First name')
      .required('First name is required'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid Last name')
      .required('Last name is required'),
    userLev: Yup.string().required('User level is required'),
    team: Yup.number().required('Team is required'),
    gro: Yup.number().required('Group is required'),
    countryName: Yup.string().required('Country is required'),
    cityName: Yup.string().required('City is required')
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      userLev: '',
      gro: '',
      team: '',
      supportAccess: false,
      billingAccess: false,
      readOnly: false,
      mustChangePassword: false,
      resetPassword: false,
      countryName: '',
      cityName: '',
      shortUsername: '',
      isAiAccess: false,
    },
    validationSchema: AddUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (isAddMode) {
          await addUser(
            values.email.toLowerCase(),
            values.password,
            values.firstName,
            values.lastName,
            values.userLev,
            values.gro.toString(),
            values.team.toString(),
            values.supportAccess,
            values.billingAccess,
            values.readOnly,
            values.mustChangePassword,
            values.resetPassword,
            values.countryName,
            values.cityName,
            values.shortUsername,
            values.isAiAccess
          );

          enqueueSnackbar('Added New User Successfully', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

          // Load all users after new user added
          refreshAllUsers('1');
          resetForm();
          setGroup(groupsList);
          setTeamList([]);
          setValue('');
          setCityValue('');
          handleClose();
        } else {
          await editUser(
            values.email.toLowerCase(),
            values.firstName,
            values.lastName,
            values.userLev,
            values.gro.toString(),
            values.team.toString(),
            values.supportAccess,
            values.billingAccess,
            values.readOnly,
            values.mustChangePassword,
            values.id,
            values.resetPassword,
            values.countryName,
            values.cityName,
            values.shortUsername,
            values.isAiAccess
          );

          enqueueSnackbar('Edited User Successfully', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          if (loggedUserData?.id === values?.id) {
            localStorage.setItem(
              'userData',
              JSON.stringify({ ...loggedUserData, supportAccess: values.supportAccess })
            );
          }
          // Close dialog
          closeAddUserDialog(false);
          // Load all users after edited existing user
          refreshAllUsers('1');
          resetForm();
          setGroup(groupsList);
          setTeamList([]);
          setValue('');
          setCityValue('');
        }

        if (useIsMountedRef.current) {
          setSubmitting(false);
          setOpen(false);
        }
      } catch (error) {
        console.log(error)

        const errorMessage = error?.response?.data?.message;

        console.log(errorMessage)
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: errorMessage });
        }
      }
    },
    enableReinitialze: true
  });

  const { errors, touched, values, setFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (event) => {
    setUserLev(event.target.value);
  };

  const handleMultiselectdisable = async (role) => {
    if (role === 'Global Admin') {
      const selectedGroups = groupsList?.map((item) => {
        return item?.id;
      });

      setFieldValue('gro', selectedGroups);
      // getTeams(selectedGroups.toString());
      const responseTeam = await getTeams(selectedGroups.toString());
      setTeamList(responseTeam);
    }

    if (role !== 'Global Admin' && isAddMode) {
      setTeamList([]);
      setFieldValue('gro', []);
    }
  };

  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
    setIsAddMode(true);
    getUserLevels();
    // getGroups();
    getGroupsList();
  };

  // Close user dialog modal
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      formik.resetForm();
      setGroup(groupsList);
      setTeamList([]);
      setValue('');
      setCityValue('');

      if (!setAddMode) {
        closeAddUserDialog(false);
      }
    }
  };

  // Load teams items based on selected groups
 const handleChangeGroups = async (event) => {
    const { options } = event.target;

    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    setFieldValue('gro', value);
    // setFieldValue('team', '');
    // getTeams(value.toString());

    // console.log(values.team);
    setTeamList([]);
    const responseTeam = await getTeams(value?.toString());
    // console.log(responseTeam)
    setTeamList(responseTeam)

    // console.log(value?.toString());
    // console.log(value);
    if (value?.length > 0) {
      const matchingIds = values?.team?.filter(id => responseTeam.some(item => item.id === id));
      // console.log(matchingIds);
      setFieldValue('team', matchingIds);
    } else {
      setFieldValue('team', '');
    }
  };

  const handleChangeTeams = (event) => {
    const { options } = event.target;

    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    // console.log('String values:', value);

    // Convert string values to numbers
    const numberValues = value?.map(Number);
    // console.log('Number values:', numberValues);

    setFieldValue('team', numberValues);
  };

  const renderSelectUserLevel = (data) => {
    const items = data.roles.map((role) => (
      <MenuItem onClick={() => handleMultiselectdisable(role.name)} key={role.id} value={role.id} name={role.name}>
        {role.name}
      </MenuItem>
    ));
    return [
      <ListSubheader>
        <span style={{ color: '#000000', fontWeight: 'bold' }}> {data.access} </span>
      </ListSubheader>,
      items
    ];
  };

  const handleCountry = async (string) => {
    // while edit clear the previous selected value
    if (string !== value) {
      setValue('');
    }

    if (string.length > 0) {
      const response = await getCountryName(string);
      setCountryDetailsData(response);
      setIsLoading(false);
    } else {
      setCountryDetailsData([]);
      setFieldValue('countryName', '');
    }
  };

  const handleCity = async (string) => {
    // while edit clear the previous selected value
    if (string !== cityValue) {
      setCityValue('');
    }

    if (string.length > 0) {
      const response = await getCityName(formik.values.countryName, string);
      setCityDetailsData(response);
      setIsLoading(false);
    } else {
      setCityDetailsData([]);
      setFieldValue('cityName', '');
    }
  };

  return (
    <div>
      {/* Display user button based on add user or edit user dialog modal */}
      {showAddUserButton && (
        <Button variant="contained" onClick={handleClickOpen}>
          Add User
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DisableWrapper exclude={['exclude-field', 'no-disable']}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogTitle sx={{ pb: 2 }}>
                <h3> {isAddMode ? 'Add New User' : `Edit ${values.firstName} ${values.lastName}`} </h3>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 15,
                    color: (theme) => theme.palette.grey[500]
                  }}
                  className="no-disable"
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent style={{ paddingTop: '20px' }}>
                {editUserSpinner ? (
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{ py: 2, minWidth: 500 }}>
                    <CircularProgress /> Loading...
                  </Box>
                ) : (
                  <Stack spacing={3}>
                    {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        required
                        label="First name"
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        onChange={(event) => { setFieldValue('firstName', event.target.value); setFieldValue('shortUsername', event.target.value) }}
                      />

                      <TextField
                        fullWidth
                        required
                        label="Last name"
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        required
                        type="email"
                        label="Email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />

                      {isAddMode && (
                        <TextField
                          fullWidth
                          required
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      )}
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel id="user-level" required>
                          User Level
                        </InputLabel>
                        <Select
                          labelId="user-level"
                          id="demo-select-small"
                          value={userLev}
                          label="User Level"
                          onChange={handleChange}
                          {...getFieldProps('userLev')}
                          error={Boolean(touched.userLev && errors.userLev)}
                          helperText={touched.userLev && errors.userLev}
                        >
                          {userLevels?.map((data) => renderSelectUserLevel(data))}
                        </Select>
                        {formik.errors.userLev && formik.touched.userLev ? (
                          <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>
                            {formik.errors.userLev}
                          </Typography>
                        ) : null}
                      </FormControl>
                      <TextField
                        fullWidth
                        label="User name"
                        value={values.shortUsername}
                        {...getFieldProps('shortUsername')}
                        error={Boolean(touched.shortUsername && errors.shortUsername)}
                        helperText={touched.shortUsername && errors.shortUsername}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="groups" required>
                          Groups
                        </InputLabel>
                        <Select
                          multiple
                          native
                          name="gro"
                          sx={{ height: '180px' }}
                          value={values?.gro}
                          label="Groups"
                          inputProps={{
                            id: 'groups'
                          }}
                          error={Boolean(touched.gro && errors.gro)}
                          helperText={touched.gro && errors.gro}
                          {...getFieldProps('gro')}
                          onChange={(e) => {
                            handleChangeGroups(e);
                          }}
                        >
                          {groupsList?.map((item) => (
                            <option className={classes.dropdownListStyle} key={item.id} id={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                        {formik.errors.gro && formik.touched.gro ? (
                          <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>Group is required</Typography>
                        ) : null}
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="teams" required>
                          Teams
                        </InputLabel>
                        <Select
                          multiple
                          native
                          name="team"
                          sx={{ height: '180px' }}
                          // disabled={multiselectDisabled}
                          // className={multiselectDisabled ? classes.selectDisabled : classes.select}
                          value={values?.team}
                          label="Teams"
                          {...getFieldProps('team')}
                          onChange={(e) => {
                            handleChangeTeams(e);
                          }}
                          inputProps={{
                            id: 'teams'
                          }}
                          error={Boolean((touched.team || touched.gro) && errors.team)}
                          helperText={(touched.team || touched.gro) && errors.team}
                        >
                          {teamList?.map((item, i) => (
                            <option className={classes.dropdownListStyle} key={item.id + i} id={item.id} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </Select>

                        {formik.errors.team && (formik.touched.team || formik.touched.gro) ? (
                          <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>Team is required</Typography>
                        ) : null}
                      </FormControl>
                    </Stack>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={countryDetailsData?.find((option) => option?.countryName === value) || ''}
                        inputValue={inputValue}
                        options={countryDetailsData}
                        getOptionLabel={(option) => option?.countryName || ''}
                        onChange={(event, newValue) => {
                          // set selected option in list
                          setValue(newValue?.countryName);
                          if (newValue?.countryName) {
                            setFieldValue('countryName', newValue?.countryName);

                            setFieldValue('cityName', '');
                            setCityValue('');
                            setCityDetailsData([]);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setIsLoading(true);
                          // each key stroke call location API
                          handleCountry(newInputValue);
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <>
                            //       {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            //       {params.InputProps.endAdornment}
                            //     </>
                            //   ),
                            // }}
                            variant="outlined"
                            required
                          />
                        )}
                      />
                      {formik.errors.countryName && formik.touched.countryName ? (
                        <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>
                          {formik.errors.countryName}
                        </Typography>
                      ) : null}
                    </FormControl>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={cityDetailsData?.find((option) => option?.cityName === cityValue) || ''}
                        inputValue={cityInputValue}
                        options={cityDetailsData}
                        getOptionLabel={(option) => option?.cityName || ''}
                        onChange={(event, newValue) => {
                          // set selected option in list
                          setCityValue(newValue?.cityName);
                          if (newValue?.cityName) {
                            setFieldValue('cityName', newValue?.cityName);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setIsLoading(true);
                          // each key stroke call location API
                          handleCity(newInputValue);
                          setCityInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="City"
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <>
                            //       {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            //       {params.InputProps.endAdornment}
                            //     </>
                            //   ),
                            // }}
                            variant="outlined"
                            required
                          />
                        )}
                      />
                      {formik.errors.cityName && formik.touched.cityName ? (
                        <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>
                          {formik.errors.cityName}
                        </Typography>
                      ) : null}
                    </FormControl>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                      <FormControlLabel
                        label="Support Access"
                        control={
                          <Checkbox checked={values.supportAccess} {...getFieldProps('supportAccess')} size="small" />
                        }
                      />
                      <FormControlLabel
                        label="Billing Access"
                        control={
                          <Checkbox
                            disabled
                            checked={values.billingAccess}
                            {...getFieldProps('billingAccess')}
                            size="small"
                          />
                        }
                      />
                      <FormControlLabel
                        label="Read Only"
                        control={<Checkbox checked={values.readOnly} {...getFieldProps('readOnly')} size="small" />}
                      />
                      <FormControlLabel
                        label="Must Change Password"
                        control={
                          <Checkbox
                            disabled={values.resetPassword}
                            checked={values.mustChangePassword}
                            {...getFieldProps('mustChangePassword')}
                            size="small"
                          />
                        }
                      />
                      <FormControlLabel
                        label="Reset Link"
                        control={
                          <Checkbox
                            disabled={values.mustChangePassword}
                            checked={values.resetPassword}
                            {...getFieldProps('resetPassword')}
                            size="small"
                          />
                        }
                      />
                      {isAiEnabled && <FormControlLabel
                        label="Speech Ai Hub"
                        control={
                          <Checkbox
                            checked={values.isAiAccess}
                            {...getFieldProps('isAiAccess')}
                            size="small"
                          />
                        }
                      />}
                    </Stack>
                  </Stack>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                    formik.resetForm();
                    setGroup(groupsList);
                    setTeamList([]);
                    setValue('');
                    setCityValue('');
                  }}
                  color="inherit"
                  className="no-disable"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" loading={isSubmitting}>
                  {isAddMode ? 'Add User' : 'Update User'}
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DisableWrapper>
      </Dialog>
    </div>
  );
}
